import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { observer } from 'mobx-react';
import React, { Fragment, useContext, useState } from 'react'
import { FaSpinner } from 'react-icons/fa';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { RootStoreContext } from '../../../app/stores/RootStore';

interface IProps {
    movePrevious: () => void;
}

const Payment: React.FC<IProps> = ({ movePrevious }) => {
    const [payAtPickupPreference, setPayAtPickupPreference] = useState(false);

    const togglePaymentPreference = () => {
        setPayAtPickupPreference(!payAtPickupPreference);
    }
    const [name, setName] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [errorName, setErrorName] = useState('');
    const [errorPhoneNo, setErrorPhoneNo] = useState('');

    const rootContext = useContext(RootStoreContext);
    const { createOrder, processingOrder, createOrderAndChargeCard } = rootContext.orderStore;
    const { Total } = rootContext.basketStore;


    const [cardErrors, setCardErrors] = useState('');
    const [cardNumberValid, setCardNumberValid] = useState(false);
    const [cardExpiryValid, setCardExpiryValid] = useState(false);
    const [cardCvcValid, setCardCvcValid] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const payLater = async () => {
        if (name === '' || phoneNo === '') {
            setErrorReason();
            return;
        }
        createOrder(name, phoneNo);
    }

    const payWithCard = async () => {
        if (name === '' || phoneNo === '') {
            setCardErrors('Name and phone number are mandatory');
            return;
        }

        if (elements === null) { console.log('Stripe Element is null'); }
        else {
            const cardElement = elements.getElement(CardNumberElement);

            const billingDetails = {
                name: name + '@' + phoneNo,
            };
            createOrderAndChargeCard(name + '@' + phoneNo, phoneNo, stripe, cardElement, billingDetails);
        }
    }

    const handleStripeCardErrors = (event: any) => {
        if (event.error) {
            setCardErrors(event.error.message);
        } else {
            setCardErrors('');
        }

        switch (event.elementType) {
            case 'cardNumber':
                setCardNumberValid(event.complete);
                break;
            case 'cardExpiry':
                setCardExpiryValid(event.complete);
                break;
            case 'cardCvc':
                setCardCvcValid(event.complete);
                break;
        }

    }
    const cardElementOptions = {
        style: {
            base: {
                fontSize: '18px',
                color: '#424770',
                letterSpacing: '0.025em',
                fontFamily: 'Source Code Pro, monospace',
                "::placeholder": {
                    color: '#aab7c4',
                }
            },
            invalid: {
                color: '#9e2146',
                iconColor: '#FFC7EE',
            }
        },
        hidePostalCode: true

    }
    const formatAndSetPhoneNumber = (value: string) => {
        setErrorPhoneNo('');
        setPhoneNo(value)
    }

    const setErrorReason = () => {
        if ((name === null || name === '') && (phoneNo === null || phoneNo === '')) {
            setErrorName(' *** Name is mandatory');
            setErrorPhoneNo(' *** Phone Number is mandatory');
        }
        if (name === null || name === '') {
            setErrorName(' *** Name is mandatory');
        }
        if (phoneNo === null || phoneNo === '') {
            setErrorPhoneNo(' *** Phone Number is mandatory');
        }
        return "";

    }


    return (
        <div className="panel">
            <div className="delivery">
                <input className="delivery__chkbox" type="checkbox" checked={payAtPickupPreference} onChange={togglePaymentPreference} />
                <label className="delivery__chkbox-txt">I will pay at pickup.</label>
            </div>

            {payAtPickupPreference ? (
                <div>
                    <div className="cashpay">
                        <span className="creditpay__bigtext">Pay At Pickup</span>

                        <div className="creditpay__amount">
                            <div>Pay <b><u>{Total}</u></b> at pickup</div>
                        </div>
                        <div>
                            <span className="creditpay__info">
                                You can pay us by cash, credit or debit at our location at <b><u>135 Gateway Park Drive, Kitchener</u></b>  when you pick up your order.
                        </span>
                        </div>
                        <div>
                            <br />
                        </div>
                        <div>
                            <label className="creditpay__label">Name
                            <span className='chkcontact__error'>{errorName}</span>
                            </label>

                            <input className="chkcontact__nameTxt" type="text" name='name' value={name}
                                onChange={e => { setErrorName(''); setName(e.target.value); }} placeholder='Your Name' />

                        </div>
                        <div>
                            <label className="creditpay__label">Phone Number
                            <span className='chkcontact__error'>{errorPhoneNo}</span></label>
                            <NumberFormat
                                className="chkcontact__phoneTxt"
                                format="(###) ###-####"
                                mask=""
                                name="phoneNumberInput"
                                placeholder="Phone Number"
                                onValueChange={e => formatAndSetPhoneNumber(e.formattedValue)}
                                value={phoneNo}
                            />
                        </div>

                        <div className="chk-paynow__buttons">
                            <button className="bapoo-btn btn-enabled" onClick={movePrevious} >Back</button>
                            {processingOrder ? (
                                <button className="bapoo-btn btn-disabled">
                                    <FaSpinner className="spinner" />
                                </button>
                            ) : (
                                <button className="bapoo-btn btn-enabled" onClick={() => payLater()}>Confirm &amp; Pay Later</button>
                            )}

                        </div>
                    </div>
                </div>

            ) : (
                <div>
                    <div className="creditpay">
                        <label className="creditpay__logocontainer">
                            <div className="creditpay__logo">
                                <img src="/images/visa_logo.png" alt='visa' />
                                <img src="/images/mastercard_logo.png" alt='mastercard' />&nbsp;&nbsp;
                            </div>
                            <div className="creditpay__amount">
                                &nbsp;&nbsp;Pay $  <b><u>{Total}</u></b> with credit card
                                {cardErrors !== '' && (<span className='creditpay__error'><br></br>***{cardErrors}</span>)}
                            </div>
                        </label>
                        <div>
                            <label className="creditpay__label">Name
                            <span className='chkcontact__error'>{errorName}</span>
                            </label>
                            <input className="creditpay__nameTxt" type="text" name='name' value={name}
                                onChange={e => { setErrorName(''); setName(e.target.value); }} placeholder='Your Name' />
                        </div>
                        <div>
                            <label className="creditpay__label">Phone Number
                            <span className='chkcontact__error'>{errorPhoneNo}</span></label>
                            <NumberFormat
                                className="creditpay__phoneTxt"
                                format="(###) ###-####"
                                mask=""
                                name="phoneNumberInput"
                                placeholder="Phone Number"
                                onValueChange={e => formatAndSetPhoneNumber(e.formattedValue)}
                                value={phoneNo}
                            />
                        </div>
                        <div>
                            <label className="creditpay__label">Card Number</label>
                            <CardNumberElement options={cardElementOptions} onChange={(e) => handleStripeCardErrors(e)} />
                        </div>
                        <div className="creditpay__element">
                            <div>
                                <label className="creditpay__label">Valid thru</label>
                                <CardExpiryElement options={cardElementOptions} onChange={(e) => handleStripeCardErrors(e)} />
                            </div>
                            <div>
                                <label className="creditpay__label">CVV / CVC *</label>
                                <CardCvcElement options={cardElementOptions} onChange={(e) => handleStripeCardErrors(e)} />
                            </div>
                        </div>
                        <span className="creditpay__info disclaimer">* CVV or CVC is the card security code, unique three digits number on the back of your card separate from its number.</span>
                        <div className="chk-paynow__buttons">
                            <button className="bapoo-btn btn-enabled" onClick={movePrevious}>Back</button>


                            {processingOrder || !cardNumberValid || !cardCvcValid || !cardExpiryValid || name === '' || phoneNo === '' ? (
                                <Link to='#' className="bapoo-btn btn-disabled">
                                    {processingOrder ? (
                                        <FaSpinner className="spinner" />
                                    ) : (
                                        <Fragment>Fill Card Details</Fragment>
                                    )}

                                </Link>

                            ) : (
                                <Link to='#' className="bapoo-btn btn-success" onClick={() => payWithCard()}>Pay Now</Link>
                            )}

                        </div>
                    </div>

                </div>
            )
            }

            <div className="chk-panel__content">
                <span className="creditpay__info disclaimer">By entering your phone number you agree to be contacted by phone, for your order updates</span>
            </div >
        </div >

    )
}

export default observer(Payment)
