import "./contact.scss";

const Body = () => {
    return (

        <div className="contact">
            <div className="contact__heading">
                <h1>Contact Us</h1>
            </div>
            <div className="contact__hours">
                <span className="contact__hours__heading">Opening Hours:</span>
                <p>
                    <span className="contact__hours__subheading">Monday - Thursday:</span> 3PM - 10PM <br />
                    <span className="contact__hours__subheading">Friday - Saturday:</span> 3PM - 10PM <br />
                    <span className="contact__hours__subheading">Sunday:</span> 3PM - 10PM
                </p>
            </div>
            <div className="contact__info">
                <span className="contact__hours__heading">Contact Info:</span>
                <p className="footer__address">
                    <svg className="footer__svg">
                        <use xlinkHref="images/mysprite.svg#icon-location-pin"></use>
                    </svg>
                    <span className="contact__hours__subheading">135 Gateway Park Drive,
                    Kitchener</span>
                </p>
                <p className="footer__address">
                    <svg className="footer__svg">
                        <use xlinkHref="images/mysprite.svg#icon-phone"></use>
                    </svg>
                    <span className="contact__hours__subheading">519 219 2700</span></p>
                <p className="footer__address">
                    <svg className="footer__svg">
                        <use xlinkHref="images/mysprite.svg#icon-mail"></use>
                    </svg>
                    <span className="contact__hours__subheading">bapoopizza@anokhi.ca</span></p>
            </div>
            <iframe
                title="Address"
                className="contact__map"
                id="map"
                width="425"
                height="350"
                frameBorder="0"
                scrolling="no"
                marginHeight={0}
                marginWidth={0}
                src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=135+Gateway+Park Drive,+Kitchner,+ON,+Canada&amp;aq=0&amp;oq=135+Gateway+Park Drive&amp;sll=43.40582,-80.38777&amp;sspn=47.885545,107.138672&amp;ie=UTF8&amp;hq=&amp;hnear=135+Gateway Park+Drive,+Kitchner,+Ontario+L1K+0J6,+Canada&amp;t=m&amp;z=14&amp;iwloc=A&amp;output=embed"
            ></iframe>
        </div >

    )
}

export default Body
