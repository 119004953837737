import { observer } from 'mobx-react';
import React, { Fragment, useContext } from 'react'
import { TiTick } from 'react-icons/ti'
import { ProductOption } from '../../../app/models/Category';
import { RootStoreContext } from '../../../app/stores/RootStore';
import { GiCheeseWedge } from 'react-icons/gi';



const Cheese = () => {
    const rootContext = useContext(RootStoreContext);
    const { product, addSelectedOption, removeSelectedOption } = rootContext.productStore;
    const { selectedCheese, setSelectedCheese } = rootContext.customStateManager;

    const getCheeseOptions = () => {
        let myoptions = product?.options.filter(x => x.name.indexOf("Cheese") !== -1)
        if (myoptions !== undefined && myoptions?.length > 0) {
            return myoptions[0].options;
        }
        return [];
    }

    const getSelectedOption = (myselection: string) => {
        let optionSelected: ProductOption | null = null;

        getCheeseOptions().forEach(option => {
            if (option.name === myselection) {
                optionSelected = option;
            }
        });

        return optionSelected;
    }

    const isSelected = (currentCheese: string) => {
        if (currentCheese === selectedCheese)
            return true;
        return false;
    }
    const selectCrust = (currentCrust: string) => {
        let oldSelectedOption = getSelectedOption(selectedCheese);
        let newSelectedOption = getSelectedOption(currentCrust);
        if (newSelectedOption !== null) {
            addSelectedOption(newSelectedOption);
        }
        if (oldSelectedOption !== null) {
            removeSelectedOption(oldSelectedOption);
        }
        setSelectedCheese(currentCrust);
    }

    if (getCheeseOptions().length <= 0) {
        return <Fragment></Fragment>;
    }

    return (
        <Fragment>
            <div className="selectedProduct__content__title">
                <div className="selectedProduct__content__title__no"><GiCheeseWedge color='orange' /></div>
                <div className="selectedProduct__content__title__txt">
                    <p className="selectedProduct__content__title__txt-1">Choose your Cheese:</p>
                    <p className="selectedProduct__content__title__txt-2">Choice 1 / 1 : [{selectedCheese}]</p>
                </div>
            </div>
            <div className="selectedProduct__content__desc">
                <div className="selectedProduct__content__desc-grid">


                    {getCheeseOptions() !== undefined && getCheeseOptions().map(cheese => (
                        <button key={cheese.id} className="selectedProduct__content__desc-grid-item" onClick={() => selectCrust(cheese.name)}>

                            {isSelected(cheese.name) && (
                                <div className="selectedProduct__content__desc-grid-item-selected"><TiTick color="white" size='22' /></div>
                            )}

                            <img className="selectedProduct__content__desc-grid-item-img" alt="ingredients" src={cheese.pictureUrl} />
                            <div className="selectedProduct__content__desc-grid-item-txt">
                                <p className="selectedProduct__content__desc-grid-item-txt-1">{cheese.name}</p>
                                <p className="selectedProduct__content__desc-grid-item-txt-2"></p>
                            </div>
                        </button>
                    ))}

                </div>
            </div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>

        </Fragment>

    )
}

export default observer(Cheese)
