import React, { Fragment, useContext, useState } from 'react';
import { RootStoreContext } from '../../../app/stores/RootStore';
import CheckoutContact from './CheckoutContact';
import CheckoutShipping from './CheckoutShipping';
import CheckoutPayment from './CheckoutPayment';
import '../checkout.scss';
import '../stripe.scss';
import { observer } from 'mobx-react';

const StripeWithGrid = () => {
    const rootContext = useContext(RootStoreContext);
    const { Total } = rootContext.basketStore;

    const { checkOutStep, MoveCheckoutStep } = rootContext.sliderStore;

    //const [step, setStep] = useState(0);



    const [name, setName] = useState('');
    const [phoneno, setPhoneNo] = useState('');



    const movePrevious = () => {
        if (checkOutStep > 0)
            MoveCheckoutStep(checkOutStep - 1);
        else
            MoveCheckoutStep(0);
    }


    const moveNext = () => {
        if (checkOutStep > 2)
            MoveCheckoutStep(3);
        else
            MoveCheckoutStep(checkOutStep + 1);
    }


    return (
        <Fragment>
            <div className="backgroundImage" />

            <div className="chk-panel">

                {checkOutStep === 0 && (<h2 className="chk-panel__title">Your Contact Info</h2>)}
                {checkOutStep === 1 && (<h2 className="chk-panel__title">Our Delivery Announcement</h2>)}
                {checkOutStep === 2 && (<h2 className="chk-panel__title">Place Order for $ {Total}</h2>)}

                <div className="chk-panel__progress-bar">

                    {checkOutStep > 0 ? (<div className="chk-panel__step active"></div>) : (<div className="chk-panel__step"></div>)}
                    {checkOutStep > 1 ? (<div className="chk-panel__step active"></div>) : (<div className="chk-panel__step"></div>)}
                    {checkOutStep > 2 ? (<div className="chk-panel__step active"></div>) : (<div className="chk-panel__step"></div>)}
                </div>

                {checkOutStep === 0 && (<CheckoutContact name={name} phoneNo={phoneno} setName={setName} setPhoneNo={setPhoneNo} moveNext={moveNext} movePrevious={movePrevious} />)}
                {checkOutStep === 1 && (<CheckoutShipping moveNext={moveNext} movePrevious={movePrevious} />)}
                {checkOutStep === 2 && (<CheckoutPayment name={name} phoneno={phoneno} movePrevious={movePrevious} />)}
                {/*<CheckoutFooter movePrevious={movePrevious} moveNext={moveNext} />*/}

            </div>

        </Fragment>
    )
}

export default observer(StripeWithGrid)
