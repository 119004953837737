import React from 'react';
import "./footer.scss";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__container">
                <div className="footer__logoclass">
                    <img className="footer__logo" src="/images/mynewlogo.png" alt="" />
                </div>
                <div className="footer__text">
                    <h3>How To Find Us</h3>
                    <p className="footer__address">
                        <svg className="footer__svg">
                            <use xlinkHref="images/mysprite.svg#icon-location-pin"></use>
                        </svg>
                        135 Gateway Park Drive,
                    Kitchener
                  </p>
                    <p className="footer__address">
                        <svg className="footer__svg">
                            <use xlinkHref="images/mysprite.svg#icon-phone"></use>
                        </svg>
                        519 219 2700</p>
                    <p className="footer__address">
                        <svg className="footer__svg">
                            <use xlinkHref="images/mysprite.svg#icon-mail"></use>
                        </svg>
                        bapoopizza@anokhi.ca</p>
                </div>
            </div>

        </footer>
    )
}

export default Footer
