import React, { Fragment } from 'react'


interface IProps {
    movePrevious: () => void;
    moveNext: () => void;
}

const CheckoutShipping: React.FC<IProps> = ({ moveNext, movePrevious }) => {
    return (
        <Fragment>

            <div className="chk-panel__content">
                <div className="shipping">
                    <label className="shipping__announcement">Coming Soon!</label>
                    <input className="shipping__chkbox" type="checkbox" />
                    <label className="shipping__policy">I wouldn't mind trying out bapoo's own domestic delivery instead of other third-party services.</label>
                    <label className="shipping__disclaimer">We are working on building our own delivery system that will save everyone costs and also allow us to make contactless deliveries.</label>


                </div>
            </div>

            <div className="chkFooter">
                <div>
                    <button className="bapoo-btn btn-enabled" onClick={movePrevious}>Back</button>
                </div>
                <div>
                    <button className="bapoo-btn btn-enabled" onClick={moveNext}>Next</button>
                </div>
            </div>
        </Fragment>

    )
}

export default CheckoutShipping
