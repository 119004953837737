import React, { Fragment, useContext, useEffect } from 'react'
import DisplayMenuGivenCategory from '../categorymenu/DisplayMenuGivenCategory'
import { RootStoreContext } from '../../app/stores/RootStore'
import LoadingIndicator from '../../app/common/loaders/LoadingIndicator'
import { observer } from 'mobx-react'

const Body = () => {
    const rootContext = useContext(RootStoreContext);
    const { loadingMenu, loadMenu, category1, category2, category3, category4, category5, category6 } = rootContext.productStore;

    useEffect(() => {
        loadMenu()
    }, [loadMenu])



    if (loadingMenu || category1 === null || category2 === null || category3 === null || category4 === null || category5 === null || category6 === null) {
        return <LoadingIndicator />
    }


    return (
        <Fragment>
            <div className="backgroundImage" />
            <section className="flatmenus">

                <img className="flatmenus__banner" src="./images/flatmenu/pizza-ban@2x.jpg" alt="banner" />
                {<DisplayMenuGivenCategory category={category1!} />}
                <img className="flatmenus__banner" src="./images/flatmenu/pizza-ban@2x.jpg" alt="banner" />
                {<DisplayMenuGivenCategory category={category2!} />}
                <img className="flatmenus__banner" src="./images/flatmenu/pizza-ban@2x.jpg" alt="banner" />
                {<DisplayMenuGivenCategory category={category3!} />}
                <img className="flatmenus__banner" src="./images/flatmenu/pizza-ban@2x.jpg" alt="banner" />
                {<DisplayMenuGivenCategory category={category4!} />}
                <img className="flatmenus__banner" src="./images/flatmenu/pizza-ban@2x.jpg" alt="banner" />
                {<DisplayMenuGivenCategory category={category5!} />}
                <img className="flatmenus__banner" src="./images/flatmenu/pizza-ban@2x.jpg" alt="banner" />
                {<DisplayMenuGivenCategory category={category6!} />}
                <img className="flatmenus__banner" src="./images/flatmenu/pizza-ban@2x.jpg" alt="banner" />

                {/*
                <MeatTraditionals />
                <div></div>
                <VegTraditionals />
                <div></div>
                <MeatGourmet />
                <div></div>
                <VegGourmet />
                <div></div>
                <Sides />
                */}
            </section>
        </Fragment>
    )
}

export default observer(Body);
