import { RootStore } from "./RootStore";
import { observable, action, reaction, makeObservable } from 'mobx';

export default class CommonStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

        reaction(
            () => this.token,
            (token) => {
                if (token) {
                    window.localStorage.setItem('BapooPizzaToken', token);
                } else {
                    window.localStorage.removeItem('BapooPizzaToken');
                }

            }
        );
    }


    @observable appLoaded = false;
    @observable token: string | null = window.localStorage.getItem('BapooPizzaToken');

    @observable tabPanes: any = [];
    @observable activeTabIndex = -1;
    @observable tabVsIndexMap = new Map();

    @action setToken = (token: string | null) => {
        this.token = token;

        // if (token) {
        //     window.localStorage.setItem('BapooPizzaToken', token);
        // } else {
        //     window.localStorage.removeItem('BapooPizzaToken');
        // }

    }

    @action setAppLoaded = () => {
        this.appLoaded = true;
    }


    @action addTabPane = (key: string, tab: any) => {
        if (this.tabVsIndexMap.has(key)) {
            const index = this.tabVsIndexMap.get(key);
            this.setActiveTabIndex(index);
        } else {
            this.tabPanes = [...this.tabPanes, tab];
            this.setActiveTabIndex(this.tabPanes.length - 1);
            this.tabVsIndexMap.set(key, this.activeTabIndex);
        }

    }

    @action removeTabPane = (key: string) => {
        //console.log(`Trying to delete ${key} in ${this.tabVsIndexMap}`);
        if (this.tabVsIndexMap.has(key)) {
            //console.log("Got in the loop");
            this.tabPanes = this.tabPanes.filter((tab: any) => {
                return tab.menuItem !== key;
            });
            this.tabVsIndexMap.delete(key);
            this.setActiveTabIndex(0);
        }
    }


    @action setActiveTabIndex = (tabIndex: any) => {
        this.activeTabIndex = tabIndex;
    }

    @action resetTabs = () => {
        this.tabPanes = [];
        this.activeTabIndex = -1;
        this.tabVsIndexMap.clear();
    }


}