import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { observer } from 'mobx-react';
import React, { Fragment } from 'react'
import StripeWithGrid from './stripwithgrid/StripeWithGrid'


const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY!);

const Checkout = () => {

    return (
        <Fragment>
            <div className="contact_banner">
                <h1>Check out</h1>
                <h4>&nbsp;</h4>
            </div>
            {/*   <Body /> */}
            {/*<StripeTry /> */}

            <Elements stripe={stripePromise}>
                {<StripeWithGrid />}
            </Elements>
        </Fragment>
    )
}

export default observer(Checkout)
