import { observer } from 'mobx-react';
import React, { Fragment, useContext } from 'react'
import { TiTick } from 'react-icons/ti'
import { ProductOption } from '../../../app/models/Category';
import { RootStoreContext } from '../../../app/stores/RootStore';
import { GiLadle } from 'react-icons/gi'

const Gravy = () => {
    const rootContext = useContext(RootStoreContext);
    const { product, addSelectedOption, removeSelectedOption } = rootContext.productStore;
    const { selectedGravy, setSelectedGravy } = rootContext.customStateManager;


    const getGravyOptions = () => {
        let myoptions = product?.options.filter(x => x.name.indexOf("Gravy") !== -1)
        if (myoptions !== undefined && myoptions?.length > 0) {
            return myoptions[0].options;
        }
        return [];
    }

    const getSelectedOption = (myselection: string) => {
        let optionSelected: ProductOption | null = null;

        getGravyOptions().forEach(option => {
            if (option.name === myselection) {
                optionSelected = option;
            }
        });

        return optionSelected;
    }

    const isSelected = (currentGravy: string) => {
        if (currentGravy === selectedGravy)
            return true;
        return false;
    }
    const selectGravy = (currentGravy: string) => {
        let oldSelectedOption = getSelectedOption(selectedGravy);
        let newSelectedOption = getSelectedOption(currentGravy);
        if (newSelectedOption !== null) {
            addSelectedOption(newSelectedOption);
        }
        if (oldSelectedOption !== null) {
            removeSelectedOption(oldSelectedOption);
        }
        setSelectedGravy(currentGravy);
    }


    if (getGravyOptions().length <= 0) {
        return <Fragment></Fragment>;
    }


    return (
        <Fragment>
            <div className="selectedProduct__content__title">
                <div className="selectedProduct__content__title__no"><GiLadle /></div>
                <div className="selectedProduct__content__title__txt">
                    <p className="selectedProduct__content__title__txt-1">Choose your Gravy:</p>
                    <p className="selectedProduct__content__title__txt-2">Choice 1 / 1 : [{selectedGravy}]</p>
                </div>
            </div>
            <div className="selectedProduct__content__desc">
                <div className="selectedProduct__content__desc-grid">


                    {getGravyOptions() !== undefined && getGravyOptions().map(gravy => (
                        <button key={gravy.id} className="selectedProduct__content__desc-grid-item" onClick={() => selectGravy(gravy.name)}>

                            {isSelected(gravy.name) && (
                                <div className="selectedProduct__content__desc-grid-item-selected"><TiTick color="white" size='22' /></div>
                            )}

                            <img className="selectedProduct__content__desc-grid-item-img" alt="ingredients" src={gravy.pictureUrl} />
                            <div className="selectedProduct__content__desc-grid-item-txt">
                                <p className="selectedProduct__content__desc-grid-item-txt-1">{gravy.name}</p>
                                <p className="selectedProduct__content__desc-grid-item-txt-2"></p>
                            </div>
                        </button>
                    ))}

                </div>
            </div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>

        </Fragment>

    )
}

export default observer(Gravy)
