import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import 'react-toastify/dist/ReactToastify.min.css';
import App from './app/layout/App';
import ScrollToTop from './app/layout/ScrollToTop';
import reportWebVitals from './reportWebVitals';
//import { loadStripe } from '@stripe/stripe-js';
//import { Elements } from '@stripe/react-stripe-js';

//const stripePromise = loadStripe('pk_test_51IHx95HfnKDO1c3EdC8yvHwp4d1imPIklcKKLAz6BGBczjIzn6sJH11SMLJohUv9p6SL7TZB4TzpIe0vGNjpSlKu00L3xN5ugc');
//const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY!);

export const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
