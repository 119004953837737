import React, { Fragment } from 'react'
import Body from './Body'

const OrderConfirmation = () => {
    return (
        <Fragment>
            <div className="about_banner">
                <h1>Order Confirmation</h1>
                <h4>&nbsp;</h4>
            </div>
            <Body />
        </Fragment>
    )
}

export default OrderConfirmation
