import { observer } from 'mobx-react'
import React, { Fragment, useContext } from 'react'
import { useHistory } from 'react-router-dom';
import { ICategoryCompact, IProductCompact } from '../../app/models/Category'
import { RootStoreContext } from '../../app/stores/RootStore';

import { FiPlus } from 'react-icons/fi';

interface IProps {
    category: ICategoryCompact;
}
const Deals: React.FC<IProps> = ({ category }) => {
    const history = useHistory();
    const rootContext = useContext(RootStoreContext);
    const { closeSlider } = rootContext.sliderStore;
    const { addCustomItemToBasket } = rootContext.basketStore;




    const navigateTo = (navigateurl: string) => {
        history.push(navigateurl);
    }


    const getPrice = (prices: string[]) => {
        try {
            return Number.parseFloat(prices[0]);
        } catch {
            return 0;
        }
        //return prices.join(" / ");
    }


    const addCustomOrderToBasket = (category: string, product: IProductCompact) => {

        let myprice = 0;
        if (product.productPrices !== undefined) {
            myprice = getPrice(product.productPrices);
        }
        let customOrder = {
            productId: product.id,
            productName: product.name,
            price: myprice,
            quantity: 1,
            size: product.productSizes[0],
            pictureUrl: product.pictureUrl,
            category: category,
            categoryId: category,
            crustType: '',
            sauceType: '',
            pizzaDips: [],
            pizzaSauces: [],
            pizzaCheeses: [],
            pizzaMeatToppings: [],
            pizzaVegToppings: [],
            pizzaOtherToppings: []
        }
        addCustomItemToBasket(customOrder, 1);
        closeSlider();
    }


    return (
        <Fragment>
            <div className="products__subheading">
                <button className="bapoo-btn btn-success" onClick={() => navigateTo(`/`)}> &lt;&lt;&nbsp; Back To Home</button>
            </div>

            <div className="products__heading">
                <h4>{category.name}</h4>
            </div>
            <div className="products__container">
                {category.productList.map((product) => (
                    <div key={product.id} className="products__item-container">
                        <div className="products__item-image">
                            <img src={product.pictureUrl} alt="" />
                        </div>
                        <div className="products__item-title">{product.name}</div>
                        <div className="products__item-desc">{product.description}</div>
                        <div className="products__item-prices">$ {getPrice(product.productPrices)}</div>
                        <div className="products__item-buttons">
                            <button className="bapoo-btn btn-enabled"
                                onClick={() => addCustomOrderToBasket(category.name, product)}><FiPlus />&nbsp;&nbsp;Add To Cart</button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="menus__subheading">
                <button className="bapoo-btn btn-success" onClick={() => navigateTo(`/`)}> &lt;&lt;&nbsp; Back To Home</button>
            </div>

        </Fragment>
    )
}

export default observer(Deals)
