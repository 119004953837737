import React, { useContext, useState } from 'react';
import { RootStoreContext } from '../../app/stores/RootStore';
import './loginPage.scss';

const Login = () => {
    const rootContext = useContext(RootStoreContext);
    const { login } = rootContext.userStore;

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const handleLogin = () => {
        if (username !== '' && password !== '') {
            let userFormValues = {
                email: username,
                password: password
            }
            login(userFormValues);
        }
    }

    const handleReset = () => {
        setUsername('');
        setPassword('');
    }

    return (
        <section className="loginPage">
            <div className="loginform">
                <label>User Name </label>
                <input type='text' name='login' value={username} onChange={(e) => setUsername(e.target.value)} style={{ width: '300px' }} />
                <label>Password </label>
                <input type='password' name='password' value={password} onChange={(e) => setPassword(e.target.value)} style={{ width: '300px' }} />
                <br />
                <div className="btnGroup">
                    <button className="bapoo-btn btn-success" onClick={handleLogin}>Login</button>
                    <button className="bapoo-btn btn-enabled" onClick={handleReset}>Reset</button>
                </div>
            </div>
        </section>
    )
}

export default Login
