import React, { Fragment } from 'react'
import { IBasketItem } from '../../../app/models/Basket'

interface IProps {
    basketItem: IBasketItem;
}
const CheesesInCart: React.FC<IProps> = ({ basketItem }) => {
    if (basketItem.cheeseList === undefined || basketItem.cheeseList?.length <= 0) {
        return <Fragment></Fragment>
    }
    return (
        <div>
            <p><b>Cheese(s):</b></p>
            {basketItem.cheeseList !== undefined && basketItem.cheeseList?.length > 0 && (
                basketItem.cheeseList?.map((item, index) => (
                    <p key={index}>{item.cheeseType.name}</p>
                ))
            )}
        </div>

    )
}

export default CheesesInCart
