import { configure } from "mobx";
import { createContext } from "react";
import BasketStore from "./BasketStore";
import CommonStore from "./CommonStore";
import CustomStateManager from "./CustomStateManager";
import DeliveryStateStore from "./DeliveryStateStore";
import KioskStore from "./KioskStore";
import { ModalStore } from "./ModalStore";
import OrderStore from "./OrderStore";
import ProductStore from "./ProductStore";
import SliderStore from "./SliderStore";
import UserStore from "./UserStore";

configure({ enforceActions: 'always' });

export class RootStore {
    basketStore: BasketStore;
    productStore: ProductStore;
    orderStore: OrderStore;
    sliderStore: SliderStore;
    customStateManager: CustomStateManager;
    deliveryStateStore: DeliveryStateStore;
    kioskStore: KioskStore;

    commonStore: CommonStore;
    userStore: UserStore;
    modalStore: ModalStore;


    constructor() {
        this.basketStore = new BasketStore(this);
        this.productStore = new ProductStore(this);
        this.orderStore = new OrderStore(this);
        this.sliderStore = new SliderStore(this);
        this.customStateManager = new CustomStateManager(this);
        this.deliveryStateStore = new DeliveryStateStore(this);

        this.commonStore = new CommonStore(this);
        this.userStore = new UserStore(this);
        this.modalStore = new ModalStore(this);
        this.kioskStore = new KioskStore(this);
    }
}

export const RootStoreContext = createContext(new RootStore());