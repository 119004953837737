import { action, computed, makeAutoObservable, runInAction } from "mobx";
import { observable } from "mobx";
import agent from "../api/agent";
import { User, UserFormValues } from "../models/User";
import { RootStore } from "./RootStore";
import { history } from '../..';
import { toast } from "react-toastify";

export default class UserStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this)
        this.rootStore = rootStore;
    }

    @observable user: User | null = null;


    @computed get isLoggedIn() {
        return !!this.user;
    }



    @action login = async (creds: UserFormValues) => {
        try {
            const myuser = await agent.account.login(creds);
            runInAction(() => {
                this.user = myuser;
                console.log(myuser.displayname);
                this.rootStore.commonStore.setToken(myuser.token);
                this.rootStore.modalStore.closeModal();
                history.push('/orders');

            });
        } catch (error) {
            console.log('------> ', error.data.message);
            toast.error(error.data.message);
            // throw error;
        }
    }

    @action getUser = async () => {
        try {
            const user = await agent.account.current();
            runInAction(() => {
                this.user = user;
            })
            return user;
        } catch (error) {
            console.log('------ ', error.data);

        }

    }

    @action logout = async () => {
        try {
            this.rootStore.commonStore.setToken(null);
            this.user = null;
            history.push('/');
        } catch (error) {

        }
    }

    @action register = async (values: UserFormValues) => {
        try {
            const user = await agent.account.register(values);
            runInAction(() => {
                this.user = user;
                this.rootStore.commonStore.setToken(user.token);
                this.rootStore.modalStore.closeModal();
                history.push('/orders');
            });
        } catch (error) {
            throw error;
        }
    }
}