import React, { Fragment, useState } from 'react'
import NumberFormat from 'react-number-format';

interface IProps {
    name: string;
    phoneNo: string;
    setName: (name: string) => void;
    setPhoneNo: (phone: string) => void;
    movePrevious: () => void;
    moveNext: () => void;
}

const CheckoutContact: React.FC<IProps> = ({ name, phoneNo, setName, setPhoneNo, moveNext, movePrevious }) => {


    const [errorName, setErrorName] = useState('');
    const [errorPhoneNo, setErrorPhoneNo] = useState('');

    const isDataValid = () => {
        if (name === null || name === '') {
            return false;
        }
        if (phoneNo === null || phoneNo === '') {
            return false;
        }
        return true;
    }

    const setErrorReason = () => {
        if ((name === null || name === '') && (phoneNo === null || phoneNo === '')) {
            setErrorName(' *** Name is mandatory');
            setErrorPhoneNo(' *** Phone Number is mandatory');
        }
        if (name === null || name === '') {
            setErrorName(' *** Name is mandatory');
        }
        if (phoneNo === null || phoneNo === '') {
            setErrorPhoneNo(' *** Phone Number is mandatory');
        }
        return "";

    }

    const formatAndSetPhoneNumber = (value: string) => {
        setPhoneNo(value)
    }

    return (
        <Fragment>
            <div className="chk-panel__content">
                <div className='chkcontact'>
                    <label className="chkcontact__nameLbl">Your Name <span className='chkcontact__error'>{errorName}</span></label>
                    <input className="chkcontact__nameTxt" type="text" name='name' value={name} onChange={e => { setErrorName(''); setName(e.target.value); }} placeholder='Name On Order' />
                    <label className="chkcontact__phoneLbl">Phone Number<span className='chkcontact__error'>{errorPhoneNo}</span></label>

                    <NumberFormat
                        className="chkcontact__phoneTxt"
                        format="(###) ###-####"
                        mask=""
                        name="phoneNumberInput"
                        placeholder="Phone Number"
                        onValueChange={e => formatAndSetPhoneNumber(e.formattedValue)}
                        value={phoneNo}
                    />
                    <span className="chkcontact__disclaimer">*** We need your number so that we can text your order updates and let you know when it is ready for pickup</span>
                </div>
                <br />
                <div className="chkFooter">
                    <div>
                        <button className="bapoo-btn btn-disabled" onClick={movePrevious}>Back</button>
                    </div>
                    <div>
                        {isDataValid() ? (
                            <button className="bapoo-btn btn-enabled" onClick={moveNext}>Next</button>
                        ) : (
                            <button className="bapoo-btn btn-disabled" onClick={setErrorReason}>Next</button>
                        )}

                    </div>
                </div>
                <br />
            </div>
        </Fragment>

    )
}

export default CheckoutContact
