import React, { Fragment } from 'react'
import Customize from './Customize'
import MeatGourmet from './MeatGourmet'
import MeatTraditionals from './MeatTraditionals'
import Sides from './Sides'
import VegGourmet from './VegGourmet'
import VegTraditionals from './VegTraditionals'
import './quickMenu.scss';

const Body = () => {
    return (
        <Fragment>
            <div className="backgroundImage" />

            <section className="menus">
                <MeatTraditionals />
                <VegTraditionals />
                <MeatGourmet />
                <VegGourmet />
                <Sides />
                <Customize />
            </section>
        </Fragment>

    )
}

export default Body
