import { observer } from 'mobx-react';
import React, { Fragment, useContext, useState } from 'react'
import { RootStoreContext } from '../../app/stores/RootStore';
import SplitItems from './SplitItems';
import { FaCheck } from 'react-icons/fa';
import { BiEdit } from 'react-icons/bi';

import InfiniteScroll from 'react-infinite-scroller';
import { IOrder } from '../../app/models/Order';
import moment from 'moment';

export const CreateOrderGroup = "OrderCreation";



interface UpdatedOrders {
    orderid: number;
    updatedTime: string;
}

interface Props {
    playSound: boolean;
    setPlaySound: (value: boolean) => void;
    setEnableSound: (value: boolean) => void;
    acceptAndStop: (value: number) => void;
    stop: () => void;
}
const OrdersList: React.FC<Props> = ({ playSound, setPlaySound, setEnableSound, acceptAndStop, stop }) => {
    const rootContext = useContext(RootStoreContext);

    const { orders, loadNextPage, completeOrder, updateOrder,
        totalPages, currentPageNo } = rootContext.kioskStore;

    const [loadingNext, setLoadingNext] = useState(false);

    const [timeForReady, setTimeForReady] = useState<UpdatedOrders[]>([]);


    const handleGetNext = () => {
        setLoadingNext(true);
        loadNextPage().then(() => {
            setLoadingNext(false);
        });

    }

    const defaultTimerTime = 40;

    const getStatusString = (order: IOrder) => {
        if (order.orderStatus === "AcceptedAtKitchen") {
            return "acceptorder";

        } else if (order.orderStatus === "ReadyForDelivery") {
            return "completed";
        } else if (order.orderStatus === "TimerSet") {
            return "timerset";
        }
        if (!playSound)
            setPlaySound(true);
        return "neworder";
    }


    const getStatusStringForButton = (order: IOrder) => {
        if (order.orderStatus === "AcceptedAtKitchen") {
            return "acceptorderBtn";

        } else if (order.orderStatus === "ReadyForDelivery") {
            return "completedBtn";
        }

        return "neworderBtn";
    }

    //const soundUrl = '/sounds/kalimba.mp3';

    const updateTimeForReady = (orderid: number) => {
        var orderTime = timeForReady.filter(x => x.orderid === orderid)[0];

        var minutesToAdd = defaultTimerTime.toString();
        if (!!orderTime) {
            minutesToAdd = orderTime.updatedTime;
        }
        updateOrder(orderid, minutesToAdd);
    }

    const getValueForSelect = (orderid: number) => {
        var orderTime = timeForReady.filter(x => x.orderid === orderid)[0];

        if (!!orderTime) {
            return orderTime.updatedTime;
        }

        return defaultTimerTime.toString();
    }

    const updateTimeFromSelect = (myorderid: number, value: string) => {
        let newOrders = timeForReady.filter(x => x.orderid !== myorderid);
        newOrders.push({ orderid: myorderid, updatedTime: value });
        setTimeForReady(newOrders);
    }

    const getDateOnly = (dateString: string) => {
        var date = new Date(dateString);
        var correctedDate = moment(date);//.add(-4, 'hours');
        try {
            return moment(correctedDate).format("MMM Do");//dateString.split('T')[0];
        } catch {
            return dateString;
        }
    }

    const getTimeOnly = (dateString: string) => {
        var date = new Date(dateString);
        var correctedDate = moment(date);//.add(-4, 'hours');
        try {
            //return dateString.split('T')[1].split('.')[0];
            return moment(correctedDate).format("hh:mm:ss a");
        } catch {
            return dateString;
        }
    }

    const getReadyTime = (myorderid: number, dateString: string) => {
        var orderTime = timeForReady.filter(x => x.orderid === myorderid)[0];

        var minutesToAdd = defaultTimerTime.toString();
        if (!!orderTime) {
            minutesToAdd = orderTime.updatedTime;
        }
        var date = new Date(dateString);
        var correctedDate = moment(date).add(minutesToAdd, 'minutes');
        try {
            return moment(correctedDate).format("hh:mm:ss a");
        } catch {
            return dateString;
        }
    }

    return (

        <div className="secondRow">
            <InfiniteScroll
                pageStart={0}
                loadMore={handleGetNext}
                hasMore={!loadingNext && !!orders && currentPageNo < totalPages}
                initialLoad={false}
            >
                {orders.map((order) => (
                    <div className={getStatusString(order)} key={order.id}>
                        <div className="mysegmentGroup">
                            <div className="mysegment inverted">
                                <div className="itemGroup">
                                    <div className="itemGroup__header">
                                        {order.orderStatus === "New" ? (
                                            <div>
                                                <button
                                                    style={{ padding: '5px', margin: '5px' }}
                                                    className={getStatusStringForButton(order)}
                                                    onClick={() => { acceptAndStop(order.id) }}
                                                >Accept Order &nbsp;<BiEdit /></button>
                                            </div>
                                        ) : (
                                            <Fragment>
                                                {order.orderStatus !== "ReadyForDelivery" && (
                                                    <div>
                                                        Time To Prepare :
                                                        <select
                                                            style={{ padding: '5px', margin: '5px' }}
                                                            value={getValueForSelect(order.id)}
                                                            onChange={(e) => updateTimeFromSelect(order.id, e.target.value)}>
                                                            <option value="15">15 mins</option>
                                                            <option value="20">20 mins</option>
                                                            <option value="25">25 mins</option>
                                                            <option value="30">30 mins</option>
                                                            <option value="40">40 mins</option>
                                                            <option value="45">45 mins</option>
                                                            <option value="60">1 hour</option>
                                                        </select>
                                                        <button
                                                            style={{ padding: '5px', margin: '5px' }}
                                                            className={getStatusStringForButton(order)}
                                                            onClick={() => { updateTimeForReady(order.id) }}
                                                        >Send Message &nbsp;<FaCheck /></button>
                                                    </div>

                                                )}
                                            </Fragment>
                                        )}

                                    </div>

                                    <div className="itemGroup__header">
                                        <div><b>Status : </b>{order.orderStatus} </div>
                                        {order.orderStatus !== "New" && order.orderStatus !== "ReadyForDelivery" && (
                                            <div><b>Ready Time : </b>{getReadyTime(order.id, order.orderDate)} </div>
                                        )}
                                    </div>
                                    <div className="itemGroup__header">
                                        <div>Order No : {order.id} </div>
                                        {order.paymentStatus.indexOf('Cash') !== -1 ? (
                                            <div style={{ color: 'red', fontWeight: 600 }}>Not Paid</div>
                                        ) : (
                                            <div style={{ color: 'green', fontWeight: 600 }}>Paid</div>
                                        )}
                                    </div>
                                    <div className="itemGroup__header">
                                        <div>Order Date : <b>{getDateOnly(order.orderDate)}</b> </div>
                                    </div>
                                    <div className="itemGroup__header">
                                        <div>Time : <b>{getTimeOnly(order.orderDate)}</b></div>
                                    </div>
                                    <div className="itemGroup__desc">
                                        <div>{order.buyerEmail.split('@')[0]}</div>
                                        <div>{order.buyerCellphone}</div>
                                    </div>
                                    {(order.shipToAddress !== null && order.shipToAddress.mainAddress !== "") ? (
                                        <div className="itemGroup__desc_bold">
                                            <div className="itemGroup__heading">Deliver To :</div>
                                            <div className="itemGroup__greenvalue">{order.shipToAddress.mainAddress}</div>
                                            <div>{order.shipToAddress.miscAddress}</div>
                                        </div>
                                    ) : (
                                        <div className="itemGroup__desc">
                                            <div className="itemGroup__heading">Deliver To :</div>
                                            <div className="itemGroup__value">Customer Will Pickup</div>
                                        </div>

                                    )}
                                </div>
                            </div>
                            {order.orderItems.map((orderItem, index) => (
                                <div className="mysegment" key={index}>
                                    {index + 1}: <span style={{ fontSize: '16px', color: 'orangered', fontWeight: 600, padding: '20px' }}>
                                        {orderItem.productName} - [{orderItem.productSize}]</span>
                                    <br></br>
                                    {orderItem.comments !== "" && (
                                        <Fragment>
                                            <span style={{ marginLeft: '50px' }}><b>{orderItem.comments}</b></span>
                                            <br></br>
                                        </Fragment>
                                    )}
                                    <span style={{ marginLeft: '50px' }}><b>Quantity :</b> {orderItem.quantity}</span>
                                    {orderItem.crustType !== "" && (
                                        <Fragment>
                                            <br></br>
                                            <span style={{ marginLeft: '50px' }}><b> CrustType :</b> {orderItem.crustType}</span>
                                        </Fragment>
                                    )}
                                    {orderItem.sauceType !== "" && (
                                        <Fragment>
                                            <br></br>
                                            <span style={{ marginLeft: '50px' }}><b>SauceType :</b> {orderItem.sauceType}</span>
                                        </Fragment>
                                    )}
                                    {orderItem.dips !== "" && (
                                        <Fragment>
                                            <br></br>
                                            <span style={{ marginLeft: '50px' }}><b>Dips : </b></span>
                                            <SplitItems values={orderItem.dips} />
                                        </Fragment>
                                    )}
                                    {orderItem.cheeses !== "" && (
                                        <Fragment>
                                            <br></br>
                                            <span style={{ marginLeft: '50px' }}><b>Cheeses :</b></span>
                                            <SplitItems values={orderItem.cheeses} />
                                        </Fragment>
                                    )}
                                    {orderItem.meatToppings !== "" && (
                                        <Fragment>
                                            <br></br>
                                            <span style={{ marginLeft: '50px' }}><b>Meat Toppings :</b></span>
                                            <SplitItems values={orderItem.meatToppings} />
                                        </Fragment>
                                    )}
                                    {orderItem.vegToppings !== "" && (
                                        <Fragment>
                                            <br></br>
                                            <span style={{ marginLeft: '50px' }}><b>Veg Toppings :</b></span>
                                            <SplitItems values={orderItem.vegToppings} />
                                        </Fragment>
                                    )}
                                    {orderItem.otherToppings !== "" && (
                                        <Fragment>
                                            <br></br>
                                            <span style={{ marginLeft: '50px' }}><b>Special Toppings :</b></span>
                                            <SplitItems values={orderItem.otherToppings} />
                                        </Fragment>
                                    )}
                                </div>
                            ))}

                            <div className="totalBill">
                                Total Bill : {order.totalBill}
                            </div>

                            <div className="itemGroup__header">
                                <div><b>Status : </b>{order.orderStatus} </div>
                                <div>
                                    {order.orderStatus !== "ReadyForDelivery" && (
                                        <button
                                            className={getStatusStringForButton(order)}
                                            onClick={() => { completeOrder(order.id) }}
                                        >Order Ready For Pickup &nbsp;<FaCheck /></button>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                ))
                }

            </InfiniteScroll>
        </div >

    )
}

export default observer(OrdersList)
