import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const Customize = () => {
    return (
        <Fragment>
            <div className="menus__heading" id='Customize'>
                <h1>Create your own</h1>
            </div>
            <div className="menus__subheading">
                <Link to={'/'}> &lt;&lt;&nbsp;Back To Home</Link>
            </div>
            <div className="menu">
                <img src="images/menu/customPizza.jpg" alt="" className="menu__img" />

                <h5 className="menu__name">Customize</h5>
                <span className="menu__subtext">Create a pizza of your liking</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Topping Choices Thick / Thin / Classic  - Gluten free</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Choice of sauce</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Choice of cheese</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Choose your toppings</p>
                </div>

                <button className="btn menu__btn">Order Now</button>


            </div>
            <div className="menus__subheading">
                <Link to={'/'}> &lt;&lt;&nbsp;Back To Home</Link>
            </div>


        </Fragment>
    )
}

export default Customize
