import React from 'react';
import { NavLink } from 'react-router-dom';
import './navBar.scss';

const NavBar = () => {
    return (
        <nav className="topNav">
            <div className="nbContent">
                <div className="nbContent__links">
                    <NavLink to={'/'} exact activeClassName="current">Home</NavLink>
                    <NavLink to={'/menu'} exact activeClassName="current">Menu</NavLink>
                    <NavLink to={'/contactus'} exact activeClassName="current">Contact</NavLink>
                    <p className="nbContent__links__phone">
                        519 219 2700
                    </p>
                </div>
                <div className="nbContent__logoclass">
                    <img src="/images/mynewlogo.png" className="nbContent__logo" alt="" />
                </div>
            </div>

            {/* 
        
        <div className="header__dropdown">
            <a href="#">Home</a>
            <a href="#">Menu</a>
            <a href="#">About Us</a>
            <a href="#">Contact</a>
        </div>
        */}
        </nav>
    )
}

export default NavBar
