import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const VegTraditionals = () => {
    return (
        <Fragment>
            <div className="menus__heading" id='VegTraditionals'>
                <h1>Bapoo's Veg Traditional's</h1>
            </div>
            <div className="menus__subheading">
                <Link to={'/'}> &lt;&lt;&nbsp;Back To Home</Link>
            </div>
            <div className="menu">
                <img src="images/menu/vegTraditionals.jpg" alt="" className="menu__img" />

                <h5 className="menu__name pepperoni">Margherita</h5>
                <span className="menu__subtext">San Marzano Marinara,Fresh Basil and Mozzarella Cheese</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Small $ 7.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Medium $ 9.75</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Large $ 11.75</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Ex. Large $ 13.75</p>
                </div>

                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menu">
                <img src="images/menu/vegGourmet.png" alt="" className="menu__img" />

                <h5 className="menu__name">Ananas</h5>
                <span className="menu__subtext">Sweet Corn, Pineapple and Green Pepper roasted to perfection</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Small $ 10.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Medium $ 13.75</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Large $ 16.75</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Ex. Large $ 19.25</p>
                </div>

                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menu">
                <img src="images/menu/vegTraditionals.jpg" alt="" className="menu__img" />

                <h5 className="menu__name">Canadian Veggie Delight</h5>
                <span className="menu__subtext">CMushroooms, Green Peppers, Tomatoes and Hot Banana Peppers</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Small $ 10.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Medium $ 14.75</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Large $ 17.75</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Ex. Large $ 20.25</p>
                </div>

                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menu">
                <img src="images/menu/vegGourmet.png" alt="" className="menu__img" />

                <h5 className="menu__name">Classic Paneer</h5>
                <span className="menu__subtext">House Made Paneer Crumble,Red Onions, and Green Peppers</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Small $ 11.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Medium $ 14.75</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Large $ 17.75</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Ex. Large $ 20.25</p>
                </div>

                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menu">
                <img src="images/menu/vegTraditionals.jpg" alt="" className="menu__img" />

                <h5 className="menu__name">Indian Veggie Lovers</h5>
                <span className="menu__subtext"> Mushroooms,Red Onions, Roasted Red Peppers and Host Banana
                    Peppers</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Small $ 10.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Medium $ 13.75</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Large $ 16.75</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Ex. Large $ 19.25</p>
                </div>

                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menus__subheading">
                <Link to={'/'}> &lt;&lt;&nbsp;Back To Home</Link>
            </div>


        </Fragment>
    )
}

export default VegTraditionals
