import React, { Fragment } from 'react'
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'
import { GiDoughRoller } from 'react-icons/gi'
import { ICrustType } from '../../../app/models/PizzaOptions'


interface IProps {
    crustTypes: ICrustType[];
    minimize: boolean;
    maximize: (section: string, minimize: boolean) => void;
    pizzaCrust: string;
    setPizzaCrust: (size: string) => void;
}

const CrustOptions: React.FC<IProps> = ({ crustTypes, minimize, maximize, pizzaCrust, setPizzaCrust }) => {



    return (
        <Fragment>
            {minimize ? (
                <Fragment>
                    <div className="r-section__h4" onClick={() => maximize('crust', true)}>
                        <span><GiDoughRoller size='25' /></span>
                        <span>Crust:</span>
                        <span><FaPlusCircle /></span>
                    </div>
                </Fragment>
            ) :
                (
                    <Fragment>
                        <div className="r-section__h4  r-section__h4__expanded" onClick={() => maximize('crust', false)}>
                            <span><GiDoughRoller size='25' /></span>
                            <span>Crust:</span>
                            <span><FaMinusCircle /></span>
                        </div>
                        <div className="r-section__p">
                            {crustTypes.map(crustType => (
                                <div key={crustType.id}>
                                    <input className="bapoo_radio_button" type="radio" name="crust"
                                        value={crustType.name}
                                        checked={pizzaCrust === crustType.name}
                                        onChange={(e) => setPizzaCrust(e.target.value)} /><span onClick={() => setPizzaCrust(crustType.name)}>&nbsp;&nbsp;{crustType.name} </span>
                                </div>

                            ))}

                        </div>
                        <div className="r-section__h4__footer">&nbsp;</div>
                    </Fragment>
                )
            }


        </Fragment >
    )
}

export default CrustOptions
