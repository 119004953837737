import { observer } from 'mobx-react';
import React, { Fragment, useContext } from 'react'
import { TiTick } from 'react-icons/ti'
import { ProductOption } from '../../../app/models/Category';
import { RootStoreContext } from '../../../app/stores/RootStore';
import { GiMeatCleaver } from 'react-icons/gi'

const ToppingsMeat = () => {

    const rootContext = useContext(RootStoreContext);
    const { product, addSelectedOption, removeSelectedOption } = rootContext.productStore;
    const { selectedMeatToppings, setSelectedMeatToppings } = rootContext.customStateManager;


    const getMeatToppingOptions = () => {
        let myoptions = product?.options.filter(x => x.name.indexOf("Meat Toppings") !== -1)
        if (myoptions !== undefined && myoptions?.length > 0) {
            return myoptions[0].options;
        }
        return [];
    }

    const getSelectedOption = (toppingSelection: string) => {
        let optionSelected: ProductOption | null = null;

        getMeatToppingOptions().forEach(option => {
            if (option.name === toppingSelection) {
                optionSelected = option;
            }
        });

        return optionSelected;
    }

    const isSelected = (topping: string) => {
        let match = selectedMeatToppings.filter(x => x === topping);
        if (match !== null && match.length > 0)
            return true;
        return false;
    }

    const isPreConfiguredOption = (topping: ProductOption) => {

        if (product === null || product === undefined) return;
        const configOptions = product.preConfiguredOptions.filter(x => x.name.indexOf("Meat") !== -1);
        if (configOptions !== null && configOptions !== undefined && configOptions.length > 0) {
            const option = configOptions[0].options.filter(x => x.name === topping.name);
            if (option.length > 0) {
                return true;
            }
        }
        return false;
    }


    const toggleSelection = (toppingSelection: string) => {
        let match = selectedMeatToppings.filter(x => x === toppingSelection);

        let optionSelected = getSelectedOption(toppingSelection);

        if (match !== null && match.length > 0) {
            setSelectedMeatToppings(selectedMeatToppings.filter(x => x !== toppingSelection));
            if (optionSelected != null) {
                removeSelectedOption(optionSelected);
            }
        } else {
            setSelectedMeatToppings([...selectedMeatToppings, toppingSelection]);
            if (optionSelected != null) {
                addSelectedOption(optionSelected);
            }

        }
    }


    if (getMeatToppingOptions().length <= 0) {
        return <Fragment></Fragment>;
    }


    return (
        <Fragment>
            <div className="selectedProduct__content__title">
                <div className="selectedProduct__content__title__no"><GiMeatCleaver /></div>
                <div className="selectedProduct__content__title__txt">
                    <p className="selectedProduct__content__title__txt-1">Meat Toppings:</p>
                    <p className="selectedProduct__content__title__txt-2">Chose {selectedMeatToppings.length} / {getMeatToppingOptions().length}</p>
                </div>

            </div>
            <div className="selectedProduct__content__desc">
                <div className="selectedProduct__content__desc-grid">


                    {getMeatToppingOptions() !== undefined && getMeatToppingOptions().map(meatTopping => (
                        <Fragment key={meatTopping.id}>
                            {isPreConfiguredOption(meatTopping) ? (
                                <div key={meatTopping.id} className="selectedProduct__content__desc-grid-item  selectedProduct__content__desc-grid-item-preconfigured">
                                    <div className="selectedProduct__content__desc-grid-item-preconfigured-heading">Included</div>
                                    <div className="selectedProduct__content__desc-grid-item-selected"><TiTick color="white" size='22' /></div>
                                    <img className="selectedProduct__content__desc-grid-item-img" alt="ingredients" src={meatTopping.pictureUrl} />
                                    <div className="selectedProduct__content__desc-grid-item-txt">
                                        <p className="selectedProduct__content__desc-grid-item-txt-1">{meatTopping.name}</p>
                                        <p className="selectedProduct__content__desc-grid-item-txt-2"></p>
                                    </div>
                                </div>

                            ) : (
                                <button key={meatTopping.id} className="selectedProduct__content__desc-grid-item" onClick={() => toggleSelection(meatTopping.name)}>

                                    {isSelected(meatTopping.name) && (
                                        <div className="selectedProduct__content__desc-grid-item-selected"><TiTick color="white" size='22' /></div>
                                    )}
                                    <img className="selectedProduct__content__desc-grid-item-img" alt="ingredients" src={meatTopping.pictureUrl} />
                                    <div className="selectedProduct__content__desc-grid-item-txt">
                                        <p className="selectedProduct__content__desc-grid-item-txt-1">{meatTopping.name}</p>
                                        <p className="selectedProduct__content__desc-grid-item-txt-2"></p>
                                    </div>
                                </button>

                            )}

                        </Fragment>

                    ))}

                </div>
            </div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>

        </Fragment >

    )
}

export default observer(ToppingsMeat)
