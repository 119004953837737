import React, { Fragment } from 'react'
import { IBasketItem } from '../../../app/models/Basket'

interface IProps {
    basketItem: IBasketItem;
}
const CommentsInCart: React.FC<IProps> = ({ basketItem }) => {

    if (basketItem.comments === undefined || basketItem.comments?.length <= 0) {
        return <Fragment></Fragment>
    }
    return (
        <div>
            <b></b> &nbsp;{basketItem.comments}
        </div>
    )
}

export default CommentsInCart
