import { observer } from 'mobx-react';
import React, { Fragment, useContext } from 'react'
import { RootStoreContext } from '../../app/stores/RootStore';
import ModifiedCheckout from './ModifiedCheckout';
import { VscChromeClose } from 'react-icons/vsc';

const SlidingCheckout = () => {
    const rootContext = useContext(RootStoreContext);
    const { closeSlider } = rootContext.sliderStore;

    return (
        <Fragment>
            <div className="backgroundImage" />

            <div className="selectedProduct">
                <div className="selectedProduct__header">
                    <p className="selectedProduct__header-label">Checkout Order</p>
                    <VscChromeClose className="selectedProduct__header-btn" size='25' onClick={closeSlider} />
                </div>
                <div className="selectedProduct__subheader">
                    <div>
                    </div>
                </div>
                <div className="selectedProduct__content">
                    <ModifiedCheckout />
                </div>
                <div className="selectedProduct__footer">
                </div>

            </div>


        </Fragment >

    )
}

export default observer(SlidingCheckout)