import { observer } from 'mobx-react'
import React, { Fragment, useContext, useEffect } from 'react'
import LoadingComponent from '../../app/common/loaders/LoadingComponent'
import { IPizzaAccessory } from '../../app/models/PizzaOptions'
import { RootStoreContext } from '../../app/stores/RootStore'
import { VscChromeClose } from 'react-icons/vsc';
import "./productoptions.scss";
import Crust from './ingredients/Crust'
import Size from './ingredients/Size'
import ToppingsMeat from './ingredients/ToppingsMeat'
import ToppingsVeg from './ingredients/ToppingsVeg'
import ToppingsOther from './ingredients/ToppingsOther'
import Cheese from './ingredients/Cheese'
import Dips from './ingredients/Dips'
import Sauce from './ingredients/Sauce'
import Gravy from './ingredients/Gravy'



const CustomizeOrder = () => {

    const rootContext = useContext(RootStoreContext);
    const { product, loadProduct } = rootContext.productStore;
    const { data: productId, closeSlider } = rootContext.sliderStore;
    const { addCustomItemToBasket } = rootContext.basketStore;


    useEffect(() => {
        loadProduct(productId);
    }, [productId, loadProduct])

    if (product === null || product.productSizes === undefined) {
        return <LoadingComponent />
    }

    const getNotesStringValue = (optionCategory: string) => {
        let myoptions = product.selectedOptions?.filter(x => x.categoryName.indexOf(optionCategory) !== -1)

        let iteratedValues: string[] = [];

        if (myoptions !== undefined && myoptions?.length > 0) {
            myoptions.forEach(option => {
                if (!iteratedValues.includes(option.name)) {
                    iteratedValues.push(option.name);
                }
            });
        }
        return iteratedValues.join(",");;
    }


    const getOptionStringValue = (optionCategory: string) => {
        let myoptions = product.selectedOptions?.filter(x => x.categoryName.indexOf(optionCategory) !== -1)

        let iteratedValues: string[] = [];

        if (myoptions !== undefined && myoptions?.length > 0) {
            myoptions.forEach(option => {
                if (!iteratedValues.includes(option.name)) {
                    iteratedValues.push(option.name);
                }
            });
        }
        return iteratedValues.join(",");;
    }

    const getOptionAsAccessory = (optionCategory: string) => {

        let myoptions = product.selectedOptions?.filter(x => x.categoryName.indexOf(optionCategory) !== -1)
        let returnValue: IPizzaAccessory[] = [];
        if (myoptions !== undefined && myoptions?.length > 0) {
            myoptions.forEach(option => {
                returnValue.push({
                    id: option.id,
                    name: option.name,
                    description: option.description,
                    price: option.price,
                    quantity: 1,
                    quantityScale: 1
                });
            });
        }
        return returnValue;
    }

    const addCustomOrderToBasket = () => {

        let myprice = 0;
        if (product.totalPrice !== undefined) {
            myprice = product.totalPrice;
        }
        let customOrder = {
            productId: product.id,
            productName: product.name,
            price: myprice,
            quantity: 1,
            size: product.selectedSize!.name,
            pictureUrl: product.pictureUrl,
            category: product.categories[0].name,
            categoryId: product.categories[0].name,
            crustType: getOptionStringValue("Crust"),
            sauceType: getOptionStringValue("Sauce"),
            pizzaDips: getOptionAsAccessory("Dips"),
            pizzaSauces: getOptionAsAccessory("Sauce"),
            pizzaCheeses: getOptionAsAccessory("Cheese"),
            pizzaMeatToppings: getOptionAsAccessory("Meat"),
            pizzaVegToppings: getOptionAsAccessory("Veg"),
            pizzaOtherToppings: getOptionAsAccessory("Other"),
            comments: getNotesStringValue("Gravy")
        }

        //console.log('addCustomOrderToBasket ', customOrder);

        addCustomItemToBasket(customOrder, 1);
        closeSlider();
    }

    return (
        <Fragment>
            <div className="backgroundImage" />

            <div className="selectedProduct">
                <div className="selectedProduct__header">
                    <p className="selectedProduct__header-label">Customize Your Order</p>
                    <VscChromeClose className="selectedProduct__header-btn" color='white' size='25' onClick={closeSlider} />
                </div>
                <div className="selectedProduct__subheader">
                    {/*<img className="selectedProduct__mainImage" alt="ingredients" src="/images/ingredients/alfalfa-seeds.jpg" />*/}
                    <img className="selectedProduct__mainImage" alt="ingredients" src={product.pictureUrl} />
                    <div className="selectedProduct__subheader_desc">
                        <p className="selectedProduct__selectedProductName">{product.name}</p>
                        <p className="selectedProduct__selectedItem">{product.totalPrice}</p>
                    </div>
                </div>
                <div className="selectedProduct__content">
                    {product.categories[0].name !== "Sides" && <Size />}
                    {product.name.indexOf("Fries") !== -1 && <Size />}
                    {product.name.indexOf("Wings") !== -1 && <Size />}
                    <Crust />
                    <Cheese />
                    <Sauce />
                    <ToppingsMeat />
                    <ToppingsVeg />
                    <ToppingsOther />
                    <Gravy />
                    <Dips />
                    <div>&nbsp;</div>

                </div>
                <div className="selectedProduct__footer">
                    <button className="selectedProduct__footer-btn" onClick={addCustomOrderToBasket}>
                        <div className="selectedProduct__footer-btn-amt">
                            <p>Total : $ {product.totalPrice}</p>
                        </div>
                        <div className="selectedProduct__footer-btn-addCart">
                            <p>Add To Cart</p>
                        </div>
                    </button>
                </div>

            </div>


        </Fragment >

    )
}

export default observer(CustomizeOrder)
