import React, { Fragment } from 'react';
import "./loading.scss";

const LoadingIndicator = () => {
    return (
        <Fragment>
            <div className="loadingIndicator">
                <img src="/images/loader.gif" alt="loading gif" />
            </div>
        </Fragment >
    )
}

export default LoadingIndicator
