import { action, makeObservable, observable } from "mobx";
import { RootStore } from "./RootStore";

export default class CustomStateManager {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable selectedSize = 'Large';
    @observable selectedCrust = 'Original Crust';
    @observable selectedSauce = 'Organic Tomato Sauce';
    @observable selectedGravy = 'Semi Gravy';
    @observable selectedCheese = '';
    @observable selectedDip: string[] = [];
    @observable selectedMeatToppings: string[] = [];
    @observable selectedVegToppings: string[] = [];
    @observable selectedOtherToppings: string[] = [];

    @action setSelectedCheese = (value: string) => {
        this.selectedCheese = value;
    }
    @action setSelectedCrust = (value: string) => {
        this.selectedCrust = value;
    }
    @action setSelectedDip = (value: string[]) => {
        this.selectedDip = value;
    }
    @action setSelectedSauce = (value: string) => {
        this.selectedSauce = value;
    }
    @action setSelectedGravy = (value: string) => {
        this.selectedGravy = value;
    }
    @action setSelectedSize = (value: string) => {
        this.selectedSize = value;
    }
    @action setSelectedMeatToppings = (value: string[]) => {
        this.selectedMeatToppings = value;
    }
    @action setSelectedOtherToppings = (value: string[]) => {
        this.selectedOtherToppings = value;
    }
    @action setSelectedVegToppings = (value: string[]) => {
        this.selectedVegToppings = value;
    }

    public reset = () => {
        this.selectedSize = 'Large';
        this.selectedCrust = 'Original Crust';
        this.selectedCheese = '';
        this.selectedSauce = 'Organic Tomato Sauce';
        this.selectedGravy = 'Semi Gravy';
        this.selectedDip = [];
        this.selectedMeatToppings = [];
        this.selectedVegToppings = [];
        this.selectedOtherToppings = [];
    }


}