import { observer } from 'mobx-react'
import React, { Fragment } from 'react'

interface IProps {
    values: string;
}
const SplitItems: React.FC<IProps> = ({ values }) => {
    const myvalues: string[] = values.split(']');
    return (
        <Fragment>
            {myvalues.map((spValue, index) => (
                <div key={spValue + index} style={{ marginLeft: '60px' }}>{spValue.replaceAll('[', ' ')}</div>
            ))}
        </Fragment>

    )
}

export default observer(SplitItems)
