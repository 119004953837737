import React, { Fragment } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Body from './Body'


interface DetailedParams {
    productId: string;
}
const CustomProduct: React.FC<RouteComponentProps<DetailedParams>> = ({ match }) => {
    return (
        <Fragment>
            <div className="contact_banner">
                <h1>Customize</h1>
                <h4>&nbsp;</h4>
            </div>
            <Body productId={match.params.productId} />
        </Fragment>
    )
}

export default CustomProduct
