import { observer } from 'mobx-react';
import React, { Fragment, useContext } from 'react'
import { TiTick } from 'react-icons/ti'
import { ProductOption } from '../../../app/models/Category';
import { RootStoreContext } from '../../../app/stores/RootStore';
import { GiLadle } from 'react-icons/gi'

const Sauce = () => {
    const rootContext = useContext(RootStoreContext);
    const { product, addSelectedOption, removeSelectedOption } = rootContext.productStore;
    const { selectedSauce, setSelectedSauce } = rootContext.customStateManager;


    const getSauceOptions = () => {
        let myoptions = product?.options.filter(x => x.name.indexOf("Sauce") !== -1)
        if (myoptions !== undefined && myoptions?.length > 0) {
            return myoptions[0].options;
        }
        return [];
    }

    const getSelectedOption = (myselection: string) => {
        let optionSelected: ProductOption | null = null;

        getSauceOptions().forEach(option => {
            if (option.name === myselection) {
                optionSelected = option;
            }
        });

        return optionSelected;
    }

    const isSelected = (currentSauce: string) => {
        if (currentSauce === selectedSauce)
            return true;
        return false;
    }
    const selectSauce = (currentSauce: string) => {
        let oldSelectedOption = getSelectedOption(selectedSauce);
        let newSelectedOption = getSelectedOption(currentSauce);
        if (newSelectedOption !== null) {
            addSelectedOption(newSelectedOption);
        }
        if (oldSelectedOption !== null) {
            removeSelectedOption(oldSelectedOption);
        }
        setSelectedSauce(currentSauce);
    }


    if (getSauceOptions().length <= 0) {
        return <Fragment></Fragment>;
    }


    return (
        <Fragment>
            <div className="selectedProduct__content__title">
                <div className="selectedProduct__content__title__no"><GiLadle /></div>
                <div className="selectedProduct__content__title__txt">
                    <p className="selectedProduct__content__title__txt-1">Choose your Sauce:</p>
                    <p className="selectedProduct__content__title__txt-2">Choice 1 / 1 : [{selectedSauce}]</p>
                </div>
            </div>
            <div className="selectedProduct__content__desc">
                <div className="selectedProduct__content__desc-grid">


                    {getSauceOptions() !== undefined && getSauceOptions().map(sauce => (
                        <button key={sauce.id} className="selectedProduct__content__desc-grid-item" onClick={() => selectSauce(sauce.name)}>

                            {isSelected(sauce.name) && (
                                <div className="selectedProduct__content__desc-grid-item-selected"><TiTick color="white" size='22' /></div>
                            )}

                            <img className="selectedProduct__content__desc-grid-item-img" alt="ingredients" src={sauce.pictureUrl} />
                            <div className="selectedProduct__content__desc-grid-item-txt">
                                <p className="selectedProduct__content__desc-grid-item-txt-1">{sauce.name}</p>
                                <p className="selectedProduct__content__desc-grid-item-txt-2"></p>
                            </div>
                        </button>
                    ))}

                </div>
            </div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>

        </Fragment>

    )
}

export default observer(Sauce)
