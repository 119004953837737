import { observer } from 'mobx-react';
import React, { Fragment, useContext } from 'react'
import { TiTick } from 'react-icons/ti'
import { ProductOption } from '../../../app/models/Category';
import { RootStoreContext } from '../../../app/stores/RootStore';
import { GiSpill } from 'react-icons/gi'

const Dips = () => {

    const rootContext = useContext(RootStoreContext);
    const { product, addSelectedOption, removeSelectedOption } = rootContext.productStore;
    const { selectedDip, setSelectedDip } = rootContext.customStateManager;


    const getDipOptions = () => {
        let myoptions = product?.options.filter(x => x.name.indexOf("Dips") !== -1)
        if (myoptions !== undefined && myoptions?.length > 0) {
            return myoptions[0].options;
        }
        return [];
    }

    const getSelectedOption = (toppingSelection: string) => {
        let optionSelected: ProductOption | null = null;
        getDipOptions().forEach(option => {
            if (option.name === toppingSelection) {
                optionSelected = option;
            }
        });

        return optionSelected;
    }
    const isSelected = (topping: string) => {
        let match = selectedDip.filter(x => x === topping);
        if (match !== null && match.length > 0)
            return true;
        return false;
    }

    const toggleSelection = (dipSelection: string) => {
        let match = selectedDip.filter(x => x === dipSelection);
        let optionSelected = getSelectedOption(dipSelection);
        if (match !== null && match.length > 0) {
            setSelectedDip(selectedDip.filter(x => x !== dipSelection));
            if (optionSelected != null) {
                removeSelectedOption(optionSelected);
            }
        } else {
            setSelectedDip([...selectedDip, dipSelection]);
            if (optionSelected != null) {
                addSelectedOption(optionSelected);
            }

        }
    }

    if (getDipOptions().length <= 0) {
        return <Fragment></Fragment>;
    }


    return (
        <Fragment>
            <div className="selectedProduct__content__title">
                <div className="selectedProduct__content__title__no"><GiSpill /></div>
                <div className="selectedProduct__content__title__txt">
                    <p className="selectedProduct__content__title__txt-1">Dips:</p>
                    <p className="selectedProduct__content__title__txt-2">Selected [{selectedDip.length} of {getDipOptions().length}]</p>
                </div>

            </div>
            <div className="selectedProduct__content__desc">
                <div className="selectedProduct__content__desc-grid">


                    {getDipOptions() !== undefined && getDipOptions().map(dip => (
                        <button key={dip.id} className="selectedProduct__content__desc-grid-item" onClick={() => toggleSelection(dip.name)}>

                            {isSelected(dip.name) && (
                                <div className="selectedProduct__content__desc-grid-item-selected"><TiTick color="white" size='22' /></div>
                            )}
                            <img className="selectedProduct__content__desc-grid-item-img" alt="ingredients" src={dip.pictureUrl} />
                            <div className="selectedProduct__content__desc-grid-item-txt">
                                <p className="selectedProduct__content__desc-grid-item-txt-1">{dip.name}</p>
                                <p className="selectedProduct__content__desc-grid-item-txt-2"></p>
                            </div>
                        </button>

                    ))}

                </div>
            </div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>

        </Fragment>

    )
}

export default observer(Dips)
