import React, { Fragment } from 'react'
import { IBasketItem } from '../../../app/models/Basket'

interface IProps {
    basketItem: IBasketItem;
}
const OtherToppingsInCart: React.FC<IProps> = ({ basketItem }) => {
    if (basketItem.otherToppingList === undefined || basketItem.otherToppingList?.length <= 0) {
        return <Fragment></Fragment>
    }
    return (
        <div>
            <p><b>Other Topping(s):</b></p>
            {basketItem.otherToppingList !== undefined && basketItem.otherToppingList?.length > 0 && (
                basketItem.otherToppingList?.map((item, index) => (
                    <p key={index}>{item.otherToppingType.name}</p>
                ))
            )}
        </div>

    )
}

export default OtherToppingsInCart
