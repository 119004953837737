import { observer } from 'mobx-react';
import React, { Fragment, useContext } from 'react'
import { TiTick } from 'react-icons/ti'
import { ProductSize } from '../../../app/models/Category';
import { RootStoreContext } from '../../../app/stores/RootStore';
import { GiFullPizza } from 'react-icons/gi';


const Size = () => {
    const rootContext = useContext(RootStoreContext);
    const { product, changeSize } = rootContext.productStore;
    const { selectedSize, setSelectedSize } = rootContext.customStateManager;

    const isSelected = (productSize: ProductSize) => {
        if (selectedSize === '' && product !== null && product.selectedSize !== undefined) {
            setSelectedSize(product?.selectedSize?.name);
        }
        if (productSize.name === selectedSize)
            return true;
        return false;
    }

    const selectSize = (productSize: ProductSize) => {
        setSelectedSize(productSize.name);
        changeSize(productSize);
    }

    if (product !== undefined && product?.productSizes !== undefined && product?.productSizes.length <= 0) {
        return <Fragment></Fragment>;
    }


    return (
        <Fragment>
            <div className="selectedProduct__content__title">
                <div className="selectedProduct__content__title__no"><GiFullPizza /></div>
                <div className="selectedProduct__content__title__txt">
                    <p className="selectedProduct__content__title__txt-1">Choose your Size:</p>
                    <p className="selectedProduct__content__title__txt-2">Choice 1 / 1 : [{selectedSize}]</p>
                </div>

            </div>
            <div className="selectedProduct__content__desc">
                <div className="selectedProduct__content__desc-grid">

                    {product?.productSizes.map(productSize => (
                        <button key={productSize.id} className="selectedProduct__content__desc-grid-item" onClick={() => selectSize(productSize)}>
                            {isSelected(productSize) && (
                                <div className="selectedProduct__content__desc-grid-item-selected"><TiTick color="white" size='22' /></div>
                            )}
                            <img className="selectedProduct__content__desc-grid-item-img" alt="ingredients" src={productSize.pictureUrl} />
                            <div className="selectedProduct__content__desc-grid-item-txt">
                                <p className="selectedProduct__content__desc-grid-item-txt-1">{productSize.name}</p>
                                <p className="selectedProduct__content__desc-grid-item-txt-1">{productSize.price}</p>
                            </div>
                        </button>
                    ))}

                </div>
            </div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>

        </Fragment>
    )
}

export default observer(Size);
