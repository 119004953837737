import { observer } from 'mobx-react';
import React, { Fragment, useContext } from 'react';
import { FaPlusCircle, FaMinusCircle, FaTrash } from 'react-icons/fa';
import { RootStoreContext } from '../../app/stores/RootStore';
import MeatToppingsInCart from './customproduct/MeatToppingsInCart';
import VegToppingsInCart from './customproduct/VegToppingsInCart';
import OtherToppingsInCart from './customproduct/OtherToppingsInCart';
import CheesesInCart from './customproduct/CheesesInCart';
import SaucesInCart from './customproduct/SaucesInCart';
import DippingsInCart from './customproduct/DippingsInCart';
import CrustTypeInCart from './customproduct/CrustTypeInCart';
import { IBasketItem } from '../../app/models/Basket';
import CommentsInCart from './customproduct/CommentsInCart';

interface IProps {
    checkoutOrder: () => void;
}

const CartContentGrid: React.FC<IProps> = ({ checkoutOrder }) => {
    const rootContext = useContext(RootStoreContext);
    const { customerBasket, SubTotal, Taxes, Total, incrementItemQuantity, decrementItemQuantity, removeItemFromBasket } = rootContext.basketStore;

    /*
        const getTotalPriceForCrustSelection = (basketItem: IBasketItem) => {
            return 0;
        }
        const getTotalPriceForDipsSelection = (basketItem: IBasketItem) => {
            if (basketItem.dipList === undefined)
                return 0;
            let totalOptionsCost = 0;
            basketItem.dipList.forEach(pizzaOpt => {
                totalOptionsCost += (pizzaOpt.dippingType.price * parseInt(pizzaOpt.quantity));
            });
    
            return totalOptionsCost;
        }
        const getTotalPriceForSaucesSelection = (basketItem: IBasketItem) => {
            if (basketItem.saucesList === undefined)
                return 0;
            let totalOptionsCost = 0;
            basketItem.saucesList.forEach(pizzaOpt => {
                totalOptionsCost += (pizzaOpt.sauceType.price);
            });
    
            return totalOptionsCost;
        }
    
        const getTotalPriceForCheesesSelection = (basketItem: IBasketItem) => {
            if (basketItem.cheeseList === undefined)
                return 0;
            let totalOptionsCost = 0;
    
            basketItem.cheeseList.forEach(pizzaOpt => {
                totalOptionsCost += (pizzaOpt.cheeseType.price);
            });
    
            return totalOptionsCost;
        }
    
        const getTotalPriceForMeatToppingsSelection = (basketItem: IBasketItem) => {
            if (basketItem.meatToppingList === undefined)
                return 0;
            let totalOptionsCost = 0;
            basketItem.meatToppingList.forEach(pizzaOpt => {
                totalOptionsCost += (pizzaOpt.meatToppingType.price);
            });
    
            return totalOptionsCost;
        }
    
        const getTotalPriceForVegToppingsSelection = (basketItem: IBasketItem) => {
            if (basketItem.vegToppingList === undefined)
                return 0;
            let totalOptionsCost = 0;
            basketItem.vegToppingList.forEach(pizzaOpt => {
                totalOptionsCost += (pizzaOpt.vegToppingType.price);
            });
    
            return totalOptionsCost;
        }
    
        const getTotalPriceForOtherToppingsSelection = (basketItem: IBasketItem) => {
            if (basketItem.otherToppingList === undefined)
                return 0;
            let totalOptionsCost = 0;
            basketItem.otherToppingList.forEach(pizzaOpt => {
                totalOptionsCost += (pizzaOpt.otherToppingType.price);
            });
    
            return totalOptionsCost;
        }
    
    */
    const getTotalPriceForSingleItem = (basketItem: IBasketItem) => {

        // let basePrice = basketItem.price
        //     + getTotalPriceForCrustSelection(basketItem)
        //     + getTotalPriceForDipsSelection(basketItem)
        //     + getTotalPriceForSaucesSelection(basketItem)
        //     + getTotalPriceForCheesesSelection(basketItem)
        //     + getTotalPriceForMeatToppingsSelection(basketItem)
        //     + getTotalPriceForVegToppingsSelection(basketItem)
        //     + getTotalPriceForOtherToppingsSelection(basketItem);

        return Math.round(basketItem.price * 100 + Number.EPSILON) / 100;
        //return basePrice;

    }

    const getTotalPrice = (basketItem: IBasketItem) => {
        let pricePerItem = getTotalPriceForSingleItem(basketItem);
        let totalPrice = pricePerItem * basketItem.quantity;
        return Math.round(totalPrice * 100 + Number.EPSILON) / 100;
    }

    const isBusinessHours = () => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let now = new Date();
        let day = days[now.getDay()];
        let hour = now.getHours();
        //console.log(`day ${day} hour ${hour}`);

        // Going back to business hours
        if (true) return false;

        if (day === 'Tuesday' || hour < 14 || hour >= 23)
            return false;
        return true;
    }

    return (
        <div className="cartContent">

            <div className="cartContent__header">


                {(customerBasket === null || customerBasket.items.length === 0) ?
                    (
                        <div></div>
                    ) : (
                        <Fragment>
                            <div>&nbsp;</div>
                            {isBusinessHours() ? (
                                <button className="bapoo-btn btn-enabled" onClick={() => checkoutOrder()}>Check out</button>) : (
                                <div className="cartContent__closedMessage">Our Kitchen is currently closed for online orders!</div>
                            )}

                        </Fragment>

                    )}


            </div>


            <div className="cartContent__center">
                <div className="grid-table">
                    {(customerBasket === null || customerBasket.items.length === 0) ?
                        (
                            <div>There are no items in your basket</div>
                        ) : (
                            <Fragment>
                                <div className="grid-table-row">
                                    <div className="grid-table-cell grid-table-cell__image grid-table-cell__heading "></div>
                                    <div className="grid-table-cell grid-table-cell__desc grid-table-cell__heading">Bapoo Cuisine</div>
                                    <div className="grid-table-cell grid-table-cell__price grid-table-cell__heading">Price</div>
                                    <div className="grid-table-cell grid-table-cell__quantity grid-table-cell__heading">Qty</div>
                                    <div className="grid-table-cell grid-table-cell__total grid-table-cell__heading">Total</div>
                                    <div className="grid-table-cell grid-table-cell__trash grid-table-cell__heading">Remove </div>
                                </div>
                                <div className="grid-table-row">
                                    <div className="grid-table-cell-filler"></div>
                                </div>
                                {customerBasket.items.map((basketItem, index) => (
                                    <Fragment key={index}>

                                        <div key={basketItem.id} className="grid-table-row">
                                            <div className="grid-table-cell grid-table-cell__image" >
                                                <div className="grid-product__image">
                                                    <img src={basketItem.pictureUrl} alt='Product' />
                                                </div>
                                            </div>
                                            <div className="grid-table-cell grid-table-cell__desc" >
                                                {basketItem.productName} ({basketItem.size})

                                                <Fragment>
                                                    {basketItem.category.indexOf("Sides") === -1 &&
                                                        <CrustTypeInCart basketItem={basketItem} />
                                                    }

                                                    <DippingsInCart basketItem={basketItem} />
                                                    <SaucesInCart basketItem={basketItem} />
                                                    <CheesesInCart basketItem={basketItem} />
                                                    <MeatToppingsInCart basketItem={basketItem} />
                                                    <VegToppingsInCart basketItem={basketItem} />
                                                    <OtherToppingsInCart basketItem={basketItem} />
                                                    <CommentsInCart basketItem={basketItem} />

                                                </Fragment>

                                            </div>
                                            <div className="grid-table-cell grid-table-cell__price">

                                                {basketItem.isCustom ? (
                                                    <Fragment> $ {getTotalPriceForSingleItem(basketItem)}</Fragment>
                                                ) : (
                                                    <Fragment> $ {basketItem.price}</Fragment>
                                                )}
                                            </div>
                                            <div className="grid-table-cell grid-table-cell__quantity">
                                                <div className="flexcontainer">
                                                    <div><FaMinusCircle size={18} onClick={() => decrementItemQuantity(basketItem)} /></div>
                                                    <div style={{ fontSize: '1.4rem' }}>{basketItem.quantity}</div>
                                                    <div><FaPlusCircle size={18} onClick={() => incrementItemQuantity(basketItem)} /></div>
                                                </div>
                                            </div>
                                            <div className="grid-table-cell grid-table-cell__total"><strong>
                                                {basketItem.isCustom ? (
                                                    <Fragment> $ {getTotalPrice(basketItem)}</Fragment>
                                                ) : (
                                                    <Fragment>  $ {basketItem.quantity * basketItem.price}</Fragment>
                                                )}



                                            </strong></div>
                                            <div className="grid-table-cell grid-table-cell__trash">
                                                <FaTrash color='red' onClick={() => removeItemFromBasket(basketItem)} />
                                            </div>
                                        </div>
                                        <div className="grid-table-row">
                                            <div className="grid-table-cell-filler"></div>
                                        </div>
                                    </Fragment>

                                ))}

                            </Fragment>

                        )}
                </div>

                {(customerBasket === null || customerBasket.items.length === 0) ?
                    (
                        <div></div>
                    ) : (
                        <div>
                            <div className="grid-totals-table">
                                <div className="grid-totals-table-subtotal-heading">SubTotal : </div>
                                <div className="grid-totals-table-subtotal">$ {SubTotal}</div>
                                <div className="grid-totals-table-taxes-heading">Taxes :</div>
                                <div className="grid-totals-table-taxes">$ {Taxes}</div>
                                <div className="grid-totals-table-total-heading">Total :</div>
                                <div className="grid-totals-table-total">$ {Total}</div>
                            </div>
                        </div>

                    )}
            </div>


            {(customerBasket !== null && customerBasket.items.length !== 0) && (
                <div className="cartContent__footer">
                    {isBusinessHours() ? (
                        <button className="bapoo-btn btn-enabled" onClick={() => checkoutOrder()}>Check out</button>) : (
                        <div>&nbsp;</div>
                    )}
                </div>

            )}



        </div>
    )
}

export default observer(CartContentGrid)
