import React, { Fragment } from 'react'
import { FaMinus, FaMinusCircle, FaPlus, FaPlusCircle, FaRegHandPointUp } from 'react-icons/fa'
import { GiChiliPepper } from 'react-icons/gi'
import { IOtherToppingType, IPizzaAccessory } from '../../../app/models/PizzaOptions';


interface IProps {
    otherToppingTypes: IOtherToppingType[];
    minimize: boolean;
    maximize: (section: string, minimize: boolean) => void;
    pizzaOtherToppings: IPizzaAccessory[];
    setPizzaOtherToppings: (pizzaOtherToppings: IPizzaAccessory[]) => void;

}

const OtherOptions: React.FC<IProps> = ({ otherToppingTypes, minimize, maximize, pizzaOtherToppings, setPizzaOtherToppings }) => {

    const leftSideText = "Left Side: ";
    const rightSideText = "Right Side: ";
    const fullSideText = "Full Pizza: ";

    const getPizzaAccessoryGivenId = (id: number) => {
        const pizzaSauce = pizzaOtherToppings.filter(x => x.id === id)[0];
        return pizzaSauce;
    }
    const getPizzaOptionsFromDbGivenId = (id: number) => {
        const pizzaDip = otherToppingTypes.filter(x => x.id === id)[0];
        return pizzaDip;
    }

    const scaleToString = (scaleVal: number) => {
        switch (scaleVal) {
            case 0:
                return 'none';
            case 1:
                return 'light';
            case 2:
                return 'medium';
            case 3:
                return 'heavy';
        }
    }

    const getQuantity = (id: number) => {
        const pizzaCheese = getPizzaAccessoryGivenId(id);
        if (pizzaCheese === null || pizzaCheese === undefined)
            return scaleToString(0);
        return scaleToString(pizzaCheese.quantityScale);
    }


    const toggleSideSelection = (id: number, selectedSide: string, otherSide: string) => {
        try {
            let pizzaAccessory = getPizzaAccessoryGivenId(id);
            if (pizzaAccessory === undefined) {
                pizzaAccessory = { ...getPizzaOptionsFromDbGivenId(id), quantity: 0, quantityScale: 0 };
                pizzaOtherToppings.push(pizzaAccessory);
            }

            if (pizzaAccessory.description.includes(selectedSide) || pizzaAccessory.description.includes(fullSideText)) {
                if (pizzaAccessory.description.includes(selectedSide)) {
                    pizzaAccessory.description = pizzaAccessory.description.replace(selectedSide, "");
                    pizzaAccessory.quantityScale = 0;
                }
                if (pizzaAccessory.description.includes(fullSideText)) {
                    pizzaAccessory.description = pizzaAccessory.description.replace(fullSideText, "");
                    pizzaAccessory.description = otherSide + pizzaAccessory.description;
                    pizzaAccessory.quantityScale = 1;
                }
            } else {
                if (pizzaAccessory.description.includes(otherSide)) {
                    pizzaAccessory.description = pizzaAccessory.description.replace(otherSide, "");
                    pizzaAccessory.description = fullSideText + pizzaAccessory.description;
                } else {
                    pizzaAccessory.description = selectedSide + pizzaAccessory.description;
                }
                pizzaAccessory.quantityScale = 1;

            }
            setPizzaOtherToppings([...pizzaOtherToppings]);

        } catch (error) {
            console.log(error);
        }
    }

    const getSideSelectionComponentText = (id: number) => {

        try {
            let pizzaAccessory = getPizzaAccessoryGivenId(id);
            if (pizzaAccessory === undefined) {
                return (<div className='selectionlabel'><label>&nbsp;&nbsp;&nbsp;&nbsp;<FaRegHandPointUp size='23' />&nbsp; &nbsp; &nbsp;click to select</label></div>);
            }

            if (pizzaAccessory.description.includes(leftSideText)) {
                return (<div className='selectionlabel'><label>Left Side Selected</label></div>);
            }
            if (pizzaAccessory.description.includes(rightSideText)) {
                return (<div className='selectionlabel'><label>Right Side Selected</label></div>);
            }
            if (pizzaAccessory.description.includes(fullSideText)) {
                return (<div className='selectionlabel'><label>Full Pizza Selected</label></div>);
            }


        } catch (error) {
            console.log(error);
        }

    }

    const getLeftSideCssClasses = (id: number) => {
        let defaultClasses = 'halfleft left';
        if (isLeftSideSelected(id)) {
            defaultClasses = defaultClasses + ' selected';
        }
        return defaultClasses;
    }
    const getRightSideCssClasses = (id: number) => {
        let defaultClasses = 'halfleft right';
        if (isRightSideSelected(id)) {
            defaultClasses = defaultClasses + ' selected';
        }
        return defaultClasses;
    }


    const isLeftSideSelected = (id: number): boolean => {
        try {
            let pizzaAccessory = getPizzaAccessoryGivenId(id);
            if (pizzaAccessory === undefined) {
                return false;
            }

            if (pizzaAccessory.description.includes(leftSideText) || pizzaAccessory.description.includes(fullSideText)) {
                return true;
            } else {
                return false;
            }

        } catch (error) {
            console.log(error);
            return false;
        }
    }

    const isRightSideSelected = (id: number): boolean => {
        try {
            let pizzaAccessory = getPizzaAccessoryGivenId(id);
            if (pizzaAccessory === undefined) {
                return false;
            }

            if (pizzaAccessory.description.includes(rightSideText) || pizzaAccessory.description.includes(fullSideText)) {
                return true;
            } else {
                return false;
            }

        } catch (error) {
            console.log(error);
            return false;
        }
    }

    const selectLeftSide = (id: number) => {
        try {
            toggleSideSelection(id, leftSideText, rightSideText);
        } catch (error) {
            console.log(error);
        }
    }
    const selectRightSide = (id: number) => {
        try {
            toggleSideSelection(id, rightSideText, leftSideText);
        } catch (error) {
            console.log(error);
        }
    }

    const incrementQuantity = (id: number, quantityScale: number = 1) => {
        try {
            let pizzaAccessory = getPizzaAccessoryGivenId(id);
            if (pizzaAccessory === undefined) {
                pizzaAccessory = { ...getPizzaOptionsFromDbGivenId(id), quantity: 0, quantityScale: 1 };
                pizzaOtherToppings.push(pizzaAccessory);
            } else {
                pizzaAccessory.quantityScale += 1;
                if (pizzaAccessory.quantityScale >= 3)
                    pizzaAccessory.quantityScale = 3;

            }
            setPizzaOtherToppings([...pizzaOtherToppings]);

        } catch (error) {
            console.log(error);
        }
    }
    const decrementQuantity = (id: number) => {
        try {
            let pizzaAccessory = getPizzaAccessoryGivenId(id);

            if (pizzaAccessory !== undefined) {
                pizzaAccessory.quantityScale -= 1;
            }

            if (pizzaAccessory.quantityScale === 0) {
                pizzaOtherToppings = pizzaOtherToppings.filter(x => x.id !== id);
            }
            setPizzaOtherToppings([...pizzaOtherToppings]);

        } catch {

        }
    }



    return (
        <Fragment>
            {minimize ? (
                <Fragment>
                    <div className="r-section__h4" onClick={() => maximize('other', true)}>
                        <span><GiChiliPepper size='35' /></span>
                        <span>Other Topping(s) Completely Free!:</span>
                        <span><FaPlusCircle /></span>
                    </div>

                </Fragment>
            ) : (
                    <Fragment>
                        <div className="r-section__h4" onClick={() => maximize('other', false)}>
                            <span><GiChiliPepper size='35' /></span>
                            <span>Other Topping(s) Completely Free!:</span>
                            <span><FaMinusCircle /></span>
                        </div>
                        <div className="r-section__p r-section__h4__expanded">
                            {otherToppingTypes.map(otherToppingType => (
                                <div key={otherToppingType.id} className='quantitycounterwithsizes'>
                                    <div className='quantitylabel'><label>{otherToppingType.name}</label></div>
                                    <div>
                                        <div className={getLeftSideCssClasses(otherToppingType.id)} onClick={() => selectLeftSide(otherToppingType.id)}></div>
                                        <div className={getRightSideCssClasses(otherToppingType.id)} onClick={() => selectRightSide(otherToppingType.id)}></div>
                                    </div>
                                    <div className="quantityscalecontainer">
                                        <FaMinus className="bapoo__quantity_icon" onClick={() => decrementQuantity(otherToppingType.id)} />
                                        <input className="bapoo__quantity_input" type="text" readOnly name="quantityScale" id="quantityScale" value={getQuantity(otherToppingType.id)} />
                                        <FaPlus className="bapoo__quantity_icon" onClick={() => incrementQuantity(otherToppingType.id)} />
                                    </div>
                                    {getSideSelectionComponentText(otherToppingType.id)}

                                </div>

                            ))}

                        </div>
                        <div className="r-section__h4__footer">&nbsp;</div>

                    </Fragment>
                )}

        </Fragment>

    )
}

export default OtherOptions
