import React, { Fragment } from 'react'
import VegTraditionals from '../VegTraditionals'

const VegTraditionalSpecific = () => {
    return (
        <Fragment>
            <div className="menu_banner">
                <h1>Our Menu</h1>
                <h4> ... always good &amp; fresh !</h4>
            </div>
            <section className="menus">
                <VegTraditionals />
            </section>
        </Fragment>

    )
}

export default VegTraditionalSpecific
