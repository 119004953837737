import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const MeatGourmet = () => {
    return (
        <Fragment>
            <div className="menus__heading" id='MeatGourmet'>
                <h1>Bapoo's Meat Gourmet</h1>
            </div>
            <div className="menus__subheading">
                <Link to={'/'}> &lt;&lt;&nbsp;Back To Home</Link>
            </div>
            <div className="menu">
                <img src="images/menu/meatGourmet.jpg" alt="" className="menu__img" />

                <h5 className="menu__name">Pesto Chicken</h5>
                <span className="menu__subtext">Pesto Sauce,Roasted Red Peppers,Feta cheese,Red Onions,
                    Mushrooms and Grilled Chicken Breast</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Small $ 13.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Medium $ 16.75</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Large $ 19.75</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Ex. Large $ 22.25</p>
                </div>

                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menu">
                <img src="images/menu/meatTraditionals.jpg" alt="" className="menu__img" />

                <h5 className="menu__name">Creamy Butter Chicken</h5>
                <span className="menu__subtext">Indian Butter Sauce, Chicken Tikka Chunks with Red Onions and Roasted Red Peppers, slow cooked.</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Small $ 13.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Medium $ 16.75</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Large $ 19.25</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Ex. Large $ 22.25</p>
                </div>

                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menu">
                <img src="images/menu/meatGourmet.jpg" alt="" className="menu__img" />

                <h5 className="menu__name">Tandoori Chicken Tikka</h5>
                <span className="menu__subtext">Spicy San Marzano Marinara, Chicken Tikka Chunks,Red Onions,Jalapenos and Hot Banana Peppers</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Small $ 13.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Medium $ 16.75</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Large $ 19.25</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Ex. Large $ 22.25</p>
                </div>

                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menu">
                <img src="images/menu/meatTraditionals.jpg" alt="" className="menu__img" />

                <h5 className="menu__name">Bapoo's Sheekh Kabab</h5>
                <span className="menu__subtext">Chicken Seekh Kabab, Green Pepper,Red Onions and Sweet Corn</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Small $ 13.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Medium $ 16.75</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Large $ 19.75</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Ex. Large $ 22.25</p>
                </div>

                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menu">
                <img src="images/menu/meatGourmet.jpg" alt="" className="menu__img" />

                <h5 className="menu__name">Meat Overload</h5>
                <span className="menu__subtext">Italian Sausage, Bacon Strips, Pepperoni, Grilled Chicken and Prosciutto</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Small $ 14.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Medium $ 17.75</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Large $ 20.75</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Ex. Large $ 23.25</p>
                </div>

                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menu">
                <img src="images/menu/meatTraditionals.jpg" alt="" className="menu__img" />

                <h5 className="menu__name">Spicy Lamb</h5>
                <span className="menu__subtext">Spicy Lamb Chunks, Red Onions, Mint and Sun Dried Tomatoes</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Small $ 13.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Medium $ 16.75</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Large $ 19.75</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Ex. Large $ 22.25</p>
                </div>

                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menus__subheading">
                <Link to={'/'}> &lt;&lt;&nbsp;Back To Home</Link>
            </div>

        </Fragment>
    )
}

export default MeatGourmet
