import React, { Fragment } from 'react'
import { IBasketItem } from '../../../app/models/Basket'

interface IProps {
    basketItem: IBasketItem;
}
const SaucesInCart: React.FC<IProps> = ({ basketItem }) => {

    if (basketItem.sauceType === undefined || basketItem.sauceType?.length <= 0) {
        return <Fragment></Fragment>
    }
    return (
        <div>
            <b>Sauce:</b> &nbsp;{basketItem.sauceType}
            {/*basketItem.saucesList !== undefined && basketItem.saucesList?.length > 0 && (
                basketItem.saucesList?.map(item => (
                    <span key={item.sauceType.id}>{item.sauceType.name}({item.quantity})</span>
                ))
                )*/}
        </div>

    )
}

export default SaucesInCart
