import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const MeatTraditionals = () => {
    return (
        <Fragment>
            <div className="menus__heading" id='MeatTraditionals'>
                <h1>Bapoo's Meat Traditional's</h1>
            </div>
            <div className="menus__subheading">
                <Link to={'/'}> &lt;&lt;&nbsp;Back To Home</Link>
            </div>
            <div className="menu">
                <img src="images/menu/meatTraditionals.jpg" alt="" className="menu__img" />

                <h5 className="menu__name pepperoni">Classic Pepperoni</h5>
                <span className="menu__subtext">Classic Pepperoni with tomato sauce, mozzarella, anchovies,
                    oregano, basil</span>
                <div className="menu__detail_1">
                    <svg className="menu__like pepperoni">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Small $ 8.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like pepperoni">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Medium $ 10.99</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="menu__like pepperoni">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Large $ 13.55</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="pepperoni">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Ex. Large $ 15.20</p>
                </div>

                <button className="btn menu__btn pepperoni">Order Now</button>

            </div>
            <div className="menu">
                <img src="images/menu/meatGourmet.jpg" alt="" className="menu__img" />

                <h5 className="menu__name hawaiin">Hawaiin</h5>
                <span className="menu__subtext">Choice of [Grilled Chicken Breast / Ham Slices/ Baclon bits]
                    and Pineappble</span>
                <div className="menu__detail_1">
                    <svg className="hawaiin">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Small $ 10.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="hawaiin">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Medium $ 13.75</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="hawaiin">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Large $ 16.75</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="hawaiin">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Ex. Large $ 19.25</p>
                </div>

                <button className="btn menu__btn hawaiin">Order Now</button>

            </div>
            <div className="menu">
                <img src="images/menu/meatTraditionals.jpg" alt="" className="menu__img" />

                <h5 className="menu__name">The Canadian</h5>
                <span className="menu__subtext">Pepperoni, Mushroooms and Backin bits cooked to perfection</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Small $ 10.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Medium $ 13.75</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Large $ 16.75</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Ex. Large $ 19.25</p>
                </div>

                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menu">
                <img src="images/menu/meatGourmet.jpg" alt="" className="menu__img" />

                <h5 className="menu__name">Meat Lovers</h5>
                <span className="menu__subtext">Pepperoni, Bacon bits and Ham Slices slow grilled in marniate</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Small $ 10.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Medium $ 13.75</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Large $ 16.75</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Ex. Large $ 19.25</p>
                </div>

                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menu">
                <img src="images/menu/meatTraditionals.jpg" alt="" className="menu__img" />

                <h5 className="menu__name">Classic Chicken</h5>
                <span className="menu__subtext">Grilled Chicken Breast, Read Onions and Green Peppers</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Small $ 11.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Medium $ 14.75</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Large $ 17.75</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Ex. Large $ 20.25</p>
                </div>

                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menus__subheading">
                <Link to={'/'}> &lt;&lt;&nbsp;Back To Home</Link>
            </div>

        </Fragment>
    )
}

export default MeatTraditionals
