import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { ICustomerBasket } from "../models/Basket";
import { ICategoryCompact, IProduct } from "../models/Category";
import { IOrder, IOrderRequest, IPaginatedOrders } from "../models/Order";
import { IPizzaOptions } from "../models/PizzaOptions";
import { User, UserFormValues } from "../models/User";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
    const token = window.localStorage.getItem('BapooPizzaToken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    Promise.reject(error);
})

axios.interceptors.response.use(undefined, error => {

    if (error.message === 'Network Error' && !error.response) {
        toast.error('Network error - make sure API is running!')
    }
    /*
    const { status, data, config, headers } = error.response;

    if (status === 401 && headers['www-authenticate'] === 'Bearer error="invalid_token", error_description="The access token expired"') {
        window.localStorage.removeItem('Brisk_JWT');
        history.push('/');
        toast.info('Your session has expired!, please login again');
    }

    if (status === 404) {
        history.push('/notfound')
    }
    if (status === 400 && config.method === 'get' && data.errors.hasOwnProperty('id')) {
        history.push('/notfound')
    }
    if (status === 500) {
        toast.error('Server error - check the terminal for more info!')
    }
    */
    throw error.response;
})

const responseBody = (response: AxiosResponse) => response.data;

// const sleep = (ms: number) => (response: AxiosResponse) =>
//     new Promise<AxiosResponse>(resolve => setTimeout(() => resolve(response), ms));

const requests = {
    //get: (url: string) => axios.get(url).then(sleep(1500)).then(responseBody),
    get: (url: string) => axios.get(url).then(responseBody),
    post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    del: (url: string) => axios.delete(url).then(responseBody),
    postForm: (url: string, file: Blob) => {
        let formData = new FormData();
        formData.append('File', file);
        return axios.post(url, formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        }).then(responseBody);
    },
    getAsByteStream: (url: string) => axios.get(url, { responseType: 'blob' }).then(responseBody)
};

const products = {
    getProduct: (id: string): Promise<IProduct> => requests.get(`/products/${id}`),
    //list: (): Promise<[IProduct]> => requests.get('/products'),
    categories: (): Promise<ICategoryCompact[]> => requests.get('/products/categories'),
    categoryDetails: (id: string): Promise<ICategoryCompact> => requests.get(`/products/categorydetails/${id}`),
    pizzaOptions: (id: string): Promise<IPizzaOptions> => requests.get(`/products/pizzaoptions/${id}`),
}

const basket = {
    get: (id: string): Promise<ICustomerBasket> => requests.get('/basket?id=' + id),
    update: (customerBasket: ICustomerBasket): Promise<ICustomerBasket> => requests.post('/basket', customerBasket),
    delete: (id: string): Promise<boolean> => requests.del('/basket?id=' + id),
}
const orders = {
    create: (orderRequest: IOrderRequest): Promise<IOrder> => requests.post('/orders', orderRequest),
    getOrderNo: (orderNo: string): Promise<IOrder> => requests.get(`/orders/orderno/${orderNo}`)
}

const payments = {
    createOrUpdateIntent: (basketId: string): Promise<ICustomerBasket> => requests.post(`/payments/${basketId}`, {})
}

const kitchenkiosk = {
    list: (): Promise<IOrder[]> => requests.get(`/kichenkiosk/list`),
    pagedList: (params: URLSearchParams): Promise<IPaginatedOrders> => axios.get('/kichenkiosk/list', { params: params }).then(responseBody),
    getOrderNo: (orderNo: string): Promise<IPaginatedOrders> => requests.get(`/kichenkiosk/${orderNo}`),
    acceptOrder: (orderNo: number) => requests.post(`/kichenkiosk/accept/${orderNo}`, {}),
    rejectOrder: (orderNo: number) => requests.post(`/kichenkiosk/reject/${orderNo}`, {}),
    cancelOrder: (orderNo: number) => requests.post(`/kichenkiosk/cancel/${orderNo}`, {}),
    completeOrder: (orderNo: number) => requests.post(`/kichenkiosk/complete/${orderNo}`, {}),
    updateTime: (orderNo: number, timeInMinutes: string) => requests.post(`/kichenkiosk/updatetime/${orderNo}/${timeInMinutes}`, {}),

}

const account = {
    current: (): Promise<User> => requests.get('/user'),
    login: (user: UserFormValues): Promise<User> => requests.post(`/user/login`, user),
    register: (user: UserFormValues): Promise<User> => requests.post(`/user/register`, user)
}


const httpMethods = {
    products,
    basket,
    orders,
    payments,
    kitchenkiosk,
    account
}
export default httpMethods;