import React, { Fragment } from 'react'
import { FaMinus, FaMinusCircle, FaPlus, FaPlusCircle, FaRegHandPointUp } from 'react-icons/fa'
import { GiCheeseWedge } from 'react-icons/gi'
import { ICheeseType, IPizzaAccessory } from '../../../app/models/PizzaOptions';

interface IProps {
    cheeseTypes: ICheeseType[];
    minimize: boolean;
    maximize: (section: string, minimize: boolean) => void;
    pizzaCheeses: IPizzaAccessory[];
    setPizzaCheeses: (pizzaCheeses: IPizzaAccessory[]) => void;

}

const CheeseOptions: React.FC<IProps> = ({ cheeseTypes, minimize, maximize, pizzaCheeses, setPizzaCheeses }) => {

    const leftSideText = "Left Side: ";
    const rightSideText = "Right Side: ";
    const fullSideText = "Full Pizza: ";

    const getPizzaAccessoryGivenId = (id: number) => {
        const pizzaSauce = pizzaCheeses.filter(x => x.id === id)[0];
        return pizzaSauce;
    }
    const getPizzaOptionsFromDbGivenId = (id: number) => {
        const pizzaDip = cheeseTypes.filter(x => x.id === id)[0];
        return pizzaDip;
    }


    const scaleToString = (scaleVal: number) => {
        switch (scaleVal) {
            case 0:
                return 'none';
            case 1:
                return 'light';
            case 2:
                return 'medium';
            case 3:
                return 'heavy';
        }
    }

    const getQuantity = (id: number) => {
        const pizzaCheese = getPizzaAccessoryGivenId(id);
        if (pizzaCheese === null || pizzaCheese === undefined)
            return scaleToString(0);
        return scaleToString(pizzaCheese.quantityScale);
    }


    const toggleSideSelection = (id: number, selectedSide: string, otherSide: string) => {
        try {
            let pizzaAccessory = getPizzaAccessoryGivenId(id);
            if (pizzaAccessory === undefined) {
                pizzaAccessory = { ...getPizzaOptionsFromDbGivenId(id), quantity: 0, quantityScale: 0 };
                pizzaCheeses.push(pizzaAccessory);
            }

            if (pizzaAccessory.description.includes(selectedSide) || pizzaAccessory.description.includes(fullSideText)) {
                if (pizzaAccessory.description.includes(selectedSide)) {
                    pizzaAccessory.description = pizzaAccessory.description.replace(selectedSide, "");
                    pizzaAccessory.quantityScale = 0;
                }
                if (pizzaAccessory.description.includes(fullSideText)) {
                    pizzaAccessory.description = pizzaAccessory.description.replace(fullSideText, "");
                    pizzaAccessory.description = otherSide + pizzaAccessory.description;
                    pizzaAccessory.quantityScale = 1;
                }
            } else {
                if (pizzaAccessory.description.includes(otherSide)) {
                    pizzaAccessory.description = pizzaAccessory.description.replace(otherSide, "");
                    pizzaAccessory.description = fullSideText + pizzaAccessory.description;
                } else {
                    pizzaAccessory.description = selectedSide + pizzaAccessory.description;
                }
                pizzaAccessory.quantityScale = 1;

            }
            setPizzaCheeses([...pizzaCheeses]);

        } catch (error) {
            console.log(error);
        }
    }

    const getSideSelectionComponentText = (id: number) => {

        try {
            let pizzaAccessory = getPizzaAccessoryGivenId(id);
            if (pizzaAccessory === undefined) {
                return (<div className='selectionlabel'><label>&nbsp;&nbsp;&nbsp;&nbsp;<FaRegHandPointUp size='23' />&nbsp; &nbsp; &nbsp;click to select</label></div>);
            }

            if (pizzaAccessory.description.includes(leftSideText)) {
                return (<div className='selectionlabel'><label>Left Side Selected</label></div>);
            }
            if (pizzaAccessory.description.includes(rightSideText)) {
                return (<div className='selectionlabel'><label>Right Side Selected</label></div>);
            }
            if (pizzaAccessory.description.includes(fullSideText)) {
                return (<div className='selectionlabel'><label>Full Pizza Selected</label></div>);
            }


        } catch (error) {
            console.log(error);
        }

    }

    const getLeftSideCssClasses = (id: number) => {
        let defaultClasses = 'halfleft left';
        if (isLeftSideSelected(id)) {
            defaultClasses = defaultClasses + ' selected';
        }
        return defaultClasses;
    }
    const getRightSideCssClasses = (id: number) => {
        let defaultClasses = 'halfleft right';
        if (isRightSideSelected(id)) {
            defaultClasses = defaultClasses + ' selected';
        }
        return defaultClasses;
    }


    const isLeftSideSelected = (id: number): boolean => {
        try {
            let pizzaAccessory = getPizzaAccessoryGivenId(id);
            if (pizzaAccessory === undefined) {
                return false;
            }

            if (pizzaAccessory.description.includes(leftSideText) || pizzaAccessory.description.includes(fullSideText)) {
                return true;
            } else {
                return false;
            }

        } catch (error) {
            console.log(error);
            return false;
        }
    }

    const isRightSideSelected = (id: number): boolean => {
        try {
            let pizzaAccessory = getPizzaAccessoryGivenId(id);
            if (pizzaAccessory === undefined) {
                return false;
            }

            if (pizzaAccessory.description.includes(rightSideText) || pizzaAccessory.description.includes(fullSideText)) {
                return true;
            } else {
                return false;
            }

        } catch (error) {
            console.log(error);
            return false;
        }
    }

    const selectLeftSide = (id: number) => {
        try {
            toggleSideSelection(id, leftSideText, rightSideText);
        } catch (error) {
            console.log(error);
        }
    }
    const selectRightSide = (id: number) => {
        try {
            toggleSideSelection(id, rightSideText, leftSideText);
        } catch (error) {
            console.log(error);
        }
    }

    const incrementQuantity = (id: number, quantityScale: number = 1) => {
        try {
            let pizzaAccessory = getPizzaAccessoryGivenId(id);
            if (pizzaAccessory === undefined) {
                pizzaAccessory = { ...getPizzaOptionsFromDbGivenId(id), quantity: 0, quantityScale: 1 };
                pizzaCheeses.push(pizzaAccessory);
            } else {
                pizzaAccessory.quantityScale += 1;
                if (pizzaAccessory.quantityScale >= 3)
                    pizzaAccessory.quantityScale = 3;

            }
            setPizzaCheeses([...pizzaCheeses]);

        } catch (error) {
            console.log(error);
        }
    }
    const decrementQuantity = (id: number) => {
        try {
            let pizzaAccessory = getPizzaAccessoryGivenId(id);

            if (pizzaAccessory !== undefined) {
                pizzaAccessory.quantityScale -= 1;
            }

            if (pizzaAccessory.quantityScale === 0) {
                pizzaCheeses = pizzaCheeses.filter(x => x.id !== id);
            }
            setPizzaCheeses([...pizzaCheeses]);

        } catch {

        }
    }

    return (
        <Fragment>
            {minimize ? (
                <Fragment>
                    <div className="r-section__h4" onClick={() => maximize('cheese', true)}>
                        <span><GiCheeseWedge size='35' /></span>
                        <span>Cheese(s):</span>
                        <span><FaPlusCircle /></span>
                    </div>
                </Fragment>
            ) : (
                    <Fragment>
                        <div className="r-section__h4  r-section__h4__expanded" onClick={() => maximize('cheese', false)}>
                            <span><GiCheeseWedge size='35' /></span>
                            <span>Cheese(s):</span>
                            <span><FaMinusCircle /></span>
                        </div>
                        <div className="r-section__p">

                            {cheeseTypes.map(cheeseType => (
                                <div key={cheeseType.id} className='quantitycounterwithsizes'>
                                    <div className='quantitylabel'><label>{cheeseType.name}</label></div>
                                    <div>
                                        <div className={getLeftSideCssClasses(cheeseType.id)} onClick={() => selectLeftSide(cheeseType.id)}></div>
                                        <div className={getRightSideCssClasses(cheeseType.id)} onClick={() => selectRightSide(cheeseType.id)}></div>
                                    </div>
                                    <div className="quantityscalecontainer">
                                        <FaMinus className="bapoo__quantity_icon" onClick={() => decrementQuantity(cheeseType.id)} />
                                        <input className="bapoo__quantity_input" type="text" readOnly name="quantityScale" id="quantityScale" value={getQuantity(cheeseType.id)} />
                                        <FaPlus className="bapoo__quantity_icon" onClick={() => incrementQuantity(cheeseType.id)} />

                                    </div>

                                    {getSideSelectionComponentText(cheeseType.id)}
                                </div>

                            ))}

                        </div>
                        <div className="r-section__h4__footer">&nbsp;</div>
                    </Fragment>
                )}

        </Fragment>

    )
}

export default CheeseOptions
