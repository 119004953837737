import { v4 as uuidv4 } from 'uuid';

export interface ICustomerBasket {
    id: string;
    items: IBasketItem[];
    deliveryMethodId?: number;
    clientSecret?: string;
    paymentIntentId?: string;
    comments?: string;
    mainAddress?: string;
    miscAddress?: string;
    distance?: string;
    duration?: string;
    shippingCost?: number;
}

export interface IBasketItem {
    id: number;
    randomBasketId: number;
    productName: string;
    price: number;
    quantity: number;
    size: string;
    pictureUrl: string;
    category: string;
    crustType: string;
    sauceType: string;
    isCustom: boolean;
    saucesList?: ISauceList[];
    dipList?: IDipList[];
    cheeseList?: ICheeseList[];
    meatToppingList?: IMeatToppingList[];
    vegToppingList?: IVegToppingList[];
    otherToppingList?: IOtherToppingList[];
    comments?: string;
}

export interface ISauceList {
    sauceType: IOptionType;
    quantity: string;
}
export interface IDipList {
    dippingType: IOptionType;
    quantity: string;
}
export interface ICheeseList {
    cheeseType: IOptionType;
    quantity: string;
}
export interface IMeatToppingList {
    meatToppingType: IOptionType;
    quantity: string;
}
export interface IVegToppingList {
    vegToppingType: IOptionType;
    quantity: string;
}
export interface IOtherToppingList {
    otherToppingType: IOptionType;
    quantity: string;
}

export interface IOptionType {
    id: number;
    name: string;
    description: string;
    price: number;
}

export class CustomerBasket implements ICustomerBasket {
    id = uuidv4();
    items: IBasketItem[] = [];
}