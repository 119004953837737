import React, { Fragment } from 'react'
import { ISauceType } from '../../../app/models/PizzaOptions'

import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'
import { GiLadle } from 'react-icons/gi'


interface IProps {
    sauceTypes: ISauceType[];
    minimize: boolean;
    maximize: (section: string, minimize: boolean) => void;
    sauceOptionSelected: string;
    setPizzaSauce: (sauce: string) => void;
}

const SauceOption: React.FC<IProps> = ({ sauceTypes, minimize, maximize, sauceOptionSelected, setPizzaSauce }) => {
    return (
        <Fragment>
            {minimize ? (
                <Fragment>
                    <div className="r-section__h4" onClick={() => maximize('sauce', true)}>
                        <span><GiLadle size='35' /></span>
                        <span>Sauce:</span>
                        <span><FaPlusCircle /></span>
                    </div>
                </Fragment>
            ) :
                (
                    <Fragment>
                        <div className="r-section__h4  r-section__h4__expanded" onClick={() => maximize('sauce', false)}>
                            <span><GiLadle size='35' /></span>
                            <span>Sauce:</span>
                            <span><FaMinusCircle /></span>
                        </div>
                        <div className="r-section__p">
                            {sauceTypes.map(sauceType => (
                                <div key={sauceType.id}>
                                    <input className="bapoo_radio_button" type="radio" name="sauce"
                                        value={sauceType.name}
                                        checked={sauceOptionSelected === sauceType.name}
                                        onChange={(e) => setPizzaSauce(e.target.value)} /><span onClick={() => setPizzaSauce(sauceType.name)}>&nbsp;&nbsp;{sauceType.name} </span>
                                </div>

                            ))}

                        </div>
                        <div className="r-section__h4__footer">&nbsp;</div>
                    </Fragment>
                )
            }


        </Fragment >
    )
}

export default SauceOption
