import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { CustomerBasket, IBasketItem, ICheeseList, ICustomerBasket, IDipList, IMeatToppingList, IOtherToppingList, ISauceList, IVegToppingList } from "../models/Basket";
import { IProduct } from "../models/Category";
import { ICustomizedPizzaItem, IPizzaAccessory } from "../models/PizzaOptions";
import { RootStore } from "./RootStore";

export default class BasketStore {

    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this)
        this.rootStore = rootStore;
    }

    @observable displayBasket = true;


    @observable basketId: string | null = localStorage.getItem('bapoobasketid');
    @observable customerBasket: ICustomerBasket | null = null;
    @observable loadingBasket = false;
    @observable basketTotal = 0;



    @action createPaymentIntent = async () => {
        const basket = this.customerBasket ?? this.createBasket();
        this.loadingBasket = true;
        try {
            const customerBasket = await agent.payments.createOrUpdateIntent(basket.id);
            runInAction(() => {
                this.customerBasket = customerBasket;
                this.loadingBasket = false;
            });
        } catch (error) {
            console.log(error);
            toast.error(error);
            runInAction(() => {
                this.setBasketId(null);
                this.loadingBasket = false;
            });
        }

    }


    @action loadCustomerBasket = async () => {
        if (this.basketId == null) {
            this.customerBasket = new CustomerBasket();
            this.basketId = this.customerBasket.id;
            this.setBasketId(this.basketId);
        } else if (this.customerBasket == null) {
            this.loadCustomerBasketFromId(this.basketId);
        }
    }


    @action addItemToBasket = async (item: IProduct, quantity = 1) => {
        const itemToAdd: IBasketItem = this.mapPizzaToBasketItem(item, quantity);

        const basket = this.customerBasket ?? this.createBasket();
        //this.loadCustomerBasket();
        basket.items = this.addOrUpdateItem(basket.items, itemToAdd, quantity);
        this.setBasket(basket);

        toast.success(item.name + ' add to your cart');
    }



    @action addCustomItemToBasket = async (customItem: ICustomizedPizzaItem, quantity = 1) => {
        const itemToAdd: IBasketItem = this.mapCustomPizzaToBasketItem(customItem, quantity);
        const basket = this.customerBasket ?? this.createBasket();
        basket.items = this.addOrUpdateItem(basket.items, itemToAdd, quantity);
        this.setBasket(basket);
        toast.warning(customItem.productName + ' add to your cart');
    }

    @action addItemWithIdToBasket = async (id: number, quantity = 1) => {
        this.loadingBasket = true;
        try {
            const product = await agent.products.getProduct(id.toString());
            this.addItemToBasket(product);
        } catch (error) {

        }

    }


    @action addDeliveryAddress = async (deliveryMethodId: number, mainAddress?: string, miscAddress?: string, distance?: string, duration?: string) => {

        const basket = this.customerBasket ?? this.createBasket();
        if (deliveryMethodId > 0) {
            basket.deliveryMethodId = deliveryMethodId;
            basket.mainAddress = mainAddress;
            basket.miscAddress = miscAddress;
            basket.distance = distance;
            basket.duration = duration;
        } else {
            basket.deliveryMethodId = 0;
            basket.mainAddress = '';
            basket.miscAddress = '';
            basket.distance = '';
            basket.duration = '';
        }

        await this.setBasket(basket);
    }


    @action incrementItemQuantity = async (item: IBasketItem) => {
        const basket = this.customerBasket ?? this.createBasket();
        const foundItemIndex = basket.items.findIndex(x => x.id === item.id && x.randomBasketId === item.randomBasketId);
        basket.items[foundItemIndex].quantity++;
        this.setBasket(basket);
    }


    @action decrementItemQuantity = async (item: IBasketItem) => {
        const basket = this.customerBasket ?? this.createBasket();
        const foundItemIndex = basket.items.findIndex(x => x.id === item.id && x.randomBasketId === item.randomBasketId);
        if (basket.items[foundItemIndex].quantity > 1) {
            basket.items[foundItemIndex].quantity--;
            this.setBasket(basket);
        } else {
            this.removeItemFromBasket(item);
        }
    }

    @action removeItemFromBasket = (item: IBasketItem) => {
        const basket = this.customerBasket ?? this.createBasket();
        if (basket.items.some(x => x.id === item.id && x.randomBasketId === item.randomBasketId)) {
            basket.items = basket.items.filter(i => !(i.id === item.id && i.randomBasketId === item.randomBasketId));
            if (basket.items.length > 0) {
                this.setBasket(basket);
            } else {
                this.deleteBasket();
            }
        }

    }

    setBasket = async (basket: ICustomerBasket) => {
        //agent.basket.update(basket);
        this.customerBasket = basket;
        await this.updateBasket();
        // this.calculateTotals();
    }


    @observable flatRate = 0;
    @observable freeDistance = 5;
    @observable ratePerKms = 0.5;
    @observable minimumOrderAmount = 20;
    @observable discountRateForDollars = 0.5;

    @computed get ShippingCost() {
        if (this.customerBasket === undefined || this.customerBasket === null) return 0;

        if (this.customerBasket.deliveryMethodId !== undefined && this.customerBasket.deliveryMethodId <= 0) {
            return 0;
        }
        const distanceString = this.customerBasket.distance !== undefined ? this.customerBasket.distance : '0';

        const distance = parseFloat(distanceString);

        let chargableDistance = distance - this.freeDistance;
        if (chargableDistance <= 0)
            chargableDistance = 0;
        let shipping = this.flatRate + chargableDistance * this.ratePerKms;

        let dollarDiscount = 0;
        let dollarsEligibleForDiscount = this.SubTotal - this.minimumOrderAmount;
        if (dollarsEligibleForDiscount <= 0) {
            dollarsEligibleForDiscount = 0;
        }

        dollarDiscount = dollarsEligibleForDiscount * this.discountRateForDollars;

        shipping = shipping - dollarDiscount;
        if (shipping <= 0)
            shipping = 0;
        return this.RoundNumber(shipping);
    }


    private RoundNumber(num: number) {
        return Math.round(num * 100 + Number.EPSILON) / 100
    }

    @computed get SubTotal() {
        const basket = this.customerBasket ?? this.createBasket();

        // const subtotal = basket.items.reduce((result, item) => (item.price * item.quantity) + result, 0);

        let subtotal = 0;
        basket.items.forEach(item => {
            subtotal += this.getTotalForBasketItem(item);
        });
        return this.RoundNumber(subtotal);
    }

    @computed get Taxes() {
        const taxes = (this.SubTotal + this.ShippingCost) * .13;
        return this.RoundNumber(taxes);
    }


    @computed get Total() {
        return this.RoundNumber(this.SubTotal + this.Taxes + this.ShippingCost);
    }

    private calculateTotals = () => {
        const basket = this.customerBasket ?? this.createBasket();
        const subtotal = basket.items.reduce((result, item) => (item.price * item.quantity) + result, 0);
        const total = subtotal;
        this.basketTotal = total;

    }

    private addOrUpdateItem(items: IBasketItem[], itemToAdd: IBasketItem, quantity: number): IBasketItem[] {

        const index = items.findIndex(i => i.id === itemToAdd.id);

        if (true || index === -1) {
            itemToAdd.quantity = quantity;
            items.push(itemToAdd);
        } else {
            items[index].quantity += quantity;
        }
        return items;
    }

    private createBasket(): ICustomerBasket {
        const basket = new CustomerBasket();
        localStorage.setItem('bapoobasketid', basket.id);
        return basket;
    }

    private mapPizzaToBasketItem(item: IProduct, quantity: number): IBasketItem {
        return {
            id: item.id,
            randomBasketId: this.getRandomNumber(),
            productName: item.name,
            price: item.productSizes[0].price,
            quantity,
            size: item.productSizes[0].name,
            pictureUrl: item.pictureUrl,
            category: "",
            crustType: "",
            sauceType: "",
            isCustom: false
        }
    }


    private mapCustomPizzaToBasketItem(item: ICustomizedPizzaItem, quantity: number): IBasketItem {
        return {
            id: item.productId,
            randomBasketId: this.getRandomNumber(),
            productName: item.productName,
            price: item.price,
            quantity,
            size: item.size,
            pictureUrl: item.pictureUrl,
            category: item.category,
            crustType: item.crustType,
            sauceType: item.sauceType,
            isCustom: true,
            saucesList: this.mapSaucesListFromCustomPizza(item.pizzaSauces),
            dipList: this.mapDipsListFromCustomPizza(item.pizzaDips),
            cheeseList: this.mapCheeseListFromCustomPizza(item.pizzaCheeses),
            meatToppingList: this.mapMeatToppingsListFromCustomPizza(item.pizzaMeatToppings),
            vegToppingList: this.mapVegToppingsListFromCustomPizza(item.pizzaVegToppings),
            otherToppingList: this.mapOtherToppingsListFromCustomPizza(item.pizzaOtherToppings),
            comments: item.comments
        }
    }

    private getRandomNumber = () => {
        return Math.floor(Math.random() * 100) + 1;
    }

    private mapSaucesListFromCustomPizza(selectedOptions: IPizzaAccessory[]): ISauceList[] {
        let myoptions: ISauceList[] = [];

        selectedOptions.forEach(option => {
            myoptions.push({
                quantity: option.quantityScale + '',
                sauceType: {
                    id: option.id,
                    name: option.name,
                    description: option.description,
                    price: option.price
                }
            });
        });

        return myoptions;
    }
    private mapDipsListFromCustomPizza(selectedOptions: IPizzaAccessory[]): IDipList[] {
        let myoptions: IDipList[] = [];

        selectedOptions.forEach(option => {
            myoptions.push({
                quantity: option.quantity + '',
                dippingType: {
                    id: option.id,
                    name: option.name,
                    description: option.description,
                    price: option.price
                }
            });
        });
        return myoptions;
    }
    private mapCheeseListFromCustomPizza(selectedOptions: IPizzaAccessory[]): ICheeseList[] {
        let myoptions: ICheeseList[] = [];

        selectedOptions.forEach(option => {
            myoptions.push({
                quantity: option.quantityScale + '',
                cheeseType: {
                    id: option.id,
                    name: option.name,
                    description: option.description,
                    price: option.price
                }
            });
        });
        return myoptions;
    }
    private mapMeatToppingsListFromCustomPizza(selectedOptions: IPizzaAccessory[]): IMeatToppingList[] {
        let myoptions: IMeatToppingList[] = [];

        selectedOptions.forEach(option => {
            myoptions.push({
                quantity: option.quantityScale + '',
                meatToppingType: {
                    id: option.id,
                    name: option.name,
                    description: option.description,
                    price: option.price
                }
            });
        });
        return myoptions;
    }
    private mapVegToppingsListFromCustomPizza(selectedOptions: IPizzaAccessory[]): IVegToppingList[] {
        let myoptions: IVegToppingList[] = [];
        selectedOptions.forEach(option => {
            myoptions.push({
                quantity: option.quantityScale + '',
                vegToppingType: {
                    id: option.id,
                    name: option.name,
                    description: option.description,
                    price: option.price
                }
            });
        });
        return myoptions;
    }
    private mapOtherToppingsListFromCustomPizza(selectedOptions: IPizzaAccessory[]): IOtherToppingList[] {
        let myoptions: IOtherToppingList[] = [];

        selectedOptions.forEach(option => {
            myoptions.push({
                quantity: option.quantityScale + '',
                otherToppingType: {
                    id: option.id,
                    name: option.name,
                    description: option.description,
                    price: option.price
                }
            });
        });
        return myoptions;
    }



    @computed get basketCount() {
        this.loadCustomerBasket();
        if (this.customerBasket === null) return 0;
        const count = this.customerBasket?.items.length;
        if (count === undefined) return 0;
        return count;
    }


    @action updateBasket = async () => {
        this.loadingBasket = true;
        try {
            const customerBasket = await agent.basket.update(this.customerBasket!);
            runInAction(() => {
                this.customerBasket = customerBasket;
                this.loadingBasket = false;
            });
        } catch (error) {
            console.log(error);
            toast.error(error);
            runInAction(() => {
                this.setBasketId(null);
                this.loadingBasket = false;
            });
        }
    }


    @action deleteBasket = async () => {
        this.loadingBasket = true;
        try {
            if (this.basketId == null) {
                this.setBasketId(null);
                return;
            }
            await agent.basket.delete(this.basketId!);
            runInAction(() => {
                this.setBasketId(null);
                this.customerBasket = null;
                this.loadingBasket = false;
            });
        } catch (error) {
            console.log(error);
            toast.error(error);
            runInAction(() => {
                this.setBasketId(null);
                this.loadingBasket = false;
            });
        }
    }

    @action loadCustomerBasketFromId = async (id: string) => {
        this.loadingBasket = true;
        try {
            const customerBasket = await agent.basket.get(id);
            runInAction(() => {
                this.setBasketId(customerBasket.id);
                this.customerBasket = customerBasket;
                this.loadingBasket = false;
            });
        } catch (error) {
            console.log(error);
            toast.error(error);
            runInAction(() => {
                this.setBasketId(null);
                this.loadingBasket = false;
            });
        }
    }

    @action setBasketId = (id: string | null) => {
        if (id != null) {
            localStorage.setItem('bapoobasketid', id);
        } else {
            localStorage.removeItem('bapoobasketid');
            this.customerBasket = null;
        }
    }



    getTotalForBasketItem = (basketItem: IBasketItem) => {
        //     if (basketItem.isCustom) {
        //         return this.getTotalPriceForSingleItem(basketItem) * basketItem.quantity;
        //     } else {
        return basketItem.price * basketItem.quantity;
        //     }
    }

    getTotalPriceForCrustSelection(basketItem: IBasketItem) {
        return 0;
    }
    getTotalPriceForDipsSelection(basketItem: IBasketItem) {
        if (basketItem.dipList === undefined)
            return 0;
        let totalOptionsCost = 0;
        basketItem.dipList.forEach(pizzaOpt => {
            totalOptionsCost += (pizzaOpt.dippingType.price * parseInt(pizzaOpt.quantity));
        });

        return totalOptionsCost;
    }
    getTotalPriceForSaucesSelection(basketItem: IBasketItem) {
        if (basketItem.saucesList === undefined)
            return 0;
        let totalOptionsCost = 0;
        basketItem.saucesList.forEach(pizzaOpt => {
            totalOptionsCost += (pizzaOpt.sauceType.price);
        });

        return totalOptionsCost;
    }

    getTotalPriceForCheesesSelection(basketItem: IBasketItem) {
        if (basketItem.cheeseList === undefined)
            return 0;
        let totalOptionsCost = 0;

        basketItem.cheeseList.forEach(pizzaOpt => {
            totalOptionsCost += (pizzaOpt.cheeseType.price);
        });

        return totalOptionsCost;
    }

    getTotalPriceForMeatToppingsSelection(basketItem: IBasketItem) {
        if (basketItem.meatToppingList === undefined)
            return 0;
        let totalOptionsCost = 0;
        basketItem.meatToppingList.forEach(pizzaOpt => {
            totalOptionsCost += (pizzaOpt.meatToppingType.price);
        });

        return totalOptionsCost;
    }

    getTotalPriceForVegToppingsSelection(basketItem: IBasketItem) {
        if (basketItem.vegToppingList === undefined)
            return 0;
        let totalOptionsCost = 0;
        basketItem.vegToppingList.forEach(pizzaOpt => {
            totalOptionsCost += (pizzaOpt.vegToppingType.price);
        });

        return totalOptionsCost;
    }

    getTotalPriceForOtherToppingsSelection(basketItem: IBasketItem) {
        if (basketItem.otherToppingList === undefined)
            return 0;
        let totalOptionsCost = 0;
        basketItem.otherToppingList.forEach(pizzaOpt => {
            totalOptionsCost += (pizzaOpt.otherToppingType.price);
        });

        return totalOptionsCost;
    }


    getTotalPriceForSingleItem(basketItem: IBasketItem) {

        let basePrice = basketItem.price
            + this.getTotalPriceForCrustSelection(basketItem)
            + this.getTotalPriceForDipsSelection(basketItem)
            + this.getTotalPriceForSaucesSelection(basketItem)
            + this.getTotalPriceForCheesesSelection(basketItem)
            + this.getTotalPriceForMeatToppingsSelection(basketItem)
            + this.getTotalPriceForVegToppingsSelection(basketItem)
            + this.getTotalPriceForOtherToppingsSelection(basketItem);

        return Math.round(basePrice * 100 + Number.EPSILON) / 100;
        //return basePrice;

    }

    getTotalPrice(basketItem: IBasketItem) {
        let pricePerItem = this.getTotalPriceForSingleItem(basketItem);
        let totalPrice = pricePerItem * basketItem.quantity;
        return Math.round(totalPrice * 100 + Number.EPSILON) / 100;
    }

}