import { observer } from 'mobx-react';
import React, { Fragment, useContext } from 'react'
import { TiTick } from 'react-icons/ti'
import { ProductOption } from '../../../app/models/Category';
import { RootStoreContext } from '../../../app/stores/RootStore';
import { GiChiliPepper } from 'react-icons/gi'

const ToppingsOther = () => {

    const rootContext = useContext(RootStoreContext);
    const { product, addSelectedOption, removeSelectedOption } = rootContext.productStore;
    const { selectedOtherToppings, setSelectedOtherToppings } = rootContext.customStateManager;

    const getOtherToppingOptions = () => {
        let myoptions = product?.options.filter(x => x.name.indexOf("Other Toppings") !== -1)
        if (myoptions !== undefined && myoptions?.length > 0) {
            return myoptions[0].options;
        }
        return [];
    }

    const getSelectedOption = (toppingSelection: string) => {
        let optionSelected: ProductOption | null = null;

        getOtherToppingOptions().forEach(option => {
            if (option.name === toppingSelection) {
                optionSelected = option;
            }
        });

        return optionSelected;
    }

    const isSelected = (topping: string) => {
        let match = selectedOtherToppings.filter(x => x === topping);
        if (match !== null && match.length > 0)
            return true;
        return false;
    }

    const toggleSelection = (toppingSelection: string) => {
        let match = selectedOtherToppings.filter(x => x === toppingSelection);

        let optionSelected = getSelectedOption(toppingSelection);

        if (match !== null && match.length > 0) {
            setSelectedOtherToppings(selectedOtherToppings.filter(x => x !== toppingSelection));
            if (optionSelected != null) {
                removeSelectedOption(optionSelected);
            }
        } else {
            setSelectedOtherToppings([...selectedOtherToppings, toppingSelection]);
            if (optionSelected != null) {
                addSelectedOption(optionSelected);
            }

        }
    }


    if (getOtherToppingOptions().length <= 0) {
        return <Fragment></Fragment>;
    }


    return (
        <Fragment>
            <div className="selectedProduct__content__title">
                <div className="selectedProduct__content__title__no"><GiChiliPepper color='red' /></div>
                <div className="selectedProduct__content__title__txt">
                    <p className="selectedProduct__content__title__txt-1">Bappoo's Special Toppings:</p>
                    <p className="selectedProduct__content__title__txt-2">Chose {selectedOtherToppings.length} / {getOtherToppingOptions().length}</p>
                </div>

            </div>
            <div className="selectedProduct__content__desc">
                <div className="selectedProduct__content__desc-grid">


                    {getOtherToppingOptions() !== undefined && getOtherToppingOptions().map(otherTopping => (
                        <button key={otherTopping.id} className="selectedProduct__content__desc-grid-item" onClick={() => toggleSelection(otherTopping.name)}>

                            {isSelected(otherTopping.name) && (
                                <div className="selectedProduct__content__desc-grid-item-selected"><TiTick color="white" size='22' /></div>
                            )}
                            <img className="selectedProduct__content__desc-grid-item-img" alt="ingredients" src={otherTopping.pictureUrl} />
                            <div className="selectedProduct__content__desc-grid-item-txt">
                                <p className="selectedProduct__content__desc-grid-item-txt-1">{otherTopping.name}</p>
                                <p className="selectedProduct__content__desc-grid-item-txt-2"></p>
                            </div>
                        </button>

                    ))}

                </div>
            </div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>

        </Fragment>

    )
}

export default observer(ToppingsOther)
