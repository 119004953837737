import React, { Fragment, useContext, useState } from 'react';

import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Link } from 'react-router-dom';
import { RootStoreContext } from '../../../app/stores/RootStore';
import { FaSpinner } from 'react-icons/fa';


interface IProps {
    name: string,
    phoneno: string,
    movePrevious: () => void;
}

const CheckoutPayment: React.FC<IProps> = ({ name, phoneno, movePrevious }) => {
    // const history = useHistory();
    const rootContext = useContext(RootStoreContext);
    const { createOrder, processingOrder, createOrderAndChargeCard } = rootContext.orderStore;
    //    const { customerBasket } = rootContext.basketStore;
    const { Total } = rootContext.basketStore;


    const [cardErrors, setCardErrors] = useState('');
    const [cardNumberValid, setCardNumberValid] = useState(false);
    const [cardExpiryValid, setCardExpiryValid] = useState(false);
    const [cardCvcValid, setCardCvcValid] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const payLater = async () => {
        if (name === '' || phoneno === '') {
            setCardErrors('Name and phone number are mandatory');
            return;
        }

        createOrder(name, phoneno);
        //        history.push('/orderconfirmation');

    }

    const payWithCard = async () => {
        if (name === '' || phoneno === '') {
            setCardErrors('Name and phone number are mandatory');
            return;
        }

        if (elements === null) { console.log('Stripe Element is null'); }
        else {
            const cardElement = elements.getElement(CardNumberElement);

            const billingDetails = {
                name: name + '@' + phoneno,
            };
            createOrderAndChargeCard(name + '@' + phoneno, phoneno, stripe, cardElement, billingDetails);
        }
    }

    const handleStripeCardErrors = (event: any) => {
        if (event.error) {
            setCardErrors(event.error.message);
        } else {
            setCardErrors('');
        }

        switch (event.elementType) {
            case 'cardNumber':
                setCardNumberValid(event.complete);
                break;
            case 'cardExpiry':
                setCardExpiryValid(event.complete);
                break;
            case 'cardCvc':
                setCardCvcValid(event.complete);
                break;
        }

    }

    // const createOptions = () => {
    //     return {
    //         style: {
    //             base: {
    //                 fontSize: '20px',
    //                 color: '#424770',
    //                 letterSpacing: '0.025em',
    //                 fontFamily: 'Source Code Pro, monospace',
    //                 '::placeholder': {
    //                     color: '#aab7c4',
    //                 },
    //                 padding: '2px',
    //             },
    //             invalid: {
    //                 color: '#9e2146',
    //             },
    //         },
    //     };
    // };

    const cardElementOptions = {
        style: {
            base: {
                fontSize: '18px',
                color: '#424770',
                letterSpacing: '0.025em',
                fontFamily: 'Source Code Pro, monospace',
                "::placeholder": {
                    color: '#aab7c4',
                }
            },
            invalid: {
                color: '#9e2146',
                iconColor: '#FFC7EE',
            }
        },
        hidePostalCode: true

    }
    return (
        <div className="chk-panel__content">
            <div className="chk-paynow">
                <div className="chk-paynow__lhs">
                    <label className="chk-paynow__logocontainer">
                        <div className="chk-paynow__logo">
                            <img src="/images/visa_logo.png" alt='visa' />
                            <img src="/images/mastercard_logo.png" alt='mastercard' />
                            &nbsp;&nbsp;

                        </div>
                        <div>
                            &nbsp;&nbsp;Pay $  <b><u>{Total}</u></b> with credit card
                            {cardErrors !== '' && (<span className='chk-paynow__error'><br></br>{cardErrors}</span>)}
                        </div>
                    </label>
                    <div className="chk-paynow__card column-2">
                        <label >Card Number</label>
                        <CardNumberElement options={cardElementOptions} onChange={(e) => handleStripeCardErrors(e)} />
                    </div>
                    <div className="chk-paynow__cvc">
                        <div>
                            <label>Valid thru</label>
                            <CardExpiryElement options={cardElementOptions} onChange={(e) => handleStripeCardErrors(e)} />
                        </div>
                    </div>
                    <div className="chk-paynow__cvc">
                        <div>
                            <label>CVV / CVC *</label>
                            <CardCvcElement options={cardElementOptions} onChange={(e) => handleStripeCardErrors(e)} />
                        </div>
                    </div>
                    <span className="chk-paynow__info disclaimer">* CVV or CVC is the card security code, unique three digits number on the back of your card separate from its number.</span>
                    <div className="chk-paynow__buttons">
                        <button className="bapoo-btn btn-enabled" onClick={movePrevious}>Back</button>


                        {processingOrder || !cardNumberValid || !cardCvcValid || !cardExpiryValid || name === '' || phoneno === '' ? (
                            <Link to='#' className="bapoo-btn btn-disabled">
                                {processingOrder ? (
                                    <FaSpinner className="spinner" />
                                ) : (
                                    <Fragment>Fill Card Details</Fragment>
                                )}

                            </Link>

                        ) : (
                            <Link to='#' className="bapoo-btn btn-success" onClick={() => payWithCard()}>Pay Now</Link>
                        )}

                    </div>
                </div>
                <div className="chk-paynow__rhs">
                    <label className="chk-paynow__logo">
                        <span className="chk-paynow__bigtext">
                            Pay At Pickup
                        </span> &nbsp;&nbsp;

                    </label>
                    <div>
                        &nbsp;&nbsp;Pay <b><u>{Total}</u></b> at pickup

                    </div>

                    <span className="chk-paynow__info">
                        You can pay us by cash, credit or debit at our location at <b><u>135 Gateway Park Drive, Kitchener</u></b>  when you pick up your order.
                    </span>
                    <div className="chk-paynow__buttons">
                        <button className="bapoo-btn btn-enabled" onClick={movePrevious} >Back</button>
                        <button className="bapoo-btn btn-enabled" onClick={() => payLater()}>Confirm &amp; Pay Later</button>
                    </div>
                </div>

            </div>
        </div >
    )
}

export default CheckoutPayment
