import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { IOrder } from "../models/Order";
import { RootStore } from "./RootStore";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";


export const CreateOrderGroup = "OrderMonitors";

export default class KioskStore {
    rootStore: RootStore;
    @observable hubConnection: HubConnection | null = null;
    constructor(rootStore: RootStore) {
        makeAutoObservable(this)
        this.rootStore = rootStore;
    }

    createHubConnection = () => {
        if (this.hubConnection !== null) {
            //HubConnectionState.Disconnected
            this.stopHubConnection();
        }
        console.log("Creating New HubConnection ");
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_CHAT_URL!, {
                accessTokenFactory: () => this.rootStore.userStore.user?.token!
            })
            .withAutomaticReconnect(/*{
                nextRetryDelayInMilliseconds: retryContext => {
                    if (retryContext.elapsedMilliseconds < 60000) {
                        // If we've been reconnecting for less than 60 seconds so far,
                        // wait between 0 and 10 seconds before the next reconnect attempt.
                        return Math.random() * 10000;
                    } else {
                        // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                        return null;
                    }
                }
            }*/)
            .configureLogging(LogLevel.Information)
            .build();

        this.loadOrders();
        this.hubConnection.onreconnecting((error) => {
            console.log(`Connection lost due to error "${error}". Reconnecting.`);
        });

        this.hubConnection.start()
            .then(() => console.log(this.hubConnection!.state))
            .then(() => {
                console.log('Attempting to join group', CreateOrderGroup);
                this.hubConnection!.invoke('JoinGroup', CreateOrderGroup);
            })
            .catch((error) => console.log('Error Establishing connection', error));

        // this.hubConnection.start().catch(error => console.log('Error establishing connection: ', error));


        this.hubConnection.on("JoinedGroup", data => {
            console.log('Joined Group ', data);
        });

        this.hubConnection.on("LeftGroup", data => {
            console.log('Left Group ', data);
        });

        this.hubConnection.on("OrderCreated", data => {
            try {
                console.log('on order created captured');
                const orderid = parseInt(data);
                if (!isNaN(orderid)) {
                    this.loadOrders();
                }
            } catch {
                console.log('not a number ', data);
            }
        });

        const getIntValue = (value: string) => {
            try {
                const intValue = parseInt(value);
                if (!isNaN(intValue)) {
                    return intValue;
                } else {
                    return -1;
                }
            } catch {
                return -1;
            }
        }

        this.hubConnection.on("OrderModified", data => {
            runInAction(() => {
                let splits = data.split(",");
                const orderid = getIntValue(splits[0]);
                const status = splits[1];
                let modifiedOrders = this.orders.filter(x => x.id === orderid);
                if (modifiedOrders !== null && modifiedOrders.length > 0) {
                    modifiedOrders[0].orderStatus = status;
                }
            });
        });

        this.hubConnection.onclose(async () => {
            console.log('on hub connection closed captured');
            /*
            if (this.hubConnection == null) {
                this.hubConnection = new HubConnectionBuilder()
                    .withUrl(process.env.REACT_APP_API_CHAT_URL!, {
                        accessTokenFactory: () => this.rootStore.userStore.user?.token!
                    })
                    .withAutomaticReconnect({
                        nextRetryDelayInMilliseconds: retryContext => {
                            if (retryContext.elapsedMilliseconds < 60000) {
                                // If we've been reconnecting for less than 60 seconds so far,
                                // wait between 0 and 10 seconds before the next reconnect attempt.
                                return Math.random() * 10000;
                            } else {
                                // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                                return null;
                            }
                        }
                    })
                    .configureLogging(LogLevel.Information)
                    .build();
            }
            await this.hubConnection.start()
                .then(() => console.log(this.hubConnection!.state))
                .then(() => {
                    console.log('Re-Attempting to join group on close captured', CreateOrderGroup);
                    this.hubConnection!.invoke('JoinGroup', CreateOrderGroup);

                })
                .catch((error) => console.log('Error Establishing connection', error));
            */
        });
    }


    stopHubConnection = () => {
        this.hubConnection?.stop().catch(error => console.log('Error stopping connection ', error));
    }

    clearOrderChats = () => {
        this.orders = [];
        this.stopHubConnection();
    }

    @observable currentPageNo = 0;
    @observable totalPages = 0;
    @observable pageSize = 0;
    @observable totalRecordCount = 0;



    getAxiosParams(orderNo: string = '', phoneNo: string = '') {
        const params = new URLSearchParams();
        params.append('pageNumber', (this.currentPageNo + 1) + '');
        if (orderNo !== '') {
            console.log('appeding orderID', orderNo)
            params.append('orderId', orderNo)
        }
        if (phoneNo !== '') {
            params.append('phoneNumber', phoneNo)
        }
        return params;
    }

    @observable processingOrder = false;
    @observable currentOrder: IOrder | null = null;

    @observable orders: IOrder[] = [];


    @action acceptOrder = async (orderId: number) => {
        this.processingOrder = true;
        try {
            await agent.kitchenkiosk.acceptOrder(orderId);
            runInAction(() => {
                // let modifiedOrder = this.orders.filter(x => x.id === orderId);
                // if (modifiedOrder !== null) {
                //     modifiedOrder[0].orderStatus = "AcceptedAtKitchen";
                // }
                this.processingOrder = false;
            });
        } catch (error) {
            console.log(error);
            toast.error(error);
            runInAction(() => {
                this.processingOrder = false;
            });
        }
    }
    @action completeOrder = async (orderId: number) => {
        this.processingOrder = true;
        try {
            await agent.kitchenkiosk.completeOrder(orderId);
            runInAction(() => {
                // let modifiedOrder = this.orders.filter(x => x.id === orderId);
                // if (modifiedOrder !== null) {
                //     modifiedOrder[0].orderStatus = "ReadyForDelivery";
                // }
                this.processingOrder = false;
            });
        } catch (error) {
            console.log(error);
            toast.error(error);
            runInAction(() => {
                this.processingOrder = false;
            });
        }
    }

    @action updateOrder = async (orderId: number, timeInMinutes: string) => {
        this.processingOrder = true;
        try {
            await agent.kitchenkiosk.updateTime(orderId, timeInMinutes);
            runInAction(() => {
                this.processingOrder = false;
            });
        } catch (error) {
            console.log(error);
            toast.error(error);
            runInAction(() => {
                this.processingOrder = false;
            });
        }
    }

    @action loadOrders = async () => {
        this.processingOrder = true;
        try {
            const ordersEnvelope = await agent.kitchenkiosk.pagedList(new URLSearchParams());
            const { orders, totalOrderCount, pageSize, currentPage, totalPages } = ordersEnvelope;
            runInAction(() => {
                this.orders = [];
                orders.forEach(order => {
                    this.orders.push(order);
                });


                this.currentPageNo = currentPage;
                this.totalPages = totalPages;
                this.totalRecordCount = totalOrderCount;
                this.pageSize = pageSize;
                this.processingOrder = false;
            });
        } catch (error) {
            console.log(error);
            toast.error(error);
            runInAction(() => {
                this.processingOrder = false;
            });
        }
    }


    @action searchOrder = async (orderNo: string) => {
        this.processingOrder = true;
        try {
            // const orders = await agent.orders.list();
            this.currentPageNo = 0;
            const ordersEnvelope = await agent.kitchenkiosk.getOrderNo(orderNo);
            const { orders, totalOrderCount, pageSize, currentPage, totalPages } = ordersEnvelope;
            runInAction(() => {
                this.orders = [];
                orders.forEach(order => {
                    this.orders.push(order);
                });
                this.currentPageNo = currentPage;
                this.totalPages = totalPages;
                this.totalRecordCount = totalOrderCount;
                this.pageSize = pageSize;
                this.processingOrder = false;
            });
        } catch (error) {
            console.log(error);
            toast.error(error);
            runInAction(() => {
                this.processingOrder = false;
            });
        }
    }

    @action resetOrders = async () => {
        this.processingOrder = true;
        try {
            // const orders = await agent.orders.list();
            this.currentPageNo = 0;

            const ordersEnvelope = await agent.kitchenkiosk.pagedList(this.getAxiosParams());
            const { orders, totalOrderCount, pageSize, currentPage, totalPages } = ordersEnvelope;
            runInAction(() => {
                console.log("in run in action", orders);
                this.orders = [];
                orders.forEach(order => {
                    this.orders.push(order);
                });
                this.currentPageNo = currentPage;
                this.totalPages = totalPages;
                this.totalRecordCount = totalOrderCount;
                this.pageSize = pageSize;
                this.processingOrder = false;
                console.log("in run in action orders now ", orders);
                console.log("in run in action this.orders now ", this.orders);
                return this.orders;
            });
        } catch (error) {
            console.log(error);
            toast.error(error);
            runInAction(() => {
                this.processingOrder = false;
            });
        }
    }



    @action loadNextPage = async () => {
        this.processingOrder = true;
        try {
            const ordersEnvelope = await agent.kitchenkiosk.pagedList(this.getAxiosParams());
            const { orders, totalOrderCount, pageSize, currentPage, totalPages } = ordersEnvelope;
            runInAction(() => {
                orders.forEach(order => {
                    this.orders.push(order);
                });
                this.currentPageNo = currentPage;
                this.totalPages = totalPages;
                this.totalRecordCount = totalOrderCount;
                this.pageSize = pageSize;
                this.processingOrder = false;
            });
        } catch (error) {
            console.log(error);
            toast.error(error);
            runInAction(() => {
                this.processingOrder = false;
            });
        }
    }
}