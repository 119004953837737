import React, { Fragment } from 'react'
import Body from './Body'

const ContactUs = () => {
    return (
        <Fragment>
            <div className="contact_banner">
                <h1>Contact us</h1>
                <h4> ... always by your side!</h4>
            </div>
            <Body />
        </Fragment>
    )
}

export default ContactUs
