import { observer } from 'mobx-react';
import React, { Fragment, useContext, useEffect } from 'react'
import LoadingIndicator from '../../app/common/loaders/LoadingIndicator';
import { RootStoreContext } from '../../app/stores/RootStore';
import Deals from './Deals';
import ProductWithCategory from './ProductWithCategory';


interface IProps {
    categoryId: string;
}

const MenuBody: React.FC<IProps> = ({ categoryId }) => {
    // const history = useHistory();
    const rootContext = useContext(RootStoreContext);
    const { loadingCategory, categoryDetails, getCategoryDetails } = rootContext.productStore;
    //const { addItemWithIdToBasket } = rootContext.basketStore;

    useEffect(() => {
        // let mycategory = categoryId;
        getCategoryDetails(categoryId);
    }, [categoryId, getCategoryDetails])

    // const navigateTo = (navigateurl: string) => {
    //     history.push(navigateurl);
    // }

    // const isNotAPizza = () => {
    //     if (categoryDetails?.name.indexOf('Sides') !== -1)
    //         return true;
    //     return false;
    // }

    if (loadingCategory || categoryDetails === null) {
        return (
            <LoadingIndicator />
        );
    }

    return (
        <Fragment>
            <div className="backgroundImage" />
            <section className="products">


                {categoryDetails.name.indexOf("Beverages") !== -1 ? (
                    <img className="products__banner" src="/images/flatmenu/drinks-ban@2x.jpg" alt="banner" />

                ) : (
                    <img className="products__banner" src="/images/flatmenu/pizza-ban@2x.jpg" alt="banner" />

                )}


                {categoryDetails.name.indexOf("Deals") !== -1 ? (
                    <Deals category={categoryDetails} />

                ) : (
                    <ProductWithCategory category={categoryDetails} />
                )}

            </section>

        </Fragment >

    )
}

export default observer(MenuBody)
