import React, { Fragment } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import MenuBody from './MenuBody';

interface DetailedParams {
    categoryid: string;
}

const SpecificMenu: React.FC<RouteComponentProps<DetailedParams>> = ({ match }) => {

    return (
        <Fragment>
            <div className="about_banner">
                <h1>Our Menu</h1>
                <h4> ... always good &amp; fresh !</h4>
            </div>
            <MenuBody categoryId={match.params.categoryid} />
        </Fragment>


    )
}

export default SpecificMenu
