import { observer } from 'mobx-react';
import React, { Fragment, useContext } from 'react'
import { useHistory } from 'react-router-dom';
import LoadingIndicator from '../../app/common/loaders/LoadingIndicator';
import { RootStoreContext } from '../../app/stores/RootStore';
import "./orderConfirm.scss";

const Body = () => {
    const history = useHistory();
    const rootContext = useContext(RootStoreContext);
    const { processingOrder, currentOrder, resetToLastOrder } = rootContext.orderStore;



    const getTaxes = () => {
        if (currentOrder === undefined || currentOrder === null) {
            resetToLastOrder();
            return 0;
        }


        return RoundNumber(currentOrder.totalBill - currentOrder.subtotal);
    }

    // const getTotal = () => {
    //     if (currentOrder === undefined || currentOrder === null) { return 0; }

    //     return RoundNumber(currentOrder.subtotal * 1.13);
    // }


    const RoundNumber = (num: number) => {
        return Math.round(num * 100 + Number.EPSILON) / 100
    }

    const navigateTo = (navigateurl: string) => {
        history.push(navigateurl);
    }


    if (processingOrder) {
        return (
            <LoadingIndicator />
        );
    }

    return (
        <Fragment>
            <div className="orderconfirm">
                <div className="orderconfirm__backbutton">
                    <button className="bapoo-btn btn-success" onClick={() => navigateTo(`/`)}> &lt;&lt;&nbsp; Back To Home</button>
                </div>
                <div className="orderconfirm__header">
                    We have recieved your order!
                </div>
                <div className="orderconfirm__subheader">
                    Your order number Is [{currentOrder?.id}], please use this number to refer to your status
                </div>

                <div className="orderconfirm__center">
                    <div className="order-grid">
                        <Fragment>
                            <div className="order-grid-row">
                                <div className="order-grid-cell"></div>
                                <div className="order-grid-cell">Bapoo Cuisine</div>
                                <div className="order-grid-cell">Price</div>
                                <div className="order-grid-cell  grid-center">Quantity</div>
                                <div className="order-grid-cell">Total</div>
                            </div>
                            {currentOrder != null && currentOrder.orderItems.map((orderItem, index) => (
                                <div key={index} className="order-grid-row">
                                    <div className="order-grid-cell" >
                                        <div className="grid-product__image">
                                            <img src={orderItem.pictureUrl} alt='Product' />
                                        </div>
                                    </div>
                                    <div className="order-grid-cell" >
                                        {orderItem.productName} ({orderItem.productSize})
                                        {orderItem.crustType.startsWith('Th') &&
                                            (
                                                <div>
                                                    {orderItem.crustType}
                                                </div>
                                            )
                                        }
                                        {orderItem.sauceType !== '' &&
                                            (
                                                <div>
                                                    <b>Sauce:</b> {orderItem.sauceType}
                                                </div>
                                            )
                                        }

                                        {orderItem.cheeses !== '' &&
                                            (
                                                <div>
                                                    <b>Cheese:</b> {orderItem.cheeses}
                                                </div>
                                            )
                                        }

                                        {orderItem.dips !== '' &&
                                            (
                                                <div>
                                                    <b>Dips:</b> {orderItem.dips}
                                                </div>
                                            )
                                        }


                                        {orderItem.meatToppings !== '' &&
                                            (
                                                <div>
                                                    <b>Meat Toppings:</b> {orderItem.meatToppings}
                                                </div>
                                            )
                                        }


                                        {orderItem.vegToppings !== '' &&
                                            (
                                                <div>
                                                    <b>Veg Toppings:</b>  {orderItem.vegToppings}
                                                </div>
                                            )
                                        }
                                        {orderItem.otherToppings !== '' &&
                                            (
                                                <div>
                                                    <b>Other Toppings:</b> {orderItem.otherToppings}
                                                </div>
                                            )
                                        }

                                    </div>
                                    <div className="order-grid-cell grid-center">
                                        <Fragment> $ {orderItem.price}</Fragment>
                                    </div>
                                    <div className="order-grid-cell">
                                        <div>{orderItem.quantity}</div>
                                    </div>
                                    <div className="order-grid-cell"><strong>
                                        <Fragment>  $ {orderItem.quantity * orderItem.price}</Fragment>

                                    </strong></div>
                                </div>
                            ))}

                        </Fragment>
                    </div>


                    <div className="order-totals-grid">

                        <Fragment>
                            <div className="order-totals-grid-row">
                                <div className="order-totals-grid-space">&nbsp;</div>
                                <div className="order-totals-grid-heading">SubTotal : </div>
                                <div className="order-totals-grid-value">$ {currentOrder?.subtotal}</div>
                            </div>
                            <div className="order-totals-grid-row">
                                <div className="order-totals-grid-space">&nbsp;</div>
                                <div className="order-totals-grid-heading">Taxes : </div>
                                <div className="order-totals-grid-value">$ {getTaxes()}</div>
                            </div>
                            <div className="order-totals-grid-row">
                                <div className="order-totals-grid-space">&nbsp;</div>
                                <div className="order-totals-grid-heading"><strong>Total : </strong></div>
                                <div className="order-totals-grid-value"><strong>$ {currentOrder?.totalBill}</strong></div>
                            </div>




                        </Fragment>
                    </div>
                </div>






            </div>


        </Fragment>


    )
}

export default observer(Body)
