import { observer } from 'mobx-react'
import React, { Fragment, useContext } from 'react'
import './components.scss';
import { GoLocation } from 'react-icons/go';
import { BiPhone } from 'react-icons/bi';
import { FcSearch } from 'react-icons/fc';
import { MdClear } from 'react-icons/md';

import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { RootStoreContext } from '../../../app/stores/RootStore';

interface IProps {
    movePrevious: () => void;
    moveNext: () => void;
}

const Delivery: React.FC<IProps> = ({ moveNext, movePrevious }) => {

    const rootContext = useContext(RootStoreContext);
    const { addDeliveryAddress } = rootContext.basketStore;
    const {
        pickupPreference, setPickupPreference,
        address, setAddress,
        selectedAddress, setSelectedAddress,
        miscAddress, setMiscAddress,
        distance, setDistance,
        duration, setDuration,
        displayAddress, setDisplayAddress,
        error, setError

    } = rootContext.deliveryStateStore;

    const resetInputs = () => {
        setSelectedAddress('');
        setAddress('')
        setError('');
        setMiscAddress('');
        setDisplayAddress(false);
        setDistance('');
        setDuration('');
    }

    const handleOnInputsDone = () => {
        setDisplayAddress(true);
    }

    const handleMoveNext = async () => {
        if (!pickupPreference && selectedAddress === '') {
            setError('Please provide the address you want us to deliver your order or select the checkbox below to choose self-pickup option.');
            return;
        }

        let deliveryMethod = pickupPreference ? 0 : 1;
        await addDeliveryAddress(deliveryMethod, selectedAddress, miscAddress, distance, duration)
        moveNext();
    }

    const handleAddressUpdate = (value: string) => {
        setDisplayAddress(false);
        setAddress(value);
        setSelectedAddress(value);
        setError('');
        setDistance('');
        setDuration('');
    }

    const searchOptions = {
        location: new google.maps.LatLng(43.40581, -80.38779),
        radius: 2000,
        types: ['address']
    }

    const handleSelect = async (value: any) => {
        console.log(value);

        setDisplayAddress(true);
        setError('');
        setAddress(value);
        setSelectedAddress(value);
        setAddress('');
        const results = await geocodeByAddress(value);
        const latlng = await getLatLng(results[0]);
        // setCoordinates(latlng);


        const origin1 = { lat: 43.40581, lng: -80.38779 };
        const destinationB = { lat: latlng.lat, lng: latlng.lng };
        var service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
            {
                origins: [origin1],
                destinations: [destinationB],
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
                avoidHighways: false,
                avoidTolls: false,
            }, callback);

        function callback(response: any, status: any) {
            // See Parsing the Results for
            // the basics of a callback function.
            if (status === "OK") {
                try {
                    const travelDistance = response.rows[0].elements[0].distance.text;
                    const time = response.rows[0].elements[0].duration.text;
                    setDistance(travelDistance);
                    setDuration(time);

                } catch {
                    setDistance('NA');
                    setDuration('NA');
                }
            }
        }

    }

    const togglePickUpPreference = () => {
        //setPickupPreference(!pickupPreference);
        setPickupPreference(pickupPreference);
        setError('');
    }
    return (
        <Fragment>
            <div className="panel">
                <div className="errorText">
                    {error}
                </div>
                <div className="delivery">
                    <input className="delivery__chkbox" type="checkbox" checked={pickupPreference} onChange={togglePickUpPreference} />
                    <label className="delivery__chkbox-txt">I will pickup my order.</label>
                    <label className="delivery__chkbox-txt"><i style={{ fontSize: '16px' }}>(Bapoo Delivery Coming Soon!)</i></label>
                </div>
                {pickupPreference ? (
                    <div className='ouraddress'>
                        <label className="ouraddress__heading">Our Location</label>
                        <label className="ouraddress__txt"><GoLocation color="orange" />&nbsp;&nbsp;135 Gateway Park Drive,Kitchener</label>
                        <label className="ouraddress__phone"><BiPhone />&nbsp;&nbsp;519 219 2700</label>
                    </div>
                ) : (
                    <div className="address">

                        <div>
                            <label className="address__searchLabel"><FcSearch size='20' />&nbsp;&nbsp;My Address Search</label>
                            <PlacesAutocomplete value={address} onChange={handleAddressUpdate} onSelect={handleSelect} searchOptions={searchOptions}>
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input {...getInputProps({ placeholder: "Type Address", onBlur: handleOnInputsDone })} style={{ fontSize: '20px', width: '90%' }} />
                                        <div>
                                            {loading ? <div>...loading</div> : null}
                                            {suggestions.map((suggestion) => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#ffb6b9', cursor: 'pointer', border: 'dotted 1px grey', padding: '5px', fontSize: '15px' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer', border: 'dotted 1px grey', padding: '5px', fontSize: '15px' };

                                                return (<div {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                                    key={suggestion.placeId}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>)
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>

                        </div>

                    </div>
                )}

                {displayAddress && selectedAddress !== '' && (
                    <Fragment>
                        <div className='selectedaddress'>
                            <div className="selectedaddress__heading">
                                <label className="selectedaddress__Label">Delivery Address</label>
                                <MdClear size='25' color='red' onClick={resetInputs} />
                            </div>
                            {/*<input className="address__streetTxt" type="text" name='name' placeholder='Street' />*/}
                            <div className="selectedaddress__selectedValue">{selectedAddress}</div>
                            <label className="selectedaddress__otherInfoLabel">Add Apartment / Other Info </label>
                            <input className="selectedaddress__streetTxt" type="text"
                                value={miscAddress}
                                onChange={(e) => setMiscAddress(e.target.value)} placeholder='Apartment etc.' />
                        </div>
                        {/*
                        <div className='debugInfo'>
                            <p>Distance :{distance}</p>
                            <p>Time :{duration} (not considering Mr. Gill's fast driving)</p>
                        </div>
                        */}
                    </Fragment>
                )}
            </div>
            <div className="panelFooter">
                <div>
                    <button className="bapoo-btn btn-disabled" onClick={movePrevious}>Back</button>
                </div>
                <div>
                    <button className="bapoo-btn btn-enabled" onClick={handleMoveNext}>Next</button>
                </div>
            </div>
        </Fragment>
    )
}

export default observer(Delivery)