import React, { useContext, useEffect } from 'react';
import Body from './Body';
import { observer } from 'mobx-react';
import NavBar from '../../app/layout/header/NavBar';
import Footer from '../../app/layout/footer/Footer';
import { RootStoreContext } from '../../app/stores/RootStore';



const HomePage = () => {
    const rootContext = useContext(RootStoreContext);
    const { UnHideCart } = rootContext.sliderStore;
    useEffect(() => {
        UnHideCart();
    }, [UnHideCart])

    return (
        <div className="container">
            <NavBar />
            <div className="home_banner">
                {/*
                <FaPizzaSlice color='white' size={140} />
                <AiOutlineHeart color='white' size={140} />
                <GiChickenLeg color='white' size={140} />
                <GiTomato color='red' size={140} />
                <IoMdBeer color='white' size={140} />
                <GiCakeSlice color='brown' size={140} />
                <GiHighGrass color='white' size={140} />
                <GiPizzaSlice color='red' size={140} />
                */}
            </div>
            <Body />
            <Footer />
        </div >
    )
}

export default observer(HomePage);
