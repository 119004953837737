import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const VegGourmet = () => {
    return (

        <Fragment>
            <div className="menus__heading" id='VegGourmet'>
                <h1>Veg Gourmet</h1>
            </div>
            <div className="menus__subheading">
                <Link to={'/'}> &lt;&lt;&nbsp;Back To Home</Link>
            </div>
            <div className="menu">
                <img src="images/menu/vegGourmet.png" alt="" className="menu__img" />

                <h5 className="menu__name">Pesto Paneer Pizza</h5>
                <span className="menu__subtext">Pesto Sauce,Roasted Red Peppers,House made cottage cheese
                    (paneer),Mushrooms,Feta Cheese and Red Onions</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Small $ 13.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Medium $ 16.75</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Large $ 19.75</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Ex. Large $ 22.25</p>
                </div>

                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menu">
                <img src="images/menu/vegTraditionals.jpg" alt="" className="menu__img" />

                <h5 className="menu__name">Creamy Shahi Paneer</h5>
                <span className="menu__subtext"> Indian Butter Sauce,House made cottage cheese (paneer),Red
                    Onions and Roasted Red Peppers</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Small $ 13.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Medium $ 16.75</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Large $ 19.25</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Ex. Large $ 22.25</p>
                </div>

                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menu">
                <img src="images/menu/vegGourmet.png" alt="" className="menu__img" />

                <h5 className="menu__name">Tandoori Paneer Tikka</h5>
                <span className="menu__subtext">Spicy San Marzano Marinara, Paneer Tikka,Red
                    Onions,Jalapenos and Hot Banana Peppers</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Small $ 13.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Medium $ 16.75</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Large $ 19.75</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Ex. Large $ 22.25</p>
                </div>

                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menu">
                <img src="images/menu/vegTraditionals.jpg" alt="" className="menu__img" />

                <h5 className="menu__name">Bapoo's Mediterranean</h5>
                <span className="menu__subtext">Roasted Zucchini,Roasted Red Peppers, Red Onions,Feta
                    Cheese, Spinach and Black Olives</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Small $ 13.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Medium $ 16.75</p>
                </div>
                <div className="menu__detail_3">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Large $ 19.75</p>
                </div>
                <div className="menu__detail_4">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Ex. Large $ 22.25</p>
                </div>

                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menus__subheading">
                <Link to={'/'}> &lt;&lt;&nbsp;Back To Home</Link>
            </div>

        </Fragment>
    )
}

export default VegGourmet
