
import { observer } from 'mobx-react';
import React, { Fragment, useContext, useState } from 'react'
import { FaRegWindowClose, FaShoppingCart } from 'react-icons/fa';
import { RootStoreContext } from '../../app/stores/RootStore';
import "./cartSlider.scss";
import "./cart.scss";
import Slider from '../slidingpanel/Slider';
import SlidingCheckout from '../checkout/SlidingCheckout';
import CartSlider from './CartSlider';

const Cart = () => {
    //const history = useHistory();
    const rootContext = useContext(RootStoreContext);
    const { basketCount, displayBasket } = rootContext.basketStore;
    const { isCheckOutOrderOpen, openCartSlider, isCartOpen, isCartHidden } = rootContext.sliderStore;

    const [cartSlided, setCartSlided] = useState(false);
    const handleCartSlide = () => {
        openCartSlider();
    }

    const slideOut = () => {
        setCartSlided(false);
    }

    return (
        <Fragment>
            {isCartHidden ? (null) : (
                <Fragment>
                    <div className="cart">
                        <input type="checkbox"
                            className="cart__checkbox" id="cart-toggle"
                            checked={isCartOpen}
                            onClick={handleCartSlide}
                            onChange={handleCartSlide} />

                        {displayBasket && (
                            <label htmlFor="cart-toggle" className="cart__button">
                                {cartSlided ? (
                                    <span className='cart__myicon'><FaRegWindowClose color='orange' size={35} /></span>
                                ) : (
                                    <div>
                                        <span className='cart__myicon'><FaShoppingCart color='orange' size={40} />
                                        </span>
                                        {
                                            basketCount > 0 && (
                                                <div className='cart__cart-no'>{basketCount}</div>
                                            )
                                        }
                                    </div>
                                )}
                            </label>
                        )}
                        <div className="cart__background" onClick={slideOut}>&nbsp;</div>
                    </div >
                    <Slider component={SlidingCheckout} isSliderOpen={isCheckOutOrderOpen} />
                    <Slider component={CartSlider} isSliderOpen={isCartOpen} />

                </Fragment>
            )}
        </Fragment>
    )
}

export default observer(Cart)
