import React, { Fragment, useContext, useState } from 'react'
import { RootStoreContext } from '../../app/stores/RootStore';
import CartContentGrid from './CartContentGrid';
import { VscChromeClose } from 'react-icons/vsc';

const CartSlider = () => {
    const rootContext = useContext(RootStoreContext);
    const { closeSlider, openCheckoutSlider } = rootContext.sliderStore;
    //const { basketCount, createPaymentIntent, displayBasket } = rootContext.basketStore;
    //const [cartSlided, setCartSlided] = useState(false);
    const { createPaymentIntent, } = rootContext.basketStore;
    const [, setCartSlided] = useState(false);

    const checkoutOrderWithSlider = () => {
        setCartSlided(false);
        createPaymentIntent();
        openCheckoutSlider("");
    }
    return (
        <Fragment>
            <div className="backgroundImage" />

            <div className="selectedProduct">
                <div className="selectedProduct__header">
                    <p className="selectedProduct__header-label">Your Basket</p>
                    <VscChromeClose className="selectedProduct__header-btn" size='25' onClick={closeSlider} />
                </div>
                <div className="selectedProduct__subheader">
                    <div>
                        <p className="selectedProduct__selectedProductName"></p>
                    </div>
                </div>
                <div className="selectedProduct__content">
                    <CartContentGrid checkoutOrder={checkoutOrderWithSlider} />
                </div>
                <div className="selectedProduct__footer">

                </div>

            </div>


        </Fragment >
    )
}

export default CartSlider
