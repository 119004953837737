import { RootStore } from "./RootStore";
import { observable, action, makeObservable } from "mobx";

export class ModalStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable.shallow modal = {
        open: false,
        isSizeLarge: false,
        body: null
    }

    @action openModal = (content: any, isSizeLarge: boolean = false) => {
        this.modal.open = true;
        this.modal.isSizeLarge = isSizeLarge;
        this.modal.body = content;
    }


    @action closeModal = () => {
        this.modal.open = false;
        this.modal.body = null;
    }

}