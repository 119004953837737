import { observer } from 'mobx-react';
import React, { Fragment, useContext, useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { CgCloseO } from 'react-icons/cg';
import LoadingComponent from '../../app/common/loaders/LoadingComponent';
import { IPizzaAccessory } from '../../app/models/PizzaOptions';
import { RootStoreContext } from '../../app/stores/RootStore';
import CheeseOptions from './options/CheeseOptions';
import CrustOptions from './options/CrustOptions';
import DipOptions from './options/DipOptions';
import MeatToppings from './options/MeatToppings';
import OtherOptions from './options/OtherOptions';
import SizeOptions from './options/SizeOptions';
import VegToppings from './options/VegToppings';
import { useHistory } from 'react-router-dom';
import SauceOption from './options/SauceOption';
import "./customProduct.scss";

interface IProps {
    productId: string;
}

const Body: React.FC<IProps> = ({ productId }) => {
    const history = useHistory();
    const rootContext = useContext(RootStoreContext);
    const { pizzaOptions } = rootContext.productStore;
    const { addCustomItemToBasket } = rootContext.basketStore;

    const [quantity, setQuantity] = useState('1');



    const [pizzaSize, setPizzaSize] = useState('Large');
    const [pizzaCrust, setPizzaCrust] = useState('Original Crust');
    const [pizzaSauce, setPizzaSauce] = useState('');

    const [pizzaDips, setPizzaDips] = useState<IPizzaAccessory[]>([]);
    const [pizzaSauces,] = useState<IPizzaAccessory[]>([]);
    const [pizzaCheeses, setPizzaCheeses] = useState<IPizzaAccessory[]>([]);
    const [pizzaMeatToppings, setPizzaMeatToppings] = useState<IPizzaAccessory[]>([]);
    const [pizzaVegToppings, setPizzaVegToppings] = useState<IPizzaAccessory[]>([]);
    const [pizzaOtherToppings, setPizzaOtherToppings] = useState<IPizzaAccessory[]>([]);


    const [sizeMinimize, setSizeMinimize] = useState(false);
    const [crustMinimize, setCrustMinimize] = useState(true);
    const [dipMinimize, setDipMinimize] = useState(true);
    const [sauceMinimize, setSauceMinimize] = useState(true);
    const [cheeseMinimize, setCheeseMinimize] = useState(true);
    const [meatToppingMinimize, setMeatToppingMinimize] = useState(true);
    const [vegToppingMinimize, setVegToppingMinimize] = useState(true);
    const [otherToppingMinimize, setOtherToppingMinimize] = useState(true);

    // useEffect(() => {
    //     getPizzaOptions(productId);
    // }, [getPizzaOptions, productId])


    const addCustomOrderToBasket = () => {

        let customOrder = {
            productId: getProductIdFromSize(),
            productName: pizzaOptions?.productName!,
            price: getPriceBySize(),
            quantity: parseInt(quantity),
            size: pizzaSize,
            pictureUrl: pizzaOptions?.pictureUrl!,
            category: pizzaOptions?.category!,
            categoryId: pizzaOptions?.categoryId,
            crustType: pizzaCrust,
            sauceType: pizzaSauce,
            pizzaDips: pizzaDips,
            pizzaSauces: pizzaSauces,
            pizzaCheeses: pizzaCheeses,
            pizzaMeatToppings: pizzaMeatToppings,
            pizzaVegToppings: pizzaVegToppings,
            pizzaOtherToppings: pizzaOtherToppings
        }

        addCustomItemToBasket(customOrder, parseInt(quantity));
        backToPrevious();
    }

    const getProductIdFromSize = () => {
        let product = pizzaOptions?.sizeTypes.filter(x => x.size === pizzaSize)[0];

        if (product !== undefined) {
            return product.id;
        }
        return 0;
    }



    const maximize = (section: string, maximize: boolean) => {
        minimizeAll();
        if (section === "size") {
            setSizeMinimize(!maximize);
        } else if (section === "crust") {
            setCrustMinimize(!maximize);
        } else if (section === "dip") {
            setDipMinimize(!maximize);
        } else if (section === "sauce") {
            setSauceMinimize(!maximize);
        } else if (section === "cheese") {
            setCheeseMinimize(!maximize);
        } else if (section === "meat") {
            setMeatToppingMinimize(!maximize);
        } else if (section === "veg") {
            setVegToppingMinimize(!maximize);
        } else if (section === "other") {
            setOtherToppingMinimize(!maximize);
        }
    }

    const minimizeAll = () => {
        setSizeMinimize(true);
        setCrustMinimize(true);
        setDipMinimize(true);
        setSauceMinimize(true);
        setCheeseMinimize(true);
        setMeatToppingMinimize(true);
        setVegToppingMinimize(true);
        setOtherToppingMinimize(true);
    }


    const scaleToString = (scaleVal: number) => {
        switch (scaleVal) {
            case 0:
                return 'none';
            case 1:
                return 'light';
            case 2:
                return 'medium';
            case 3:
                return 'heavy';
        }
    }


    const incrementQuantity = () => {
        try {
            const intValue = parseInt(quantity);
            setQuantity((intValue + 1) + '');
        } catch {

        }
    }
    const decrementQuantity = () => {
        try {
            let intValue = parseInt(quantity);
            intValue = intValue - 1;
            if (intValue <= 0)
                intValue = 1;
            setQuantity(intValue + '');
        } catch {

        }
    }

    const changeQuantity = (value: string) => {
        try {
            parseInt(value);
            setQuantity(value);
        } catch {
            setQuantity('1');
        }
    }

    const getPriceBySize = () => {
        let sizeType = pizzaOptions?.sizeTypes.filter(x => x.size === pizzaSize)[0];

        if (sizeType !== undefined) {
            return sizeType.price;
        }
        return 0;
    }


    const getTotalPriceForCrustSelection = () => {
        return 0;
    }
    const getTotalPriceForDipsSelection = () => {
        let totalOptionsCost = 0;
        pizzaDips.forEach(pizzaOpt => {
            totalOptionsCost += (pizzaOpt.price * pizzaOpt.quantity);
        });

        return totalOptionsCost;
    }
    const getTotalPriceForSaucesSelection = () => {
        let totalOptionsCost = 0;
        pizzaSauces.forEach(pizzaOpt => {
            totalOptionsCost += (pizzaOpt.price);
        });

        return totalOptionsCost;
    }

    const getTotalPriceForCheesesSelection = () => {
        let totalOptionsCost = 0;
        pizzaCheeses.forEach(pizzaOpt => {
            totalOptionsCost += (pizzaOpt.price);
        });

        return totalOptionsCost;
    }

    const getTotalPriceForMeatToppingsSelection = () => {
        let totalOptionsCost = 0;
        pizzaMeatToppings.forEach(pizzaOpt => {
            totalOptionsCost += (pizzaOpt.price);
        });

        return totalOptionsCost;
    }

    const getTotalPriceForVegToppingsSelection = () => {
        let totalOptionsCost = 0;
        pizzaVegToppings.forEach(pizzaOpt => {
            totalOptionsCost += (pizzaOpt.price);
        });

        return totalOptionsCost;
    }

    const getTotalPriceForOtherToppingsSelection = () => {
        let totalOptionsCost = 0;
        pizzaOtherToppings.forEach(pizzaOpt => {
            totalOptionsCost += (pizzaOpt.price);
        });

        return totalOptionsCost;
    }

    const getTotalPrice = () => {
        let basePrice = getPriceBySize()
            + getTotalPriceForCrustSelection()
            + getTotalPriceForDipsSelection()
            + getTotalPriceForSaucesSelection()
            + getTotalPriceForCheesesSelection()
            + getTotalPriceForMeatToppingsSelection()
            + getTotalPriceForVegToppingsSelection()
            + getTotalPriceForOtherToppingsSelection();



        try {
            basePrice = basePrice * parseInt(quantity);
        } catch {

        }
        return Math.round(basePrice * 100 + Number.EPSILON) / 100;
        //return basePrice;

    }

    const backToPrevious = () => {
        history.push(`/menu/${pizzaOptions?.categoryId}`);
    }

    if (pizzaOptions === null || pizzaOptions.sizeTypes.length <= 0) {
        return <LoadingComponent />
    }

    return (
        <Fragment>
            <div className="backgroundImage" />

            <section className="custProduct">
                <div className="custProduct__close">
                    <div>
                        <CgCloseO onClick={() => backToPrevious()} size='35' />
                    </div>

                </div>
                <div className="custProduct__heading">
                    <div>
                        <h5 className="l-section__heading">{pizzaOptions?.sizeTypes[0].name}</h5>
                    </div>
                </div>
                <div className="custProduct__totals">
                    <div className="custProduct__counter">
                        <FaMinus className="bapoo__quantity_icon" size='20' onClick={() => decrementQuantity()} />
                        <input className="bapoo__quantity_input" type="text" name="quantity" id="quantity" value={quantity} onChange={(e) => changeQuantity(e.target.value)} />
                        <FaPlus className="bapoo__quantity_icon" size='20' onClick={() => incrementQuantity()} />
                    </div>
                    <label className="custProduct__totalamount">$ {getTotalPrice()}</label>
                    <button className="bapoo-btn custProduct__cart" onClick={() => addCustomOrderToBasket()} > Add To Cart</button>

                </div>
                <span className="custProduct__seperator">&nbsp;</span>

                <div className="l-section">


                    <div>
                        <span className="l-section__h4">Size:</span>
                        <span className="l-section__p1">
                            <div>{pizzaSize} [$ {getPriceBySize()}]</div> </span>
                    </div>
                    <div>
                        <span className="l-section__h4">Crust:</span>
                        <span className="l-section__p1"><div>{pizzaCrust}</div></span>
                    </div>
                    <div>
                        <span className="l-section__h4">Sauce:</span>
                        <span className="l-section__p1"><div>{pizzaSauce}</div>
                        </span>
                    </div>
                    <div>
                        <span className="l-section__h4">Dip(s):</span>
                        <span className="l-section__p1">
                            {pizzaDips.length === 0 ? (
                                <div>No dips selected</div>
                            ) : (
                                    pizzaDips.map(pizzaDip => (
                                        <div key={pizzaDip.id}>{pizzaDip.name} [Qty : {pizzaDip.quantity} / Total Price ${pizzaDip.price * pizzaDip.quantity}]</div>
                                    ))
                                )}


                        </span>
                    </div>
                    <div>
                        <span className="l-section__h4">Cheeses:</span>
                        <span className="l-section__p1">
                            {pizzaCheeses.length === 0 ? (
                                <div>No cheeses selected</div>
                            ) : (
                                    pizzaCheeses.map(pizzaOption => (
                                        <div key={pizzaOption.id}>{pizzaOption.description} [{scaleToString(pizzaOption.quantityScale)}] [Price :  ${pizzaOption.price}]</div>
                                    ))
                                )}
                        </span>
                    </div>
                    <div>
                        <span className="l-section__h4">Meats:</span>
                        <span className="l-section__p1">
                            {pizzaMeatToppings.length === 0 ? (
                                <div>No meat toppings selected</div>
                            ) : (
                                    pizzaMeatToppings.map(pizzaOption => (
                                        <div key={pizzaOption.id}>{pizzaOption.description} [{scaleToString(pizzaOption.quantityScale)}] [Price :  ${pizzaOption.price}]</div>
                                    ))
                                )}
                        </span>
                    </div>
                    <div>
                        <span className="l-section__h4">Veggies:</span>
                        <span className="l-section__p1">
                            {pizzaVegToppings.length === 0 ? (
                                <div>No veg toppings selected</div>
                            ) : (
                                    pizzaVegToppings.map(pizzaOption => (
                                        <div key={pizzaOption.id}>{pizzaOption.description} [{scaleToString(pizzaOption.quantityScale)}] [Price :  ${pizzaOption.price}]</div>
                                    ))
                                )}
                        </span>
                    </div>
                    <div>
                        <span className="l-section__h4">Other Toppings:</span>
                        <span className="l-section__p1">
                            {pizzaOtherToppings.length === 0 ? (
                                <div>No toppings selected</div>
                            ) : (
                                    pizzaOtherToppings.map(pizzaOption => (
                                        <div key={pizzaOption.id}>{pizzaOption.description} [{scaleToString(pizzaOption.quantityScale)}] [Price :  ${pizzaOption.price}]</div>
                                    ))
                                )}
                        </span>
                    </div>
                </div>
                <div className="r-section">
                    <div className="r-section__detail">
                        {pizzaOptions != null ? (
                            <Fragment>
                                <SizeOptions sizeTypes={pizzaOptions.sizeTypes} minimize={sizeMinimize} maximize={maximize} pizzaSize={pizzaSize} setPizzaSize={setPizzaSize} />
                                <CrustOptions crustTypes={pizzaOptions.crustTypes} minimize={crustMinimize} maximize={maximize} pizzaCrust={pizzaCrust}
                                    setPizzaCrust={setPizzaCrust} />
                                <SauceOption sauceTypes={pizzaOptions.sauceTypes} minimize={sauceMinimize} maximize={maximize}
                                    sauceOptionSelected={pizzaSauce}
                                    setPizzaSauce={setPizzaSauce} />
                                <DipOptions dipTypes={pizzaOptions.dippingTypes} minimize={dipMinimize} maximize={maximize} pizzaDips={pizzaDips} setPizzaDips={setPizzaDips} />
                                <CheeseOptions cheeseTypes={pizzaOptions.cheeseTypes} minimize={cheeseMinimize} maximize={maximize} pizzaCheeses={pizzaCheeses} setPizzaCheeses={setPizzaCheeses} />
                                <MeatToppings meatToppingTypes={pizzaOptions.meatToppingTypes} minimize={meatToppingMinimize} maximize={maximize} pizzaMeatToppings={pizzaMeatToppings} setPizzaMeatToppings={setPizzaMeatToppings} />
                                <VegToppings vegToppingTypes={pizzaOptions.vegToppingTypes} minimize={vegToppingMinimize} maximize={maximize} pizzaVegToppings={pizzaVegToppings} setPizzaVegToppings={setPizzaVegToppings} />
                                <OtherOptions otherToppingTypes={pizzaOptions.otherToppingTypes} minimize={otherToppingMinimize} maximize={maximize} pizzaOtherToppings={pizzaOtherToppings} setPizzaOtherToppings={setPizzaOtherToppings} />
                            </Fragment>

                        ) : (
                                <LoadingComponent />
                            )}
                    </div>

                </div>
            </section>


        </Fragment >
    )
}

export default observer(Body)
