import React, { useContext, useEffect, useState } from 'react'
import PrivateNavBar from '../../app/layout/header/PrivateNavBar'
import { RootStoreContext } from '../../app/stores/RootStore';
import "./orders.scss";
import OrdersList from './OrdersList';
import { observer } from 'mobx-react';
import ReactHowler from 'react-howler';
import { AiTwotoneSound } from 'react-icons/ai';
import { BsFillVolumeMuteFill } from 'react-icons/bs';

const KitchenKiosk = () => {
    const rootContext = useContext(RootStoreContext);
    const { HideCart, UnHideCart } = rootContext.sliderStore;
    const { searchOrder, resetOrders, createHubConnection, clearOrderChats, loadOrders, acceptOrder } = rootContext.kioskStore;

    const [orderNo, setOrderNo] = useState('');
    const [playSound, setPlaySound] = useState(false);
    const [enableSound, setEnableSound] = useState(false);


    useEffect(() => {
        HideCart();
        createHubConnection();
        //loadOrders();
        //loadOrders().then(() => setEnableSound(true));

        const timeInMinutes = 2;
        const oneMinute = 60000;
        setInterval(() => createHubConnection(), oneMinute * timeInMinutes);



        return () => {
            clearOrderChats();
            UnHideCart();
        }
    }, [HideCart, UnHideCart, createHubConnection, clearOrderChats, loadOrders])


    let player: any = null;


    const mystop = () => {
        player.stop();
        setPlaySound(false);
    }

    const acceptAndStop = (id: number) => {
        acceptOrder(id).then(() => {
            mystop();
        });
    }


    return (
        <div>
            <ReactHowler
                html5={true}
                src='/sounds/kalimba.mp3'
                playing={enableSound && playSound}
                ref={(ref) => (player = ref)}

            />

            <PrivateNavBar />


            <div className="ordersPage">
                <div className="soundButtons">
                    {!enableSound && (
                        <BsFillVolumeMuteFill size='40' onClick={() => { setEnableSound(true); }} color='red' />
                    )}
                    {enableSound && (
                        <AiTwotoneSound onClick={() => { setEnableSound(false); }} size='35' color='green' />
                    )}

                </div>
                <div className="firstRow">
                    <label>Order Search ...</label>
                    <input type='text' value={orderNo} onChange={(e) => setOrderNo(e.target.value)} />
                </div>
                <div className="firstRowButtons">
                    <button className="bapoo-btn btn-success"
                        onClick={() => searchOrder(orderNo)}
                    >Search Order</button>
                    <button className="bapoo-btn btn-enabled" onClick={() => { setOrderNo(''); resetOrders(); }}
                    >Clear Search</button>
                </div>
                <OrdersList
                    playSound={playSound}
                    setPlaySound={setPlaySound}
                    setEnableSound={setEnableSound}
                    acceptAndStop={acceptAndStop}
                    stop={mystop}

                />

            </div>
        </div >
    )
}

export default observer(KitchenKiosk)
