
import React from 'react'
import { IBasketItem } from '../../../app/models/Basket'

interface IProps {
    basketItem: IBasketItem;
}

const CrustTypeInCart: React.FC<IProps> = ({ basketItem }) => {
    const getCrustType = (crustType: string) => {
        if (crustType !== null && crustType !== '')
            return crustType;
        return 'Regular';

    }
    return (
        <div><b>Crust :</b> {getCrustType(basketItem.crustType)}</div>
    )
}

export default CrustTypeInCart