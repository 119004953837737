import { action, makeObservable, observable } from "mobx";
import { RootStore } from "./RootStore";

export default class DeliveryStateStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this)
        this.rootStore = rootStore;
    }


    @observable pickupPreference = true;
    @observable address = '';
    @observable selectedAddress = '';
    @observable miscAddress = '';
    @observable distance = '';
    @observable duration = '';
    @observable displayAddress = false;
    @observable error = '';

    @action setPickupPreference = (preference: boolean) => {
        this.pickupPreference = preference;
    }

    @action setAddress = (myvalue: string) => {
        this.address = myvalue;
    }
    @action setSelectedAddress = (myvalue: string) => {
        this.selectedAddress = myvalue;
    }

    @action setMiscAddress = (myvalue: string) => {
        this.miscAddress = myvalue;
    }
    @action setDistance = (myvalue: string) => {
        this.distance = myvalue;
    }
    @action setDuration = (myvalue: string) => {
        this.duration = myvalue;
    }

    @action setDisplayAddress = (preference: boolean) => {
        this.displayAddress = preference;
    }

    @action setError = (myvalue: string) => {
        this.error = myvalue;
    }




}