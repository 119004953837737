import React, { Fragment } from 'react'
import MeatGourmet from '../MeatGourmet'

const MeatGourmetSpecific = () => {
    return (
        <Fragment>
            <div className="menu_banner">
                <h1>Our Menu</h1>
                <h4> ... always good &amp; fresh !</h4>
            </div>
            <section className="menus">
                <MeatGourmet />
            </section>
        </Fragment>
    )
}

export default MeatGourmetSpecific
