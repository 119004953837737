import { action, makeObservable, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { IOrder, IOrderRequest } from "../models/Order";
import { RootStore } from "./RootStore";
import { history } from '../..';

export default class OrderStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this)
        this.rootStore = rootStore;
    }

    @observable processingOrder = false;
    @observable currentOrder: IOrder | null = null;



    @action resetToLastOrder = async () => {
        this.processingOrder = true;
        try {
            const orderid = this.tryGetLastOrder();
            if (orderid === null) {
                this.currentOrder = null;
                return;
            }
            const selectedOrder = await agent.orders.getOrderNo(orderid);
            runInAction(() => {
                this.currentOrder = selectedOrder;
                this.processingOrder = false;
            });
        } catch (error) {
            console.log(error);
            toast.error(error);
            runInAction(() => {
                this.processingOrder = false;
            });
        }
    }

    @action createOrderAndChargeCard = async (buyerEmail: string, buyerPhone: string, stripe: any, stripeElement: any, bilingDetails: any) => {
        this.processingOrder = true;
        try {

            const orderRequest = this.createOrderRequest(buyerEmail, buyerPhone);
            const createdOrder = await agent.orders.create(orderRequest);

            const paymentMethod = await this.createStripePaymentMethod(stripe, stripeElement, bilingDetails);

            const clientSecret = this.rootStore.basketStore.customerBasket?.clientSecret;

            const confirmCardPayment = await this.makeStripePayment(stripe, clientSecret!, paymentMethod);

            /*
                        const paymentMethodReq = await stripe!.createPaymentMethod({
                            type: 'card',
                            card: stripeElement,
                            billing_details: bilingDetails
                        });
            
                        const confirmCardPayment = await stripe.confirmCardPayment(clientSecret, {
                            payment_method: paymentMethodReq.paymentMethod.id
                        });
            */
            if (confirmCardPayment.paymentIntent) {
                await this.rootStore.basketStore.deleteBasket();
                runInAction(() => {
                    this.currentOrder = createdOrder;
                    this.rememberLastOrderAs(createdOrder.id);
                    this.processingOrder = false;
                    //toast.success("Your order was successfully submitted!");

                    history.push('/orderconfirmation');
                    this.rootStore.sliderStore.closeSlider();

                });
            } else {
                toast.error(confirmCardPayment.error.message);
            }
            runInAction(() => {
                this.processingOrder = false;
            });

        } catch (error) {
            //console.log(error);
            toast.error(error);
            runInAction(() => {
                this.processingOrder = false;
            });
        }
    }

    private createStripePaymentMethod = async (stripe: any, stripeElement: any, bilingDetails: any) => {

        const paymentMethodReq = await stripe!.createPaymentMethod({
            type: 'card',
            card: stripeElement,
            billing_details: bilingDetails
        });
        return paymentMethodReq;
    }
    private makeStripePayment = async (stripe: any, clientSecret: string, paymentMethod: any) => {

        const confirmCardPayment = await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethod.paymentMethod.id
        });
        return confirmCardPayment;
    }


    @action createOrder = async (buyerEmail: string, buyerPhone: string) => {
        this.processingOrder = true;
        try {
            const orderRequest = this.createOrderRequest(buyerEmail, buyerPhone, true);
            const createdOrder = await agent.orders.create(orderRequest);
            await this.rootStore.basketStore.deleteBasket();
            runInAction(() => {
                this.currentOrder = createdOrder;
                this.rememberLastOrderAs(createdOrder.id);
                this.processingOrder = false;
                //            toast.success("Your order was successfully submitted!")
                history.push('/orderconfirmation');
                this.rootStore.sliderStore.closeSlider();

            });
        } catch (error) {
            toast.error(error.data.message);
            runInAction(() => {
                this.processingOrder = false;
            });
            console.log(error.data.message);
        }
    }

    private createOrderRequest(buyerEmail: string, buyerPhone: string, payLater: boolean = false): IOrderRequest {
        let basketid = this.rootStore.basketStore.customerBasket?.id;
        if (basketid === undefined) {
            basketid = "notfound";
        }
        return {
            buyerEmail,
            buyerCellphone: buyerPhone,
            deliveryMethodId: 0,
            basketId: basketid,
            payLater
        }
    }


    private rememberLastOrderAs = (id: number) => {
        localStorage.setItem('lastplacedorder', id + '');
    }

    private tryGetLastOrder = () => {
        let lastorderplaced = localStorage.getItem('lastplacedorder');
        if (lastorderplaced !== null)
            return lastorderplaced;
        return null;
    }
}