import { observer } from 'mobx-react';
import React, { useContext } from 'react'
import { RootStoreContext } from '../../app/stores/RootStore';
import "./slider.scss";


interface Props {
    component: React.ComponentType<any>;
    isSliderOpen: boolean;
}

const Slider = ({ component: Component, isSliderOpen }: Props) => {
    const rootContext = useContext(RootStoreContext);
    const { closeSlider } = rootContext.sliderStore;

    return (
        <div className="slider">
            <input type="checkbox"
                className="slider__checkbox" id="slider-toggle"
                checked={isSliderOpen}
                onClick={closeSlider}
                onChange={closeSlider} />
            <div className="slider__background" onClick={closeSlider}>&nbsp;</div>
            <div className="slider__panel-wrap">
                <div className="slider__panel">
                    <div className="slider__page">
                        <Component />
                    </div>

                </div>
            </div>
        </div >
    )
}

export default observer(Slider)
