import { observer } from 'mobx-react';
import React, { Fragment, useContext } from 'react'
import { RootStoreContext } from '../../../app/stores/RootStore';


interface IProps {
    movePrevious: () => void;
    moveNext: () => void;
}

const Review: React.FC<IProps> = ({ moveNext, movePrevious }) => {
    const rootContext = useContext(RootStoreContext);
    //const { customerBasket, SubTotal, Taxes, Total, ShippingCost, flatRate, freeDistance, ratePerKms, discountRateForDollars } = rootContext.basketStore;
    const { customerBasket, SubTotal, Taxes, Total, ShippingCost } = rootContext.basketStore;

    return (
        <Fragment>
            <div className="panel">
                <div className="grid-table">
                    <Fragment>
                        <div className="grid-table-row">
                            <div className="grid-table-cell grid-table-cell__desc grid-table-cell__heading">Bapoo Cuisine</div>
                            <div className="grid-table-cell grid-table-cell__quantity grid-table-cell__heading">Qty</div>
                            <div className="grid-table-cell grid-table-cell__total grid-table-cell__heading">Total</div>
                        </div>
                        <div className="grid-table-row">
                            <div className="grid-table-cell-filler"></div>
                        </div>
                        {customerBasket !== null && customerBasket.items.map((basketItem, index) => (
                            <Fragment key={index}>

                                <div key={basketItem.id} className="grid-table-row">
                                    <div className="grid-table-cell grid-table-cell__desc" >
                                        {basketItem.productName} ({basketItem.size})
                                                </div>
                                    <div className="grid-table-cell grid-table-cell__quantity">
                                        <div className="flexcontainer">
                                            <div style={{ fontSize: '1.4rem' }}>{basketItem.quantity}</div>
                                        </div>
                                    </div>
                                    <div className="grid-table-cell grid-table-cell__total">
                                        {basketItem.quantity * basketItem.price}
                                    </div>
                                </div>
                                <div className="grid-table-row">
                                    <div className="grid-table-cell-filler"></div>
                                </div>
                            </Fragment>

                        ))}

                    </Fragment>

                    <div>
                        <div className="grid-totals-table">
                            <div className="grid-totals-table-subtotal-heading" style={{ fontSize: '18px' }}>SubTotal : </div>
                            <div className="grid-totals-table-subtotal" style={{ fontSize: '18px' }}>$ {SubTotal}</div>
                            <div className="grid-totals-table-subtotal-heading" style={{ fontSize: '18px' }}>Shipping : </div>
                            <div className="grid-totals-table-subtotal" style={{ fontSize: '18px' }}>$ {ShippingCost}</div>
                            <div className="grid-totals-table-taxes-heading" style={{ fontSize: '18px' }}>Taxes :</div>
                            <div className="grid-totals-table-taxes" style={{ fontSize: '18px' }}>$ {Taxes}</div>
                            <div className="grid-totals-table-total-heading" style={{ fontSize: '18px' }}>Total :</div>
                            <div className="grid-totals-table-total" style={{ fontSize: '18px' }}>$ {Total}</div>
                        </div>
                        {/*
                            <div className="grid-totals-table">

                                <div className="grid-totals-table-subtotal-heading" style={{ fontSize: '12px' }}>Distance : </div>
                                <div className="grid-totals-table-subtotal" style={{ fontSize: '12px' }}>$ {customerBasket?.distance}</div>
                                <div className="grid-totals-table-subtotal-heading" style={{ fontSize: '12px' }}>Driving Time : </div>
                                <div className="grid-totals-table-subtotal" style={{ fontSize: '12px' }}>$ {customerBasket?.duration}</div>

                                <div className="grid-totals-table-subtotal-heading" style={{ fontSize: '12px' }}>Shipping = flatRate + (chargableDistance * this.ratePerKms) - (dollarsEligibleForDiscount * discountRateForDollars)</div>
                                <div className="grid-totals-table-subtotal" style={{ fontSize: '12px' }}></div>

                                <div className="grid-totals-table-subtotal-heading" style={{ fontSize: '12px' }}>
                                    Constants: Flat Rate $[{flatRate}] , Free Distance [{freeDistance}]kms,  Rate Per Kms  $[{ratePerKms}/km], Discount Rate for Dollars over 20 [${discountRateForDollars}/dollar]   </div>
                                <div className="grid-totals-table-subtotal" style={{ fontSize: '12px' }}></div>


                            </div>
                        */}
                    </div>

                </div>
                <div className="panelFooter">
                    <div>
                        <button className="bapoo-btn btn-enabled" onClick={movePrevious}>Back</button>
                    </div>
                    <div>
                        <button className="bapoo-btn btn-enabled" onClick={moveNext}>Next</button>
                    </div>
                </div>
                <br />
            </div>
        </Fragment>

    )
}

export default observer(Review)
