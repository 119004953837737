import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import ContactUs from '../../features/contact/ContactUs';
import HomePage from '../../features/home/HomePage';
import QuickMenu from '../../features/quickmenu/QuickMenu';
import Menu from '../../features/menu/Menu';

import "../../scss/main.scss";
import Footer from './footer/Footer';
import MeatGourmetSpecific from '../../features/quickmenu/specific/MeatGourmetSpecific';
import MeatTraditionalSpecific from '../../features/quickmenu/specific/MeatTraditionalSpecific';
import VegGourmetSpecific from '../../features/quickmenu/specific/VegGourmetSpecific';
import VegTraditionalSpecific from '../../features/quickmenu/specific/VegTraditionalSpecific';
import SidesSpecific from '../../features/quickmenu/specific/SidesSpecific';
import CustomizeSpecific from '../../features/quickmenu/specific/CustomizeSpecific';
import Cart from '../../features/cart/Cart';
import { ToastContainer } from 'react-toastify';
import SpecificMenu from '../../features/categorymenu/SpecificMenu';
import Checkout from '../../features/checkout/Checkout';
import OrderConfirmation from '../../features/orderconfirmation/OrderConfirmation';
import CustomProduct from '../../features/customproduct/CustomProduct';
import PrivateRoute from './PrivateRoute';
import NonPrivateRoute from './NonPrivateRoute';
import KitchenKiosk from '../../features/kiosks/KitchenKiosk';
import Login from '../../features/users/Login';

function App() {
  return (
    <Fragment>
      <ToastContainer position='bottom-right' autoClose={3000} style={{ fontSize: '1.6em' }} />
      <Cart />
      {/*
      <div className="container">
        <NavBar />
        <Route exact path="/" component={HomePage} />
        <Route exact path="/quickmenu" component={QuickMenu} />
        <Route exact path="/meatTraditionals" component={MeatTraditionalSpecific} />
        <Route exact path="/meatGourmet" component={MeatGourmetSpecific} />
        <Route exact path="/vegTraditionals" component={VegTraditionalSpecific} />
        <Route exact path="/vegGourmet" component={VegGourmetSpecific} />
        <Route exact path="/sides" component={SidesSpecific} />
        <Route exact path="/customize" component={CustomizeSpecific} />
        <Route exact path="/menu" component={Menu} />
        <Route exact path="/menu/:categoryid" component={SpecificMenu} />
        <Route exact path="/mychoice/:productId" component={CustomProduct} />
        <Route exact path="/checkout" component={Checkout} />
        <Route exact path="/orderconfirmation" component={OrderConfirmation} />
        <Route exact path="/contactus" component={ContactUs} />
        <Footer />
      </div >
      */}

      <Route exact path='/' component={HomePage} />
      <Route
        path={'/(.+)'}
        render={() => (
          <Fragment>
            <Switch>
              <NonPrivateRoute exact path="/quickmenu" component={QuickMenu} />
              <NonPrivateRoute exact path="/meatTraditionals" component={MeatTraditionalSpecific} />
              <NonPrivateRoute exact path="/meatGourmet" component={MeatGourmetSpecific} />
              <NonPrivateRoute exact path="/vegTraditionals" component={VegTraditionalSpecific} />
              <NonPrivateRoute exact path="/vegGourmet" component={VegGourmetSpecific} />
              <NonPrivateRoute exact path="/sides" component={SidesSpecific} />
              <NonPrivateRoute exact path="/customize" component={CustomizeSpecific} />
              <NonPrivateRoute exact path="/menu" component={Menu} />
              <NonPrivateRoute exact path="/menu/:categoryid" component={SpecificMenu} />
              <NonPrivateRoute exact path="/mychoice/:productId" component={CustomProduct} />
              <NonPrivateRoute exact path="/checkout" component={Checkout} />
              <NonPrivateRoute exact path="/orderconfirmation" component={OrderConfirmation} />
              <NonPrivateRoute exact path="/contactus" component={ContactUs} />
              <PrivateRoute exact path="/orders" component={KitchenKiosk} />
              <NonPrivateRoute exact path="/login" component={Login} />
              <Footer />
            </Switch>
          </Fragment>
        )}
      />
    </Fragment>
  );
}

export default App;
