import { observer } from 'mobx-react'
import React, { Fragment, useContext } from 'react'
import { ICategoryCompact } from '../../app/models/Category'
import { FiPlus } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/RootStore';
import "./categoryMenu.scss";
import CustomizeOrder from '../productoptions/CustomizeOrder';
import Slider from '../slidingpanel/Slider';

interface IProps {
    category: ICategoryCompact;
}

const ProductWithCategory: React.FC<IProps> = ({ category }) => {
    const history = useHistory();
    const rootContext = useContext(RootStoreContext);

    const { openCustomizeOrderSlider, isCustomizeOrderOpen } = rootContext.sliderStore;



    const navigateTo = (navigateurl: string) => {
        history.push(navigateurl);
    }


    const getPricesString = (prices: string[]) => {
        return prices.join(" / ");
    }


    return (
        <Fragment>
            <div className="products__subheading">
                <button className="bapoo-btn btn-success" onClick={() => navigateTo(`/`)}> &lt;&lt;&nbsp; Back To Home</button>
            </div>

            <div className="products__heading">
                <h4>{category.name}</h4>
            </div>
            <div className="products__container">
                {category.productList.map((product) => (
                    <div key={product.id} className="products__item-container" onClick={() => openCustomizeOrderSlider(product.id + "")}>
                        <div className="products__item-image">
                            <img src={product.pictureUrl} alt="" />
                        </div>
                        <div className="products__item-title">{product.name}</div>
                        <div className="products__item-desc">{product.description}</div>
                        <div className="products__item-prices">$ {getPricesString(product.productPrices)}</div>
                        <div className="products__item-buttons">
                            <button className="bapoo-btn btn-enabled" onClick={() => openCustomizeOrderSlider(product.id + "")}><FiPlus />&nbsp;&nbsp;Add To Order</button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="menus__subheading">
                <button className="bapoo-btn btn-success" onClick={() => navigateTo(`/`)}> &lt;&lt;&nbsp; Back To Home</button>
            </div>

            <Slider component={CustomizeOrder} isSliderOpen={isCustomizeOrderOpen} />
            {/*<CustomizeOrderSlider />*/}
        </Fragment>
    )
}

export default observer(ProductWithCategory)
