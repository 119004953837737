import React, { Fragment } from 'react'
import { IBasketItem } from '../../../app/models/Basket'

interface IProps {
    basketItem: IBasketItem;
}
const DippingsInCart: React.FC<IProps> = ({ basketItem }) => {

    if (basketItem.dipList === undefined || basketItem.dipList?.length <= 0) {
        return <Fragment></Fragment>
    }


    return (
        <div>
            <p><b>Dip(s):</b></p>
            {basketItem.dipList !== undefined && basketItem.dipList?.length > 0 && (
                basketItem.dipList?.map((item, index) => (
                    <p key={index}>{item.dippingType.name}({item.quantity})</p>
                ))
            )}
        </div>

    )
}

export default DippingsInCart
