import React, { Fragment } from 'react'
import { IBasketItem } from '../../../app/models/Basket'

interface IProps {
    basketItem: IBasketItem;
}
const MeatToppingsInCart: React.FC<IProps> = ({ basketItem }) => {
    if (basketItem.meatToppingList === undefined || basketItem.meatToppingList?.length <= 0) {
        return <Fragment></Fragment>
    }
    return (
        <div>
            <p><b>Meat Topping(s):</b></p>
            {basketItem.meatToppingList !== undefined && basketItem.meatToppingList?.length > 0 && (
                basketItem.meatToppingList?.map((item, index) => (
                    <p key={index}>{item.meatToppingType.name}</p>
                ))
            )}
        </div>

    )
}
export default MeatToppingsInCart
