
import { action, makeObservable, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { ICategoryCompact, IProduct, ProductOption, ProductSize } from "../models/Category";
import { IPizzaOptions } from "../models/PizzaOptions";
import { RootStore } from "./RootStore";

export default class ProductStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable loadingCategories = false;
    @observable categories: ICategoryCompact[] | null = null;
    @observable loadingCategory = false;
    @observable categoryDetails: ICategoryCompact | null = null;
    @observable loadingOptions = false;
    @observable pizzaOptions: IPizzaOptions | null = null;
    @observable product: IProduct | null = null;

    public reset = () => {
        if (this.product === null || this.product.productSizes === undefined) return;
        let index = 0;
        if (this.product.productSizes !== undefined && this.product.productSizes.length > 2) {
            index = this.product.productSizes.length - 2;
        }
        this.product.selectedSize = this.product.productSizes[index];
        this.product.selectedOptions = [];
        this.computeTotal();
    }


    @action addSelectedOption = (optionToAdd: ProductOption) => {

        if (this.product !== null) {
            if (this.product.selectedOptions === null || this.product.selectedOptions === undefined) {
                this.product.selectedOptions = [];
                this.product.selectedOptions.push(optionToAdd);
            } else {
                this.product.selectedOptions.push(optionToAdd);
            }
            this.computeTotal();
        }
    }
    @action removeSelectedOption = (optionToRemove: ProductOption) => {
        if (this.product !== null) {
            if (this.product.selectedOptions !== null && this.product.selectedOptions !== undefined) {
                this.product.selectedOptions = this.product.selectedOptions.filter(x => x.name !== optionToRemove.name);
            }
            this.computeTotal();
        }
    }

    @action changeSize = (sizeSelected: ProductSize) => {
        if (this.product !== null) {
            this.product.selectedSize = sizeSelected;
        }
        this.computeTotal();
    }

    private computeTotal = () => {
        if (this.product !== undefined && this.product?.selectedSize !== undefined) {
            this.product.totalPrice = this.product.selectedSize.price;

            if (this.product.selectedOptions !== undefined) {

                this.product.selectedOptions.forEach(option => {
                    if (this.product !== undefined && this.product?.totalPrice !== undefined) {
                        this.product!.totalPrice += option.price;
                    }
                });
            }
            this.product.totalPrice = Math.round((this.product.totalPrice + Number.EPSILON) * 100) / 100
        }
    }


    private initializeSize() {
        if (this.product === null) return;
        let index = 0;
        if (this.product.productSizes !== undefined && this.product.productSizes.length > 2) {
            index = this.product.productSizes.length - 2;
        }
        if (this.product.productSizes !== undefined) {
            this.product.selectedSize = this.product.productSizes[index];
            if (this.product.selectedSize !== undefined) {
                this.rootStore.customStateManager.selectedSize = this.product.selectedSize.name;
            }
            this.computeTotal();
        }
    }

    private initializeCrust() {
        if (this.product === null || this.product === undefined) return;
        const crustOptions = this.product.preConfiguredOptions.filter(x => x.name === "Crust");
        if (crustOptions !== null && crustOptions !== undefined && crustOptions.length > 0) {
            this.rootStore.customStateManager.selectedCrust = crustOptions[0].options[0].name;
        }
    }

    private initializeCheese() {
        if (this.product === null || this.product === undefined) return;
        const crustOptions = this.product.preConfiguredOptions.filter(x => x.name === "Cheese");
        if (crustOptions !== null && crustOptions !== undefined && crustOptions.length > 0) {
            this.rootStore.customStateManager.selectedCheese = crustOptions[0].options[0].name;
        }
    }

    private initializeSauce() {
        if (this.product === null || this.product === undefined) return;
        const crustOptions = this.product.preConfiguredOptions.filter(x => x.name === "Sauce");
        if (crustOptions !== null && crustOptions !== undefined && crustOptions.length > 0) {
            this.rootStore.customStateManager.selectedSauce = crustOptions[0].options[0].name;
        }
    }

    @action loadProduct = async (productId: string) => {
        if (productId === "") {
            return;
        }

        this.loadingOptions = true;
        try {
            this.rootStore.customStateManager.reset();
            const myproduct = await agent.products.getProduct(productId);
            runInAction(() => {
                this.product = myproduct;
                this.initializeSize();
                this.initializeCrust();
                this.initializeCheese();
                this.initializeSauce();
                this.loadingOptions = false;
            });

        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loadingOptions = false;
            });
        }
    }


    @action getPizzaOptionsDeprecated = async (productId: string) => {
        // if (this.pizzaOptions !== null && this.pizzaOptions.productId === productId) {
        //     return this.pizzaOptions;
        // }
        this.loadingOptions = true;
        try {
            const myoptions = await agent.products.pizzaOptions(productId);
            runInAction(() => {
                this.pizzaOptions = myoptions;
                this.loadingOptions = false;
            });

        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loadingOptions = false;
            });
        }
    }

    @action getCategories = async () => {
        if (this.categories !== null && this.categories.length > 0) {
            return this.categories;
        }
        this.loadingCategories = true;
        try {
            const mycategories = await agent.products.categories();
            runInAction(() => {
                this.categories = mycategories;
                this.loadingCategories = false;
            });

        } catch (error) {
            console.log(error);
            toast.error(error);
            runInAction(() => {
                this.loadingCategories = false;
            });
        }
    }

    @action getCategoryDetails = async (id: string) => {
        this.loadingCategory = true;
        try {
            const mycategory = await agent.products.categoryDetails(id);
            runInAction(() => {
                this.categoryDetails = mycategory;
                this.loadingCategory = false;
            });

        } catch (error) {
            console.log(error);
            toast.error(error);
            runInAction(() => {
                this.loadingCategory = false;
            });
        }
    }


    @observable loadingMenu = false;

    @observable category1: ICategoryCompact | null = null;

    @observable category2: ICategoryCompact | null = null;

    @observable category3: ICategoryCompact | null = null;

    @observable category4: ICategoryCompact | null = null;

    @observable category5: ICategoryCompact | null = null;

    @observable category6: ICategoryCompact | null = null;


    @action loadMenu = async () => {

        if (this.category1 !== null &&
            this.category2 !== null &&
            this.category3 !== null &&
            this.category4 !== null &&
            this.category5 !== null &&
            this.category6 !== null) {
            return;
        }
        this.loadingMenu = true;
        try {
            let mycategory1 = await agent.products.categoryDetails(1 + '');
            let mycategory2 = await agent.products.categoryDetails(2 + '');
            let mycategory3 = await agent.products.categoryDetails(3 + '');
            let mycategory4 = await agent.products.categoryDetails(4 + '');
            let mycategory5 = await agent.products.categoryDetails(5 + '');
            let mycategory6 = await agent.products.categoryDetails(6 + '');
            runInAction(() => {
                this.category1 = mycategory1;
                this.category2 = mycategory2;
                this.category3 = mycategory3;
                this.category4 = mycategory4;
                this.category5 = mycategory5;
                this.category6 = mycategory6;
                this.loadingMenu = false;
            });

        } catch (error) {
            runInAction(() => {
                this.loadingMenu = false;
            });
        }
    }

}