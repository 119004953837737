import React, { Fragment } from 'react'
import { FaMinus, FaMinusCircle, FaPlus, FaPlusCircle } from 'react-icons/fa'
import { GiSpill } from 'react-icons/gi'
import { IDippingType, IPizzaAccessory } from '../../../app/models/PizzaOptions';


interface IProps {
    dipTypes: IDippingType[];
    minimize: boolean;
    maximize: (section: string, minimize: boolean) => void;
    pizzaDips: IPizzaAccessory[];
    setPizzaDips: (dips: IPizzaAccessory[]) => void;

}
const DipOptions: React.FC<IProps> = ({ dipTypes, minimize, maximize, pizzaDips, setPizzaDips }) => {


    const getDipGivenId = (id: number) => {
        const pizzaDip = pizzaDips.filter(x => x.id === id)[0];
        return pizzaDip;
    }
    const getDipFromDbGivenId = (id: number) => {
        const pizzaDip = dipTypes.filter(x => x.id === id)[0];
        return pizzaDip;
    }

    const getQuantity = (id: number) => {
        const pizzaDip = getDipGivenId(id);
        if (pizzaDip === null || pizzaDip === undefined)
            return 0;
        return pizzaDip.quantity;
    }

    const incrementQuantity = (id: number, qty: number = 1) => {
        try {
            let pizzaDip = getDipGivenId(id);

            if (pizzaDip === undefined) {
                pizzaDip = { ...getDipFromDbGivenId(id), quantity: qty, quantityScale: 0 };
                pizzaDips.push(pizzaDip);
            } else {
                pizzaDip.quantity += 1;
            }
            setPizzaDips([...pizzaDips]);

        } catch (error) {
            console.log(error);
        }
    }
    const decrementQuantity = (id: number) => {
        try {
            let pizzaDip = getDipGivenId(id);

            if (pizzaDip !== undefined) {
                pizzaDip.quantity -= 1;
            }

            if (pizzaDip.quantity === 0) {
                pizzaDips = pizzaDips.filter(x => x.id !== id);
            }
            setPizzaDips([...pizzaDips]);

        } catch {

        }
    }

    const changeQuantity = (id: number, value: string) => {
        try {
            const myqty = parseInt(value);
            incrementQuantity(id, myqty);

        } catch {

        }
    }
    return (
        <Fragment>
            {minimize ? (
                <Fragment>
                    <div className="r-section__h4" onClick={() => maximize('dip', true)}>
                        <span><GiSpill size='35' /></span>
                        <span>Dip(s):</span>
                        <span><FaPlusCircle /></span>
                    </div>
                </Fragment>
            ) : (
                    <Fragment>
                        <div className="r-section__h4  r-section__h4__expanded" onClick={() => maximize('dip', false)}>
                            <span><GiSpill size='35' /></span>
                            <span>Dip(s):</span>
                            <span><FaMinusCircle /></span>
                        </div>
                        <div className="r-section__p">
                            {dipTypes.map(dipType => (
                                <div key={dipType.id}>
                                    <div><label className='quantitylabel'>{dipType.name}</label></div>
                                    <div className="quantitycontainer">
                                        <FaMinus className="bapoo__quantity_icon" onClick={() => decrementQuantity(dipType.id)} />
                                        <input className="bapoo__quantity_input bapoo__quantity_input__tiny" type="text" name="quantity" id="quantity" value={getQuantity(dipType.id)} onChange={(e) => changeQuantity(dipType.id, e.target.value)} />
                                        <FaPlus className="bapoo__quantity_icon" onClick={() => incrementQuantity(dipType.id)} />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="r-section__h4__footer">&nbsp;</div>
                    </Fragment>
                )}

        </Fragment>
    )
}

export default DipOptions
