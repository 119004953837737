import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { RootStore } from "./RootStore";

export default class SliderStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeAutoObservable(this)
        this.rootStore = rootStore;
    }

    @observable isCustomizeOrderOpen = false;
    @observable isCheckOutOrderOpen = false;
    @observable isCartHidden = false;
    @observable isCartOpen = false;
    @observable checkOutStep = 0;

    @observable data = '';

    @action HideCart = () => {
        runInAction(() => {
            this.isCartHidden = true;
        })
    }


    @action UnHideCart = () => {
        runInAction(() => {
            this.isCartHidden = false;
        })
    }

    @action MoveCheckoutStep = (newStep: number) => {
        this.checkOutStep = newStep;
    }

    private closeAllSliders = () => {
        this.isCustomizeOrderOpen = false;
        this.isCheckOutOrderOpen = false;
        this.isCartOpen = false;
        this.rootStore.basketStore.displayBasket = true;
        this.rootStore.customStateManager.reset();
        this.rootStore.productStore.reset();
        runInAction(() => {
            this.checkOutStep = 0
        });

    }

    @action openCheckoutSlider = (mydata: string = '') => {
        runInAction(() => {
            this.data = mydata;
            this.closeAllSliders();
            this.isCheckOutOrderOpen = true;
            this.rootStore.basketStore.displayBasket = false;
        })
    }

    @action openCartSlider = (mydata: string = '') => {
        runInAction(() => {
            this.data = mydata;
            this.closeAllSliders();
            this.isCartOpen = true;
            this.rootStore.basketStore.displayBasket = false;
        })
    }

    @action closeSlider = () => {
        runInAction(() => {
            this.closeAllSliders();
        })
    }

    @action openCustomizeOrderSlider = (mydata: string = '') => {
        runInAction(() => {
            this.data = mydata;
            this.closeAllSliders();
            this.isCustomizeOrderOpen = true;
            this.rootStore.basketStore.displayBasket = false;
        })
    }


}