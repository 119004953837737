import React, { useContext, useEffect } from 'react';
import { RouteProps, RouteComponentProps, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../stores/RootStore';
import LoadingComponent from '../common/loaders/LoadingComponent';


interface IProps extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>>;
}
const PrivateRoute: React.FC<IProps> = ({ component: Component, ...rest }) => {
    const rootContext = useContext(RootStoreContext);
    const { isLoggedIn, getUser } = rootContext.userStore;
    const { token, appLoaded, setAppLoaded } = rootContext.commonStore;

    useEffect(() => {
        if (token) {
            getUser().finally(() => {
                setAppLoaded();
            })
        } else {
            setAppLoaded();
        }
    }, [token, getUser, setAppLoaded])

    if (!appLoaded) {
        return <LoadingComponent />
    }


    return (
        <Route
            {...rest}
            render={(props) => (isLoggedIn ? <Component {...props} /> : <Redirect to={'/login'} />)}
        />
    )
}

export default observer(PrivateRoute);