import React, { Fragment, useContext, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { observer } from 'mobx-react';
import './modfiedcheckout.scss';
import Delivery from './components/Delivery';
import Review from './components/Review';
import Payment from './components/Payment';
import { RootStoreContext } from '../../app/stores/RootStore';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY!);

const ModifiedCheckout = () => {
    const rootContext = useContext(RootStoreContext);
    const { checkOutStep, MoveCheckoutStep } = rootContext.sliderStore;
    const { pickupPreference, selectedAddress } = rootContext.deliveryStateStore;

    // const [step, setStep] = useState(0);

    const [error, setError] = useState('');


    const movePrevious = () => {
        setError('');
        if (checkOutStep > 0)
            MoveCheckoutStep(checkOutStep - 1);
        else
            MoveCheckoutStep(0);

    }


    const moveNext = () => {
        setError('');
        if (checkOutStep > 2)
            MoveCheckoutStep(2);
        else
            MoveCheckoutStep(checkOutStep + 1);
    }

    const setStep = (step: number) => {
        if (checkOutStep === 0) {
            if (!pickupPreference && selectedAddress === '') {
                setError('Please provide a valid address or choose the self-pickup option in the Delivery Section.');
                return false;
            }
        }
        setError('');

        if (checkOutStep > 2)
            MoveCheckoutStep(2);
        else
            MoveCheckoutStep(step);
    }


    return (
        <Fragment>
            <Elements stripe={stripePromise}>
                {error && (<div className="errorText">{error}</div>)}

                <Fragment>
                    <div className="selectedProduct__content__title" onClick={() => setStep(0)}>
                        <div className="selectedProduct__content__title__no">1</div>
                        <div className="selectedProduct__content__title__txt">
                            <p className="selectedProduct__content__title__txt-1">Delivery</p>
                        </div>

                    </div>
                    {checkOutStep === 0 ? (
                        <div className="selectedProduct__content__desc">
                            <div className="selectedProduct__content__desc-grid">
                                <Delivery
                                    moveNext={moveNext}
                                    movePrevious={movePrevious} />
                            </div>
                        </div>
                    ) : (
                        <div>&nbsp;</div>
                    )}
                </Fragment>
                <Fragment>
                    <div className="selectedProduct__content__title" onClick={() => setStep(1)}>
                        <div className="selectedProduct__content__title__no">2</div>
                        <div className="selectedProduct__content__title__txt">
                            <p className="selectedProduct__content__title__txt-1">Review Your Order</p>
                        </div>
                    </div>
                    {checkOutStep === 1 ? (
                        <div className="selectedProduct__content__desc">
                            <div className="selectedProduct__content__desc-grid">
                                <Review moveNext={moveNext} movePrevious={movePrevious} />
                            </div>
                        </div>
                    ) : (
                        <div>&nbsp;</div>
                    )}
                </Fragment>

                <Fragment>
                    <div className="selectedProduct__content__title" onClick={() => setStep(2)}>
                        <div className="selectedProduct__content__title__no">3</div>
                        <div className="selectedProduct__content__title__txt">
                            <p className="selectedProduct__content__title__txt-1">Payment</p>
                        </div>

                    </div>
                    {checkOutStep === 2 ? (
                        <div className="selectedProduct__content__desc">
                            <div className="selectedProduct__content__desc-grid">
                                <Payment movePrevious={movePrevious} />
                            </div>
                        </div>
                    ) : (
                        <div>&nbsp;</div>
                    )}
                </Fragment>
            </Elements>
        </Fragment>
    )
}

export default observer(ModifiedCheckout)
