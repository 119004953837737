import React, { Fragment } from 'react'
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'
import { GiFullPizza } from 'react-icons/gi'
import { ISizeType } from '../../../app/models/PizzaOptions'


interface IProps {
    sizeTypes: ISizeType[];
    minimize: boolean;
    maximize: (section: string, minimize: boolean) => void;
    pizzaSize: string;
    setPizzaSize: (size: string) => void;
}

const SizeOptions: React.FC<IProps> = ({ sizeTypes, minimize, maximize, pizzaSize, setPizzaSize }) => {

    return (
        <Fragment>
            {minimize ? (
                <Fragment>
                    <div className="r-section__h4 no_margin" onClick={() => maximize('size', true)} >
                        <span><GiFullPizza size='25' /></span>
                        <span>Size:</span>
                        <span><FaPlusCircle /></span>
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    <div className="r-section__h4 no_margin r-section__h4__expanded" onClick={() => maximize('size', false)} >
                        <span><GiFullPizza size='25' /></span>
                        <span>Size:</span>
                        <span><FaMinusCircle /></span>
                    </div>
                    <div className="r-section__p">
                        {sizeTypes.map(sizeType => (
                            <div key={sizeType.size}>
                                <input className="bapoo_radio_button" type="radio" name="pizza_size"
                                    value={sizeType.size}
                                    checked={pizzaSize === sizeType.size}
                                    onChange={(e) => setPizzaSize(e.target.value)} />
                                <span onClick={() => setPizzaSize(sizeType.size)}>&nbsp;&nbsp;{sizeType.size} </span>
                            </div>
                        ))}
                    </div>
                    <div className="r-section__h4__footer">&nbsp;</div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default SizeOptions
