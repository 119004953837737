import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { RootStoreContext } from '../../stores/RootStore';
import './navBar.scss';

const PrivateNavBar = () => {
    const rootContext = useContext(RootStoreContext);
    const { logout } = rootContext.userStore;

    return (
        <nav className="topNav">
            <div className="nbContent">
                <div className="nbContent__links">
                    <Link to='/' onClick={() => logout()}>Log out</Link>
                </div>
            </div>

        </nav>
    )
}

export default observer(PrivateNavBar)
