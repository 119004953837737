import React from 'react'

const LoadingComponent = () => {
    return (
        <div>
            Loading ...
        </div>
    )
}

export default LoadingComponent