import React, { useContext, useEffect } from 'react';
import { RouteProps, RouteComponentProps, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../stores/RootStore';
import NavBar from './header/NavBar';
import Footer from './footer/Footer';


interface IProps extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>>;
}
const NonPrivateRoute: React.FC<IProps> = ({ component: Component, ...rest }) => {
    const rootContext = useContext(RootStoreContext);
    const { UnHideCart } = rootContext.sliderStore;

    useEffect(() => {
        UnHideCart();
    }, [UnHideCart])

    return (
        <div className="container">
            <NavBar />
            <Route
                {...rest}
                render={(props) => <Component {...props} />}
            />
            <Footer />
        </div >
    )
}

export default observer(NonPrivateRoute);