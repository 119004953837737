import React, { Fragment } from 'react'
import Body from './Body'

const Menu = () => {
    return (
        <Fragment>
            <div className="about_banner">
                <h1>Our Menu</h1>
                <h4> ... always good &amp; fresh !</h4>
            </div>
            <Body />
        </Fragment>
    )
}

export default Menu
