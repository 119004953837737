import React, { Fragment } from 'react'
import Customize from '../Customize'

const CustomizeSpecific = () => {
    return (
        <Fragment>
            <div className="menu_banner">
                <h1>Our Menu</h1>
                <h4> ... always good &amp; fresh !</h4>
            </div>
            <section className="menus">
                <Customize />
            </section>
        </Fragment>
    )
}

export default CustomizeSpecific
