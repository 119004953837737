import { observer } from 'mobx-react';
import React, { Fragment, useContext } from 'react'
import { TiTick } from 'react-icons/ti'
import { ProductOption } from '../../../app/models/Category';
import { RootStoreContext } from '../../../app/stores/RootStore';
import { GiTomato } from 'react-icons/gi'

const ToppingsVeg = () => {

    const rootContext = useContext(RootStoreContext);
    const { product, addSelectedOption, removeSelectedOption } = rootContext.productStore;
    const { selectedVegToppings, setSelectedVegToppings } = rootContext.customStateManager;

    const getVegToppingOptions = () => {
        let myoptions = product?.options.filter(x => x.name.indexOf("Veg Toppings") !== -1)
        if (myoptions !== undefined && myoptions?.length > 0) {
            return myoptions[0].options;
        }
        return [];
    }

    const getSelectedOption = (myselection: string) => {
        let optionSelected: ProductOption | null = null;
        getVegToppingOptions().forEach(option => {
            if (option.name === myselection) {
                optionSelected = option;
            }
        });

        return optionSelected;
    }


    const isSelected = (topping: string) => {
        let match = selectedVegToppings.filter(x => x === topping);
        if (match !== null && match.length > 0)
            return true;
        return false;
    }

    const isPreConfiguredOption = (topping: ProductOption) => {

        if (product === null || product === undefined) return;
        const configOptions = product.preConfiguredOptions.filter(x => x.name.indexOf("Veg") !== -1);
        if (configOptions !== null && configOptions !== undefined && configOptions.length > 0) {
            const option = configOptions[0].options.filter(x => x.name === topping.name);
            if (option.length > 0) {
                return true;
            }
        }
        return false;
    }

    const toggleSelection = (toppingSelection: string) => {
        let match = selectedVegToppings.filter(x => x === toppingSelection);

        let optionSelected = getSelectedOption(toppingSelection);
        if (match !== null && match.length > 0) {
            setSelectedVegToppings(selectedVegToppings.filter(x => x !== toppingSelection));
            if (optionSelected != null) {
                removeSelectedOption(optionSelected);
            }

        } else {
            setSelectedVegToppings([...selectedVegToppings, toppingSelection]);
            if (optionSelected != null) {
                addSelectedOption(optionSelected);
            }

        }
    }


    if (getVegToppingOptions().length <= 0) {
        return <Fragment></Fragment>;
    }

    return (
        <Fragment>
            <div className="selectedProduct__content__title">
                <div className="selectedProduct__content__title__no"><GiTomato color='red' /></div>
                <div className="selectedProduct__content__title__txt">
                    <p className="selectedProduct__content__title__txt-1">Veg Toppings:</p>
                    <p className="selectedProduct__content__title__txt-2">Chose {selectedVegToppings.length} / {getVegToppingOptions().length}</p>
                </div>

            </div>
            <div className="selectedProduct__content__desc">
                <div className="selectedProduct__content__desc-grid">


                    {getVegToppingOptions() !== undefined && getVegToppingOptions().map(vegTopping => (

                        <Fragment key={vegTopping.id}>

                            {isPreConfiguredOption(vegTopping) ? (
                                <div key={vegTopping.id} className="selectedProduct__content__desc-grid-item  selectedProduct__content__desc-grid-item-preconfigured">
                                    <div className="selectedProduct__content__desc-grid-item-preconfigured-heading">Included</div>
                                    <div className="selectedProduct__content__desc-grid-item-selected"><TiTick color="white" size='22' /></div>
                                    <img className="selectedProduct__content__desc-grid-item-img" alt="ingredients" src={vegTopping.pictureUrl} />
                                    <div className="selectedProduct__content__desc-grid-item-txt">
                                        <p className="selectedProduct__content__desc-grid-item-txt-1">{vegTopping.name}</p>
                                        <p className="selectedProduct__content__desc-grid-item-txt-2"></p>
                                    </div>
                                </div>

                            ) : (
                                <button key={vegTopping.id} className="selectedProduct__content__desc-grid-item" onClick={() => toggleSelection(vegTopping.name)}>

                                    {isSelected(vegTopping.name) && (
                                        <div className="selectedProduct__content__desc-grid-item-selected"><TiTick color="white" size='22' /></div>
                                    )}
                                    <img className="selectedProduct__content__desc-grid-item-img" alt="ingredients" src={vegTopping.pictureUrl} />
                                    <div className="selectedProduct__content__desc-grid-item-txt">
                                        <p className="selectedProduct__content__desc-grid-item-txt-1">{vegTopping.name}</p>
                                        <p className="selectedProduct__content__desc-grid-item-txt-2"></p>
                                    </div>
                                </button>
                            )}


                        </Fragment>
                    ))}

                </div>
            </div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>

        </Fragment>

    )
}

export default observer(ToppingsVeg)
