import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const Sides = () => {
    return (
        <Fragment>
            <div className="menus__heading" id='Sides'>
                <h1>Sides</h1>
            </div>
            <div className="menus__subheading">
                <Link to={'/'}> &lt;&lt;&nbsp;Back To Home</Link>
            </div>

            <div className="menu">
                <img src="images/menu/1.jpg" alt="" className="menu__img" />

                <h5 className="menu__name">Roasted Chicken Wings</h5>
                <span className="menu__subtext">Perfectly seasoned Chicken Wings with seven different
                    types of sauses</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>$ 8.99 - 10pc</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>$16.99 - 20 pc</p>
                </div>
                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menu">
                <img src="images/menu/3.jpg" alt="" className="menu__img" />

                <h5 className="menu__name">Garlic Bread</h5>
                <span className="menu__subtext">Freshly backed dough topped with garlic, olice oil and herbs</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>$ 8.00 - 5 pc</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>cheese for $1</p>
                </div>
                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menu">
                <img src="images/menu/2.jpg" alt="" className="menu__img" />

                <h5 className="menu__name">Calzones</h5>
                <span className="menu__subtext"> Freshly backed turnover with your three stuffing's, slowly cooked to perfection</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>Regular 8.75</p>
                </div>
                <div className="menu__detail_2">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>stuffing for $1</p>
                </div>

                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menu">
                <img src="images/menu/4.jpg" alt="" className="menu__img" />

                <h5 className="menu__name">Oven Roasted Bread Sub</h5>
                <span className="menu__subtext">Feel the heat!</span>
                <div className="menu__detail_1">
                    <svg className="menu__like">
                        <use xlinkHref="images/mysprite.svg#icon-currency-dollar"></use>
                    </svg>
                    <p>3 toppings for $ 6.75</p>
                </div>

                <button className="btn menu__btn">Order Now</button>

            </div>
            <div className="menus__subheading">
                <Link to={'/'}> &lt;&lt;&nbsp;Back To Home</Link>
            </div>

        </Fragment>

    )
}

export default Sides
