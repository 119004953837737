
import { observer } from 'mobx-react';
import React, { Fragment, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import LoadingIndicator from '../../app/common/loaders/LoadingIndicator';
import { RootStoreContext } from '../../app/stores/RootStore';
import { FaPhoneAlt } from 'react-icons/fa';
import "./home.scss";

const Body = () => {
    const history = useHistory();

    const rootContext = useContext(RootStoreContext);
    const { loadingCategories, categories, getCategories } = rootContext.productStore;
    //const { categories, getCategories } = rootContext.productStore;

    useEffect(() => {
        getCategories();

    }, [getCategories])

    const navigateTo = (navigateurl: string) => {
        history.push(navigateurl);
    }



    if (loadingCategories || categories === null || categories.length === 0) {
        //  if (true) {
        return (
            <LoadingIndicator />
        );
    }

    return (
        <Fragment>

            <div className="backgroundImage" />

            <section className="categories">
                <div className="categories__heading">
                    <h5 className="categories__name"><Link to={`/`} >Categories</Link></h5>
                    <div className="categories__contact">
                        <a href="/#" className="button-one"><FaPhoneAlt />&nbsp;&nbsp;Call us at : 519 219 2700</a>
                    </div>
                </div>

                {categories !== null && categories!.map(category => (
                    <span key={category.id} className="category">
                        <img src={category.pictureUrl} alt="" className="category__img"
                            onClick={() => navigateTo(`menu/${category.id}`)} />
                        {category.name.indexOf("Deal") !== -1 ? (
                            <Link className="category__name category__deals" to={`menu/${category.id}`} >{category.name}</Link>

                        ) : (
                            <Link className="category__name" to={`menu/${category.id}`} >{category.name}</Link>
                        )}
                    </span>
                ))}
            </section>


        </Fragment >
    )
}

export default observer(Body);
